import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { withRouter, useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

import { withTranslation } from "react-i18next";

import ProgressBar from "components/Heytech/ProgressBar";
import ShortInfo from "components/Heytech/ShortInfo";
import CondizioniDiVenditaList from "components/Heytech/CondizioniDiVenditaList";
import SaveBozzaBtn from "components/Heytech/SaveBozzaBtn";
import SaveBozzaAuto from "components/Heytech/SaveBozzaAuto";

const SceltaCondizioniDiVendita = props => {
  const history = useHistory();

  const [orderTot, setOrderTot] = React.useState(localStorage.getItem("ht-order") ? JSON.parse(localStorage.getItem("ht-order")) : "");
  const [progressValue, setProgressValue] = React.useState(orderTot.type == "Macchinario" ? 80 : 50);
  const [changedLocalStorage, setChangedLocalStorage] = React.useState(false);
  const [condizioni, setCondizioni] = React.useState(JSON.parse(localStorage.getItem("ht-order")).condizioni ? JSON.parse(localStorage.getItem("ht-order")).condizioni : []);
  const [allConditionsSelected, setAllConditionsSelected] = React.useState(false);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Scelta condizioni di vendita | Gruppo Bisaro Cloud</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Attività" parentsBreadcrumb={[{"title":(orderTot. proposta === 0 ? "Crea Offerta" : "Crea Ordine"), "link":(orderTot. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")}]} breadcrumbItem="Scelta condizioni di vendita" />
          <Row>
            <ShortInfo changedLocalStorage={changedLocalStorage}/>
            <Col md="12">
              <Card>
                <CardBody>
                  <ProgressBar numberBable={orderTot.type == "Macchinario" ? 6 : 3} progressValue={progressValue}/>
                  <div className="d-flex justify-content-between pt-4 ht_btn_bar_top">
                    {orderTot.type == "Macchinario" && <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        history.push((orderTot. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")+"/macchinario/scelta-macchina");
                      }}
                    >
                      <i className="bx bx-plus font-size-16 align-middle"></i>{" "}
                      Aggiungi macchinario
                    </button>}
                    {orderTot.type !== "Macchinario" && <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        history.push((orderTot. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")+"/materialediconsumo/scelta");
                      }}
                    >
                      <i className="bx bx-left-arrow-alt font-size-16 align-middle"></i>{" "}
                      Materiale di consumo
                    </button>}
                    
                    <SaveBozzaBtn disabled={JSON.parse(localStorage.getItem("ht-order")).order.length == 0 ? true : false}/>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        if(orderTot.type == "Macchinario"){
                          history.push((orderTot. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")+"/macchinario/scelta-metododipagamento");
                        } else {
                          history.push((orderTot. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")+"/materialediconsumo/scelta-metododipagamento");
                        }
                      }}
                      disabled={!allConditionsSelected}
                    >
                      Metodo di pagamento{" "}
                      <i className="bx bx-right-arrow-alt font-size-16 align-middle"></i>
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <CardBody>
                  <h5 className="mb-5">Seleziona le condizioni di vendita</h5>
                  <CondizioniDiVenditaList changedLocalStorage={changedLocalStorage} setChangedLocalStorage={setChangedLocalStorage} condizioniPreset={condizioni} setCondizioni={setCondizioni} setAllConditionsSelected={setAllConditionsSelected}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <SaveBozzaAuto />
    </React.Fragment>
  )
}

SceltaCondizioniDiVendita.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
}

export default withRouter(withTranslation()(SceltaCondizioniDiVendita));
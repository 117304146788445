import PropTypes from "prop-types";
import React from "react"
import {
  Progress
} from "reactstrap";

const ProgressBar = props => {

  return (
    <div className="position-relative m-4">
      <Progress
        value={props.progressValue}
        color="primary"
        style={{ height: '2px' }}
      ></Progress>
      {[...Array(props.numberBable)].map((x, i) =>
        <button key={i} className={"position-absolute top-0 start-"+(100/parseInt(props.numberBable-1)*i)+" translate-middle btn btn-sm btn-"+(props.progressValue>0 && props.progressValue>=parseInt(100/(props.numberBable-1)*i) ? "primary" : "light")+" rounded-pill"} style={{ width: "2rem", height: "2rem" }}>
          {i+1}
        </button>
      )}
    </div>
  )
}

export default ProgressBar

ProgressBar.propTypes = {
  setSelectedRoute: PropTypes.func,
  selectedRoute: PropTypes.string,
  numberBable: PropTypes.number,
  progressValue: PropTypes.number,
};
import {
  GET_PDF,
  GET_PDF_FAIL,
  GET_PDF_SUCCESS,
} from "./actionTypes"

export const getPdf = (idPDF, setPdfLoaded, setUrlPdf) => ({
  type: GET_PDF,
  payload: {idPDF, setPdfLoaded, setUrlPdf},
})

export const getPdfSuccess = pdf => ({
  type: GET_PDF_SUCCESS,
  payload: pdf,
})

export const getPdfFail = error => ({
  type: GET_PDF_FAIL,
  payload: error,
})
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react"
import "toastr/build/toastr.min.css";
import { size } from "lodash";
import  localforage from "localforage";

const MetodoDiPagamentoList = props => {
  const [pagamentiList, setPagamentiList] = React.useState([]);
  const [pagamentiChoise, setPagamentiChoise] = React.useState(props.pagamentiPreset);


  useEffect(() => {
    localforage.getItem("conditions").then(function (pagamenti) {
      let filterQuery = "";
      if(JSON.parse(localStorage.getItem("ht-order")) && JSON.parse(localStorage.getItem("ht-order")).type === "Macchinario") {
        filterQuery = "MA-";
      } else {
        filterQuery = "MC-";
      }
      if(JSON.parse(localStorage.getItem("ht-order")) && JSON.parse(localStorage.getItem("ht-order")). proposta === 0) {
        filterQuery = filterQuery+"OF";
      } else {
        filterQuery = filterQuery+"PO";
      }
      let pag = [];
      if(filterQuery !== "") {
        pagamenti.forEach(paymentGroup => {
          if(paymentGroup.is_payment_method === 1){
            let pagamentiTemp = paymentGroup.condizioni.filter(payment => {
              return payment.ref.includes(filterQuery);
            });
            paymentGroup.condizioni = pagamentiTemp;
            if(size(paymentGroup.condizioni) > 0) {
              pag.push(paymentGroup);
            }
          }
        });
      }

      pag.sort(function(a, b) {
        return compareStrings(a.categoria, b.categoria);
      })
      props.setAllPagamentiSelected(pagamentiChoise.length === pag.length);
      setPagamentiList(pag);
    });
  }, [])

  function compareStrings(a, b) {
    // Assuming you want case-insensitive comparison
    a = a.toLowerCase();
    b = b.toLowerCase();
  
    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }

  const changeCondition = (name, keyNome, value) => {
    let pagamentiChoiseTemp = JSON.parse(JSON.stringify(pagamentiChoise));
    pagamentiChoiseTemp = pagamentiChoiseTemp.filter(item => item.idCondizione !== keyNome);
    pagamentiChoiseTemp.push(
      {
        "condizione": name,
        "idCondizione": keyNome,
        "valore": value
      }
    )
    let orderTemp = JSON.parse(localStorage.getItem("ht-order"));
    orderTemp.pagamenti = pagamentiChoiseTemp;
    localStorage.setItem("ht-order", JSON.stringify(orderTemp));
    props.setChangedLocalStorage(!props.changedLocalStorage);
    props.setAllPagamentiSelected(pagamentiChoiseTemp.length === pagamentiList.length);
    setPagamentiChoise(pagamentiChoiseTemp);
  }

  return (
    <>
      <ul className="ht_list_tab">
        {
          pagamentiList.map(payments => 
            <div key={payments.id}>
              {payments.condizioni.map(singleCondition => 
                <li key={singleCondition.id} onClick={() => changeCondition(payments.categoria, payments.id, singleCondition)}>
                  <input type="radio" id={singleCondition.id} checked={pagamentiChoise.filter(item => item.idCondizione === payments.id && item.valore.id === singleCondition.id).length>0} value={singleCondition.id} name="payment_method" onChange={(e) => changeCondition(payments.categoria, payments.id, singleCondition)}/>
                  <label dangerouslySetInnerHTML={{ __html: singleCondition.descrizione }} ></label>
                </li>
              )}
            </div>
          )
        }
      </ul>
    </>
  )
}

export default MetodoDiPagamentoList

MetodoDiPagamentoList.propTypes = {
  filterQuery: PropTypes.array,
  pagamentiPreset: PropTypes.array,
  setPagamenti: PropTypes.func,
  setAllPagamentiSelected: PropTypes.func,
  changedLocalStorage: PropTypes.bool,
  setChangedLocalStorage: PropTypes.func,
};
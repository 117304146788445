import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { SET_TAGORDER } from "./actionTypes"

import {
  setTagOrderSuccess,
  setTagOrderFail
} from "./actions"

//Include Both Helper File with needed methods
import { setTagOrder } from "../../helpers/backend_helper"

function* fetchSaveTagOrder({ payload: {setTagOrderLoaded, data} }) {
  try {
    const response = yield call(setTagOrder, data);
    yield put(setTagOrderSuccess(response));
    setTagOrderLoaded(false);
  } catch (error) {
    yield put(setTagOrderFail(error));
    setTagOrderLoaded(0);
  }
}

function* setTagOrderSaga() {
  yield takeEvery(SET_TAGORDER, fetchSaveTagOrder)
}

export default setTagOrderSaga;

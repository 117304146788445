import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_FILESTRANING } from "./actionTypes"

import {
  getFilesTraningSuccess,
  getFilesTraningFail
} from "./actions"

//Include Both Helper File with needed methods
import { getFilesTraning } from "../../helpers/backend_helper"

function* fetchFilesTraning({ payload: {setFilesLoaded} }) {
  try {
    const response = yield call(getFilesTraning);
    yield put(getFilesTraningSuccess(response));
    setFilesLoaded(false);
  } catch (error) {
    yield put(getFilesTraningFail(error));
    setFilesLoaded(false);
  }
}

function* filesTraningSaga() {
  yield takeEvery(GET_FILESTRANING, fetchFilesTraning)
}

export default filesTraningSaga;

import {
  GET_PDFORDER_SUCCESS,
  GET_PDFORDER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  pdfOrder: [],
  userProfile: {},
  error: {},
}

const pdfOrder = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PDFORDER_SUCCESS:
      return {
        ...state,
        pdfOrder: action.payload,
      }

    case GET_PDFORDER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default pdfOrder

import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { Link, withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  Alert,
  Spinner
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

import {
  getArchivio
} from "store/archivio/actions";

//redux
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import CustomSearch from "components/Heytech/CustomSearch";

import { size } from "lodash";

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { formatRelative, differenceInDays, format } from 'date-fns'
import { it } from 'date-fns/locale';
import { useHistory } from "react-router-dom";
import uuid from 'react-uuid';

import SyncOfflineOrderBtn from "./../../components/Heytech/SyncOfflineOrderBtn"

import localforage from "localforage";

const Archivio = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [listArchivio, setListArchivio] = React.useState([]);
  const [listArchivioOriginal, setListArchivioOriginal] = React.useState(false);
  const [archivioAggiornato, setArchivioAggiornato] = React.useState(-1);
  const [searchField, setSearchField] = React.useState("");
  const [openAction, setOpenAction] = React.useState(false)

  useEffect(() => {
    if (navigator.onLine) {
      if (!listArchivioOriginal || archivioAggiornato == -1) {
        //dispatch(getArchivio(setListArchivioOriginal, setArchivioAggiornato, ""));
        //setArchivioAggiornato(0);
      } else {
        setListArchivio(listArchivioOriginal);
        handleSearch(searchField);
      }
    } else {
      if (!listArchivioOriginal) {
        let archivioOffline = localStorage.getItem("ht-save-offline") ? JSON.parse(localStorage.getItem("ht-save-offline")) : [];
        setListArchivioOriginal(archivioOffline);
        setListArchivio(archivioOffline);
      }
    }
  }, [listArchivioOriginal])

  useEffect(() => {
    localforage.getItem("archivio").then(function (item) {
      if (item !== null && localStorage.getItem("archivioTS") && localStorage.getItem("firstArchivioTS") && (parseInt(localStorage.getItem("firstArchivioTS")) + 86400) > parseInt(new Date().getTime() / 1000)) {
        dispatch(getArchivio(setListArchivioOriginal, setArchivioAggiornato, localStorage.getItem("archivioTS"), true));
        setArchivioAggiornato(0);
        setListArchivioOriginal(item);
      } else{
        localStorage.setItem("firstArchivioTS", parseInt(new Date().getTime() / 1000));
        dispatch(getArchivio(setListArchivioOriginal, setArchivioAggiornato, ""));
        setArchivioAggiornato(0);
      }
    });
  }, []);

  const getCapitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const getStringDate = (date) => {
    return Math.abs(differenceInDays(new Date(date.replace(/-/g, "/")), new Date())) < 6
      ? getCapitalize(formatRelative(new Date(date.replace(/-/g, "/")), new Date(), { locale: it }))
      : getCapitalize(new Date(date.replace(/-/g, "/")).toLocaleString('it-IT', { dateStyle: 'full', timeStyle: 'short' }));
  }

  const { SearchBar } = Search;
  const sizePerPage = 20;
  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: size(listArchivio), // replace later with size(users),
    custom: false,
    hideSizePerPage: true,
    showTotal: true,
  };
  const defaultSorted = [
    {
      dataField: "data", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      if (!e.target.classList.contains('bx-exclude') && !e.target.classList.contains('btn-light')) {
        localStorage.setItem("ht-dettaglio-archivio", JSON.stringify(row));
        history.push("/archivio/dettaglio-archivio/" + row.id);
      }
    }
  }

  const tableRowLocalEvents = {
    onClick: (e, row, rowIndex) => {
      if (!e.target.classList.contains('bx-exclude') && !e.target.classList.contains('btn-light')) {
        localStorage.setItem("ht-dettaglio-local-archivio", JSON.stringify(row));
        history.push("/archivio/dettaglio-local-archivio/" + row.offlineId);
      }
    }
  }

  const archivioColumns = [
    {
      text: "Tipologia",
      dataField: "prefisso",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <h5 className="font-size-14 mb-1 text-dark">
            {order.json.proposta === 1 ? "Proposta d'ordine" : "Offerta"}{" "}{order.json.type}
            {order.trasformato_da !== null && <Badge pill className="badge-primary ms-1">
              Creato da offerta
            </Badge>}
            {order.trasformato_in !== null && <Badge pill className="badge-primary ms-1">
              Trasformato in ordine
            </Badge>}
          </h5>
          {order.is_draft == 1 && <p className="text-muted mb-0">Bozza</p>}
        </>
      ),
    },
    {
      text: "Cliente",
      dataField: "json.cliente.anagrafica_descrizione",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <h5 className="font-size-14 mb-1 text-dark">
            {order.json.cliente.anagrafica_descrizione}
          </h5>
          <p className="text-muted mb-0">{order.json.cliente.anagrafica_codice}</p>
        </>
      ),
    },
    {
      text: "Tag",
      dataField: "tags",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          {order.tags.map((item, key) => { return key === 0 ? item : ", " + item })}
        </>
      ),
    },
    {
      text: "Codice",
      dataField: "code",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          {order.code.indexOf(".") > 0 ? order.code.substring(0, order.code.indexOf(".")) : order.code}
        </>
      ),
    },
    {
      text: "Data",
      dataField: "data",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          {getStringDate(order.data)}
        </>
      ),
    },
    /* {
      text: "Azioni",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <Dropdown
            isOpen={order.id === openAction}
            direction="left"
            className="dropstart"
            toggle={() => setOpenAction(order.id === openAction ? false : order.id)}
          >
            <DropdownToggle tag="button" className="btn btn-light">
              <i className="bx bx-exclude" />
            </DropdownToggle>
            <DropdownMenu>
              {order.json.proposta === 0 && !order.is_draft && <DropdownItem><i className="bx bxs-file-pdf font-size-16 align-middle me-2"></i> Visualizza PDF</DropdownItem>}
              <DropdownItem><i className="bx bx-duplicate font-size-16 align-middle me-2"></i> Duplica</DropdownItem>
              <DropdownItem><i className="bx bx-edit font-size-16 align-middle me-2"></i> Modifica</DropdownItem>
              {order.json.proposta === 0 && !order.is_draft && <DropdownItem><i className="bx bx-check-circle font-size-16 align-middle me-2"></i> Trasforma in Ordine</DropdownItem>}
              <DropdownItem><i className="bx bx-trash-alt font-size-16 align-middle me-2"></i> Elimina</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </>
      ),
    }, */
  ];

  const archivioOfflineColumns = [
    {
      text: "Tipologia",
      dataField: "prefisso",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <h5 className="font-size-14 mb-1 text-dark">
            {order.data.proposta === 1 ? "Proposta d'ordine" : "Offerta"}{" "}{order.data.type}
          </h5>
          {order.offLineStatus == "Bozza" && <p className="text-muted mb-0">Bozza</p>}
        </>
      ),
    },
    {
      text: "Cliente",
      dataField: "data.cliente.anagrafica_descrizione",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <h5 className="font-size-14 mb-1 text-dark">
            {order.data.cliente.anagrafica_descrizione}
          </h5>
          <p className="text-muted mb-0">{order.data.cliente.anagrafica_codice}</p>
        </>
      ),
    },
    {
      text: "Tag",
      dataField: "tags",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          {order.tags && order.tags.map((item, key) => { return key === 0 ? item : ", " + item })}
        </>
      ),
    },
    {
      text: "Data",
      dataField: "data",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          {getStringDate(order.offlineId)}
        </>
      ),
    },
    /* {
      text: "Azioni",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <Dropdown
            isOpen={order.id === openAction}
            direction="left"
            className="dropstart"
            toggle={() => setOpenAction(order.id === openAction ? false : order.id)}
          >
            <DropdownToggle tag="button" className="btn btn-light">
              <i className="bx bx-exclude" />
            </DropdownToggle>
            <DropdownMenu>
              {order.json.proposta === 0 && !order.is_draft && <DropdownItem><i className="bx bxs-file-pdf font-size-16 align-middle me-2"></i> Visualizza PDF</DropdownItem>}
              <DropdownItem><i className="bx bx-duplicate font-size-16 align-middle me-2"></i> Duplica</DropdownItem>
              <DropdownItem><i className="bx bx-edit font-size-16 align-middle me-2"></i> Modifica</DropdownItem>
              {order.json.proposta === 0 && !order.is_draft && <DropdownItem><i className="bx bx-check-circle font-size-16 align-middle me-2"></i> Trasforma in Ordine</DropdownItem>}
              <DropdownItem><i className="bx bx-trash-alt font-size-16 align-middle me-2"></i> Elimina</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </>
      ),
    }, */
  ];

  var node = useRef();

  const keyField = "id";

  const handleSearch = (searchQuery) => {
    var splitSearchQuery = searchQuery.split(" ");
    var tempArchivioFilter = listArchivioOriginal.filter(item => {
      let paroleTrovate = [];
      if (searchQuery === "") {
        return true;
      } else {
        if (item.tag !== null && splitSearchQuery.filter(iter => JSON.stringify(item.tags).toLowerCase().indexOf(iter.toLowerCase()) !== -1).length > 0) {
          paroleTrovate = [].concat(paroleTrovate, splitSearchQuery.filter(iter => JSON.stringify(item.tags).toLowerCase().indexOf(iter.toLowerCase()) !== -1));
        }
        if (item.json.cliente.anagrafica_descrizione !== null && splitSearchQuery.filter(iter => item.json.cliente.anagrafica_descrizione.toLowerCase().indexOf(iter.toLowerCase()) !== -1).length > 0) {
          paroleTrovate = [].concat(paroleTrovate, splitSearchQuery.filter(iter => item.json.cliente.anagrafica_descrizione.toLowerCase().indexOf(iter.toLowerCase()) !== -1));
        }
        if (item.json.cliente.anagrafica_codice !== null && splitSearchQuery.filter(iter => item.json.cliente.anagrafica_codice.toLowerCase().indexOf(iter.toLowerCase()) !== -1).length > 0) {
          paroleTrovate = [].concat(paroleTrovate, splitSearchQuery.filter(iter => item.json.cliente.anagrafica_codice.toLowerCase().indexOf(iter.toLowerCase()) !== -1));
        }
        if (item.json.cliente.anagrafica_email !== null && splitSearchQuery.filter(iter => item.json.cliente.anagrafica_email.toLowerCase().indexOf(iter.toLowerCase()) !== -1).length > 0) {
          paroleTrovate = [].concat(paroleTrovate, splitSearchQuery.filter(iter => item.json.cliente.anagrafica_descrizione.toLowerCase().indexOf(iter.toLowerCase()) !== -1));
        }
        /* if (item.json.cliente.anagrafica_indirizzo.length > 0 && item.json.cliente.anagrafica_indirizzo[0].Localita !== null && splitSearchQuery.filter(iter => item.json.cliente.anagrafica_indirizzo[0].Localita.toLowerCase().indexOf(iter.toLowerCase()) !== -1).length > 0) {
          paroleTrovate = [].concat(paroleTrovate, splitSearchQuery.filter(iter => item.json.cliente.anagrafica_indirizzo[0].Localita.toLowerCase().indexOf(iter.toLowerCase()) !== -1));
        } */
        if (item.code !== null && item.code !== "" && splitSearchQuery.filter(iter => item.code.toLowerCase().indexOf(iter.toLowerCase()) !== -1).length > 0) {
          paroleTrovate = [].concat(paroleTrovate, splitSearchQuery.filter(iter => item.code.toLowerCase().indexOf(iter.toLowerCase()) !== -1));
        }
        if (item.json.proposta !== null && item.json.proposta !== null && splitSearchQuery.filter(iter => (item.json.proposta === 1 ? "proposta d'ordine" : "offerta").indexOf(iter.toLowerCase()) !== -1).length > 0) {
          paroleTrovate = [].concat(paroleTrovate, splitSearchQuery.filter(iter => (item.json.proposta === 1 ? "proposta d'ordine" : "offerta").indexOf(iter.toLowerCase()) !== -1));
        }
        if (item.json.type !== null && item.json.type !== "" && splitSearchQuery.filter(iter => item.json.type.toLowerCase().indexOf(iter.toLowerCase()) !== -1).length > 0) {
          paroleTrovate = [].concat(paroleTrovate, splitSearchQuery.filter(iter => item.json.type.toLowerCase().indexOf(iter.toLowerCase()) !== -1));
        }
        if (JSON.stringify(item.json.order) !== "" && JSON.stringify(item.json.order) !== null && splitSearchQuery.filter(iter => JSON.stringify(item.json.order).toLowerCase().indexOf(iter.toLowerCase()) !== -1).length > 0) {
          paroleTrovate = [].concat(paroleTrovate, splitSearchQuery.filter(iter => JSON.stringify(item.json.order).toLowerCase().indexOf(iter.toLowerCase()) !== -1));
        }

        paroleTrovate = paroleTrovate.filter((value, index, array) => { return array.indexOf(value) === index; });

        return paroleTrovate.length == splitSearchQuery.length;
      }
    })
    setListArchivio(tempArchivioFilter);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Archivio | Gruppo Bisaro Cloud</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Attività" breadcrumbItem="Archivio" />
          {localStorage.getItem("ht-save-offline") && JSON.parse(localStorage.getItem("ht-save-offline")).length > 0 && <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h3>Archivio in locale</h3>
                  <Alert color="danger">
                    Dati non sincronizzati. {" "}
                    <SyncOfflineOrderBtn element={<div className="alert-link btn btn-danger" style={{ "color": "white", "fontWeight": "normal" }}>
                      Sincronizza ora
                    </div>} />
                    {!navigator.onLine && "Connettiti ad internet per sincronizzare i dati."}
                  </Alert>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField={keyField}
                    columns={archivioOfflineColumns}
                    data={JSON.parse(localStorage.getItem("ht-save-offline"))}
                    dataSize={JSON.parse(localStorage.getItem("ht-save-offline")).length}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField={keyField}
                          data={JSON.parse(localStorage.getItem("ht-save-offline"))}
                          columns={archivioOfflineColumns}
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <div className="product_list_heder d-flex justify-content-between align-middle mb-3">
                                <>
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <CustomSearch value={searchField} placeholder="Ricerca" onChange={(e) => {
                                        setSearchField(e.currentTarget.value);
                                        handleSearch(e.currentTarget.value);
                                      }} />
                                    </div>
                                  </div>
                                  <div>

                                  </div>
                                </>
                                <div className="d-flex align-items-center">{archivioAggiornato == -1 ? <Badge pill color="danger" className="ms-1" style={{padding: "1em"}}>Archivio non aggiornato</Badge> : (archivioAggiornato == 1 ? <Badge pill color="success" className="ms-1" style={{padding: "1em"}}>Archivio aggiornato</Badge> : <Badge pill color="warning" className="ms-1" style={{padding: "1em"}}>Archivio in aggiornamento</Badge>)}</div>
                              </div>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={keyField}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={node}
                                      rowEvents={tableRowLocalEvents}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      );
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {!navigator.onLine ? <h4 style={{ "textAlign": "center" }}>Connettiti ad internet per visualizzare l&apos;archivio</h4> : (!listArchivioOriginal ? <div style={{ "textAlign": "center" }}><Spinner>
                    Loading...
                  </Spinner></div> : <>
                    {localStorage.getItem("ht-save-offline") && JSON.parse(localStorage.getItem("ht-save-offline")).length > 0 && <>
                      <h3>Archivio in cloud</h3><br /></>}
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField={keyField}
                      columns={archivioColumns}
                      data={listArchivio}
                      dataSize={listArchivio.length}
                    >
                      {({ paginationProps, paginationTableProps }) => {
                        return (
                          <ToolkitProvider
                            keyField={keyField}
                            data={listArchivio}
                            columns={archivioColumns}
                            bootstrap4
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <div className="product_list_heder d-flex justify-content-between align-middle mb-3">
                                  <>
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <CustomSearch value={searchField} placeholder="Ricerca" onChange={(e) => {
                                          setSearchField(e.currentTarget.value);
                                          handleSearch(e.currentTarget.value);
                                        }} />
                                      </div>
                                    </div>
                                  </>
                                  <div className="d-flex align-items-center">{archivioAggiornato == -1 ? <Badge pill color="danger" className="ms-1" style={{padding: "1em"}}>Archivio non aggiornato</Badge> : (archivioAggiornato == 1 ? <Badge pill color="success" className="ms-1" style={{padding: "1em"}}>Archivio aggiornato</Badge> : <Badge pill color="warning" className="ms-1" style={{padding: "1em"}}>Archivio in aggiornamento</Badge>)}</div>
                                </div>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={keyField}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap table-hover"
                                        }
                                        bordered={false}
                                        striped={false}
                                        responsive
                                        ref={node}
                                        rowEvents={tableRowEvents}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        );
                      }}
                    </PaginationProvider></>)}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Archivio.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
}

export default withRouter(withTranslation()(Archivio));
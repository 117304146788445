import PropTypes from "prop-types";
import React, { useEffect } from "react"

const ShowPrice = props => {
  const [price, setPrice] = React.useState(0);

  useEffect(() => {
    var qty = props.qty;
    if(qty < 1 || !qty){
      qty = 1
    }
    if(qty > 0){
      let arrayPrice = props.price.filter(item => item.qta <= qty);
      if(arrayPrice.length > 0){
        let priceFinal = 0;
        let rightQty = 0;
        arrayPrice.forEach(item => {
          if(item.qta >= rightQty){
            rightQty = item.qta;
            priceFinal = item.prezzo;
          }
        })
        setPrice(priceFinal);
      }
    } else {
      setPrice(props.price[0].prezzo);
    }
  }, [props.qty, props.price]);

  return (
    <span className="price">{Number(price).toLocaleString("it-IT", {minimumFractionDigits:2}) + " €"}</span>
  )
}

export default ShowPrice

ShowPrice.propTypes = {
  price: PropTypes.array,
  qty: PropTypes.number,
};
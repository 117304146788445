import {
  GET_ARCHIVIO,
  GET_ARCHIVIO_FAIL,
  GET_ARCHIVIO_SUCCESS,
} from "./actionTypes"

export const getArchivio = (setArchivioData, setArchivioAggiornato, idLast, isTS) => ({
  type: GET_ARCHIVIO,
  payload: {setArchivioData, setArchivioAggiornato, idLast, isTS},
})

export const getArchivioSuccess = archivio => ({
  type: GET_ARCHIVIO_SUCCESS,
  payload: archivio,
})

export const getArchivioFail = error => ({
  type: GET_ARCHIVIO_FAIL,
  payload: error,
})
import PropTypes from "prop-types";
import React from "react"

const CustomSearch = props => {

  return (
    <>
      <label htmlFor="search-bar-0" className="search-label">
        <span id="search-bar-0-label" className="sr-only">Search this table</span>
        <input id="search-bar-0" type="text" aria-labelledby="search-bar-0-label" className="form-control " placeholder={props.placeholder} value={props.value} onChange={props.onChange}/>
      </label>
      <i className="bx bx-search-alt search-icon"></i>
    </>
  )
}

export default CustomSearch

CustomSearch.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
import React from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Col } from "reactstrap"
import BisaroPhoto from "../../assets/images/bisaroPhoto.jpeg"

const CarouselPage = () => {
  return (
    <React.Fragment>
      <Col xl={8}>
        <div className="auth-full-bg pt-lg-5 p-4">
          <div className="w-100">
            <div className="bg-overlay"></div>
            <div className="d-flex h-100 flex-column">
              <div className="p-4 mt-auto">
                <div className="row justify-content-center">
                  <div className="col-lg-7 d-flex align-items-center">
                    <div className="text-center mw-100">
                      <h4 className="mb-3">
                        {/* <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i> */}
                        <span className="text-primary">Gianfranco Bisaro</span>
                      </h4>
                      <p>Presidente del Gruppo Bisaro</p>
                      <div dir="ltr">
                        <Carousel showThumbs={false} className="slider_css">
                          <div>
                            <div className="item">
                              <div className="py-3">
                                <p className="font-size-16 mb-4">
                                  &quot;Con questo portale, organizzato in spazi mirati a codice di accesso personalizzato, ho voluto firmare un punto d&apos;incontro, di cultura, di approfondimento e di condivisione al fine di supportare tutti coloro che operano o che si troveranno a rapportarsi in futuro con me e con le attività a me correlate.&ldquo;
                                </p>

                                {/* <div>
                                  <h4 className="font-size-16 text-primary">
                                    Gianfranco Bisaro
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    - Presidente Gruppo Bisaro
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="item">
                              <div className="py-3">
                                <p className="font-size-16 mb-4">
                                  &quot;Un&apos; Area web focalizzata nella divulgazione del sapere, frutto di una mia innata curiosità, di utili intuizioni, di ricerca e studio continuo. Un Centro di vivacità intellettuale rivolto a tutti coloro che entrano nella mia sfera d&apos;azione. L&apos;obiettivo è d&apos;infondere la consapevolezza di essere parte integrante di un processo evolutivo che si configura in un meccanismo di miglioramento continuo.&ldquo;
                                </p>

                                {/* <div>
                                  <h4 className="font-size-16 text-primary">
                                    Gianfranco Bisaro
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                  - Presidente Gruppo Bisaro
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="item">
                              <div className="py-3">
                                <p className="font-size-16 mb-4">
                                  &quot;Evidenziare idee, tecnologie, brevetti e competenze per stimolare dinamismo e adattamento al cambiamento nei miei collaboratori. Ciò al fine di una loro continua crescita di competenza e di professionalità che possano aumentare il sapere complessivo del gruppo, con la forte attenzione all&apos;uomo, alla tradizione, all&apos;evoluzione dei processi e alla qualità e sanità dei prodotti, che con le tecnologie proposte s&apos;interviene nella loro produzione.&ldquo;
                                </p>

                                {/* <div>
                                  <h4 className="font-size-16 text-primary">
                                    Gianfranco Bisaro
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                  - Presidente Gruppo Bisaro
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </Carousel>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5 loginBisaroImage">
                    <img
                      src={BisaroPhoto}
                      alt="Gianfranco Bisaro"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage

import PropTypes from "prop-types";
import React, { memo, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  Modal,
  Spinner
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

import macchinarioLogo from "assets/images/macchinarioLogo.png";
import materialediconsumoLogo from "assets/images/materialediconsumoLogo.png";

//redux
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  getArchivio
} from "store/archivio/actions";

import {
  deleteOrder
} from "store/deleteOrder/actions";

import {
  getPdfOrder
} from "store/pdfOrder/actions";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import Swal from 'sweetalert2'
import localforage from "localforage";
import { formatRelative, differenceInDays, format } from 'date-fns'
import { it } from 'date-fns/locale';
import TagsInput from "components/Heytech/TagsInput";
import { setTagOrder } from "store/saveTagsInOrder/actions";

const DettaglioArchivio = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    match: { params },
  } = props;
  const [tagsLoader, setTagsLoader] = React.useState(false);

  useEffect(() => {
    var element = document.querySelectorAll(".responsiveTable");
    element.forEach(elem => {
      elem.classList.remove("responsiveTable");
    })
  }, [])

  const sortOrder = (orderTemp) => {
    if (orderTemp.type == "Macchinario") {
      orderTemp.order.sort((a, b) => {
        a = a.macchinario.nome.toLowerCase();
        b = b.macchinario.nome.toLowerCase();

        return (a < b) ? -1 : (a > b) ? 1 : 0;
      });

      orderTemp.order.forEach((item, index) => {
        item.accessori.sort(
          (a, b) => {
            return parseFloat(a['sort_number']) - parseFloat(b['sort_number']);
          }
        )
        item.accessoriObbligatori.sort((a, b) => {
          a = a.nome.toLowerCase();
          b = b.nome.toLowerCase();

          return (a < b) ? -1 : (a > b) ? 1 : 0;
        })
        item.optional.sort(
          (a, b) => {
            return parseFloat(a['sort_number']) - parseFloat(b['sort_number']);
          }
        )
        item.materialiDiConsumo.sort(
          (a, b) => {
            return parseFloat(a['sort_number']) - parseFloat(b['sort_number']);
          }
        )
      })
    } else {
      orderTemp.order.sort(
        (a, b) => {
          return parseFloat(a['sort_number']) - parseFloat(b['sort_number']);
        }
      )
    }
    return orderTemp;
  }

  const [listArchivioOriginal, setListArchivioOriginal] = React.useState(false);
  const [deleteOrderLoaded, setDeleteOrderLoaded] = React.useState(false);
  const [dataOrder, setDataOrder] = React.useState((localStorage.getItem("ht-dettaglio-archivio") && JSON.parse(localStorage.getItem("ht-dettaglio-archivio")).id == params.id) ? JSON.parse(localStorage.getItem("ht-dettaglio-archivio")) : false);
  const [order, setOrder] = React.useState((localStorage.getItem("ht-dettaglio-archivio") && JSON.parse(localStorage.getItem("ht-dettaglio-archivio")).id == params.id) ? sortOrder(JSON.parse(localStorage.getItem("ht-dettaglio-archivio")).json) : false);

  const [modal_fullscreen, setModal_fullscreen] = React.useState(false);
  const [pdfOpenedData, setPdfOpenedData] = React.useState({});
  const [loadedPDF, setLoadedPDF] = React.useState(false);

  const [numPages, setNumPages] = React.useState(null);
  const [urlPdf, setUrlPdf] = React.useState({});
  const [urlPdfBlob, setUrlPdfBlob] = React.useState(undefined);
  const [tags, setTags] = React.useState(dataOrder.tags ? dataOrder.tags : []);

  useEffect(() => {
    if (!dataOrder) {
      dispatch(getArchivio(setListArchivioOriginal));
    }
  }, [dataOrder])

  useEffect(() => {
    if (deleteOrderLoaded) {
      history.push("/archivio");
    }
  }, [deleteOrderLoaded])

  useEffect(() => {
    if (listArchivioOriginal) {
      let singleOrder = listArchivioOriginal.filter(item => item.id == params.id);
      if (singleOrder.length > 0) {
        setDataOrder(singleOrder[0]);
        setTags(singleOrder[0].tags);
        setOrder(sortOrder(singleOrder[0].json));
      } else {
        history.push("/archivio");
      }
    }
  }, [listArchivioOriginal])

  function compareStrings(a, b) {
    // Assuming you want case-insensitive comparison
    a = a.toLowerCase();
    b = b.toLowerCase();

    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }

  const prezzoCad = (prezzo, qty) => {
    if (qty > 0) {
      let arrayPrice = prezzo.filter(item => item.qta < qty);
      if (arrayPrice.length > 0) {
        let priceFinal = 0;
        let rightQty = 0;
        arrayPrice.forEach(item => {
          if (item.qta >= rightQty) {
            rightQty = item.qta;
            priceFinal = item.prezzo;
          }
        })
        return priceFinal;
      }
    } else {
      return 0;
    }
  }

  const prezzoTot = (prezzo, qty) => {
    if (qty > 0) {
      let arrayPrice = prezzo.filter(item => item.qta < qty);
      if (arrayPrice.length > 0) {
        let priceFinal = 0;
        let rightQty = 0;
        arrayPrice.forEach(item => {
          if (item.qta >= rightQty) {
            rightQty = item.qta;
            priceFinal = item.prezzo;
          }
        })
        return priceFinal * qty;
      }
    } else {
      return 0;
    }
  }

  const prezzoTotMacchinario = (order) => {
    let prezzoTotale = 0;
    let arrayPrice = order.macchinario.prezzo.filter(item => item.qta < 1);
    if (arrayPrice.length > 0) {
      let priceFinal = 0;
      let rightQty = 0;
      arrayPrice.forEach(item => {
        if (item.qta >= rightQty) {
          rightQty = item.qta;
          priceFinal = item.prezzo;
        }
      })
      prezzoTotale = prezzoTotale + Number(priceFinal);
    }

    order.accessori.forEach(accessorio => {
      if (accessorio.quantity > 0) {
        let arrayPrice = accessorio.prezzo.filter(item => item.qta < accessorio.quantity);
        if (arrayPrice.length > 0) {
          let priceFinal = 0;
          let rightQty = 0;
          arrayPrice.forEach(item => {
            if (item.qta >= rightQty) {
              rightQty = item.qta;
              priceFinal = item.prezzo;
            }
          })
          prezzoTotale = prezzoTotale + (Number(priceFinal) * accessorio.quantity);
        }
      }
    })

    order.accessoriObbligatori.forEach(accessorio => {
      if (accessorio.quantity > 0) {
        let arrayPrice = accessorio.prezzo.filter(item => item.qta < accessorio.quantity);
        if (arrayPrice.length > 0) {
          let priceFinal = 0;
          let rightQty = 0;
          arrayPrice.forEach(item => {
            if (item.qta >= rightQty) {
              rightQty = item.qta;
              priceFinal = item.prezzo;
            }
          })
          prezzoTotale = prezzoTotale + (Number(priceFinal) * accessorio.quantity);
        }
      }
    })

    order.optional.forEach(optional => {
      if (optional.quantity > 0) {
        let arrayPrice = optional.prezzo.filter(item => item.qta < optional.quantity);
        if (arrayPrice.length > 0) {
          let priceFinal = 0;
          let rightQty = 0;
          arrayPrice.forEach(item => {
            if (item.qta >= rightQty) {
              rightQty = item.qta;
              priceFinal = item.prezzo;
            }
          })
          prezzoTotale = prezzoTotale + (Number(priceFinal) * optional.quantity);
        }
      }
    })

    if (order.sconto && order.sconto > 0) {
      prezzoTotale = prezzoTotale - (prezzoTotale * (order.sconto / 100));
    }

    if (order.netPrice && order.netPrice > 0) {
      prezzoTotale = order.netPrice;
    }

    return prezzoTotale;
  }

  const prezzoTotMacchinarioNoSconto = (order) => {
    let prezzoTotale = 0;
    let arrayPrice = order.macchinario.prezzo.filter(item => item.qta < 1);
    if (arrayPrice.length > 0) {
      let priceFinal = 0;
      let rightQty = 0;
      arrayPrice.forEach(item => {
        if (item.qta >= rightQty) {
          rightQty = item.qta;
          priceFinal = item.prezzo;
        }
      })
      prezzoTotale = prezzoTotale + Number(priceFinal);
    }

    order.accessori.forEach(accessorio => {
      if (accessorio.quantity > 0) {
        let arrayPrice = accessorio.prezzo.filter(item => item.qta < accessorio.quantity);
        if (arrayPrice.length > 0) {
          let priceFinal = 0;
          let rightQty = 0;
          arrayPrice.forEach(item => {
            if (item.qta >= rightQty) {
              rightQty = item.qta;
              priceFinal = item.prezzo;
            }
          })
          prezzoTotale = prezzoTotale + (Number(priceFinal) * accessorio.quantity);
        }
      }
    })

    order.accessoriObbligatori.forEach(accessorio => {
      if (accessorio.quantity > 0) {
        let arrayPrice = accessorio.prezzo.filter(item => item.qta < accessorio.quantity);
        if (arrayPrice.length > 0) {
          let priceFinal = 0;
          let rightQty = 0;
          arrayPrice.forEach(item => {
            if (item.qta >= rightQty) {
              rightQty = item.qta;
              priceFinal = item.prezzo;
            }
          })
          prezzoTotale = prezzoTotale + (Number(priceFinal) * accessorio.quantity);
        }
      }
    })

    order.optional.forEach(optional => {
      if (optional.quantity > 0) {
        let arrayPrice = optional.prezzo.filter(item => item.qta < optional.quantity);
        if (arrayPrice.length > 0) {
          let priceFinal = 0;
          let rightQty = 0;
          arrayPrice.forEach(item => {
            if (item.qta >= rightQty) {
              rightQty = item.qta;
              priceFinal = item.prezzo;
            }
          })
          prezzoTotale = prezzoTotale + (Number(priceFinal) * optional.quantity);
        }
      }
    })

    return prezzoTotale;
  }

  const calcoloScontoEffettivo = (order) => {
    let totaleNoSconto = prezzoTotMacchinarioNoSconto(order);
    let totaleSconto = prezzoTotMacchinario(order);
    let result = {
      percentuale: 100 - (totaleSconto * 100 / totaleNoSconto),
      diff: totaleNoSconto - totaleSconto
    }
    return result;
  }

  const prezzoTotMaterialeDiConsumo = (order) => {
    let prezzoTotale = 0;

    order.materialiDiConsumo.forEach(materiale => {
      if (materiale.quantity > 0) {
        prezzoTotale = prezzoTotale + (Number(materiale.prezzo_totale));
      }
    })

    return prezzoTotale;
  }

  const tog_fullscreen = () => {
    dispatch(getPdfOrder(dataOrder.id, setLoadedPDF, setUrlPdf));
    setModal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  }

  useEffect(() => {
    if (loadedPDF && typeof urlPdf !== "undefined" && typeof urlPdf.pdf !== "undefined") {
      const blob = base64toBlob(urlPdf.pdf);
      setUrlPdfBlob(URL.createObjectURL(blob));
    }
  }, [urlPdf, loadedPDF]);

  const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data;

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const prezzoTotaleOrdine = () => {
    let orderTotal = order;
    let prezzoTotale = 0;
    let orders = orderTotal.order;
    // Calcolo macchianari
    if (orderTotal.type == "Macchinario") {
      let prezzoMacchinario = 0;
      orders.map(order => {
        prezzoTotale = prezzoTotale + prezzoTotMacchinario(order);

        // Calcolo materiali di consumo
        order.materialiDiConsumo.forEach(materiale => {
          if (materiale.quantity > 0) {
            prezzoTotale = prezzoTotale + (Number(materiale.prezzo_totale));
          }
        })
      });
    } else {
      // Calcolo materiali di consumo
      order.order.forEach(materiale => {
        if (materiale.quantity > 0) {
          prezzoTotale = prezzoTotale + (Number(materiale.prezzo_totale));
        }
      })
    }

    return prezzoTotale;
  }

  const handleCalculatorPrice = (data, prod = false) => {
    //data = {"qty": modalQty, "percentuale": modalPercentuale, "valUnitario": modalValUnitario, "omaggio": modalOmaggio}
    let prodToUse = prod;
    let totalPrice = 0;
    let unitaryPrice = 0;
    let arrayPrice = prodToUse.componente_prezzo.filter(item => item.qta <= data.qty);
    if (arrayPrice.length > 0) {
      let priceFinal = 0;
      let rightQty = 0;
      arrayPrice.forEach(item => {
        if (item.qta >= rightQty) {
          rightQty = item.qta;
          priceFinal = item.prezzo;
        }
      })
      unitaryPrice = priceFinal;
    }
    let rightPrice = unitaryPrice;
    if (data.qty > 0 && !data.omaggio && !data.promo && (data.valUnitario !== "" && data.valUnitario > 0)) {
      unitaryPrice = data.valUnitario;
    }

    if (data.qty > 0 && !data.omaggio && !data.promo && (data.percentuale !== "" && data.percentuale > 0)) {
      totalPrice = ((unitaryPrice) * ((100 - data.percentuale) / 100)) * data.qty;
    } else {
      totalPrice = unitaryPrice * data.qty;
    }

    if (data.omaggio || data.promo) {
      unitaryPrice = 0;
    }

    return {
      "normalPrice": rightPrice,
      "unitaryPrice": unitaryPrice,
      "totalPrice": totalPrice
    }
  }

  const getCapitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const getStringDate = (date) => {
    return Math.abs(differenceInDays(new Date(date.replace(/-/g, "/")), new Date())) < 6
      ? getCapitalize(formatRelative(new Date(date.replace(/-/g, "/")), new Date(), { locale: it }))
      : getCapitalize(new Date(date.replace(/-/g, "/")).toLocaleString('it-IT', { dateStyle: 'full', timeStyle: 'short' }));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dettaglio archivio | Gruppo Bisaro Cloud</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Attività" parentsBreadcrumb={[{ "title": "Archivio", "link": "/archivio" }]} breadcrumbItem={"Dettaglio " + (dataOrder.code.indexOf(".") > 0 ? dataOrder.code.substring(0, dataOrder.code.indexOf(".")) : dataOrder.code)} />
          <Row>
            <Col md="12">
              <Card>
                <div className="px-4 py-3 border-bottom">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item me-3" id="dueDate">
                      <h5><img className="short_list_icon_type" src={order.type === "Macchinario" ? macchinarioLogo : materialediconsumoLogo} alt={(order.proposta === 0 ? "Offerta" : "Proposta d'ordine") + " " + order.type} />{" "}{order.proposta === 0 ? "Offerta" : "Proposta d'ordine"}{" "}{order.type}
                        {" (" + (dataOrder.code !== "" ? (dataOrder.code.indexOf(".") > 0 ? dataOrder.code.substring(0, dataOrder.code.indexOf(".")) : dataOrder.code) : "Bozza") + ")"}</h5>
                      <div className="mb-1">{getStringDate(dataOrder.data)}</div>
                      {dataOrder.trasformato_da !== null && <Badge pill className="badge-primary ms-1">
                        Creato da offerta
                      </Badge>}
                      {dataOrder.trasformato_in !== null && <Badge pill className="badge-primary ms-1">
                        Trasformato in ordine
                      </Badge>}
                    </li>
                  </ul>
                </div>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1 overflow-hidden">
                      <h5 className="text-truncate font-size-15">
                        Cliente
                      </h5>
                      <p className="mb-0 font-size-12">
                        {order.cliente && typeof order.cliente.anagrafica_descrizione !== "undefined" && order.cliente.anagrafica_descrizione}
                      </p>
                      <p className="text-muted mb-0 font-size-10">{order.cliente && typeof order.cliente.anagrafica_codice !== "undefined" && ("Codice: " + order.cliente.anagrafica_codice)}</p>
                      <p className="text-muted mb-0 font-size-10">{order.cliente && typeof order.cliente.anagrafica_indirizzo !== "undefined" && order.cliente.anagrafica_indirizzo.length > 0 && (order.cliente.anagrafica_indirizzo[0].Indirizzo + " - " + order.cliente.anagrafica_indirizzo[0].Cap + ", " + order.cliente.anagrafica_indirizzo[0].Localita + " (" + order.cliente.anagrafica_indirizzo[0].Provincia + ")")}</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {typeof order.order !== "undefined" && order.type == "Macchinario" ?
              order.order.map(orderTemp =>
                <Col md="12" key={orderTemp.id}>
                  <Card>
                    <CardBody>
                      <><h5>Macchinario</h5>
                        <div className="table_wrapper_mobile">
                          <Table className="table table-striped table-bordered">
                            <Thead>
                              <Tr>
                                <Th>Codice</Th>
                                <Th>Descrizione</Th>
                                <Th style={{ textAlign: "right" }}>Prezzo</Th>
                              </Tr>
                            </Thead>
                            <tbody>
                              <tr>
                                <Td style={{ whiteSpace: "nowrap" }}>{orderTemp.macchinario.code}</Td>
                                <Td>{orderTemp.macchinario.nome}</Td>
                                <Td style={{ textAlign: "right" }}>{Number(prezzoTot(orderTemp.macchinario.prezzo, 1)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                              </tr>
                            </tbody>
                          </Table></div></>
                      {(orderTemp.accessoriObbligatori.length > 0 || orderTemp.accessori.length > 0) && <><h5>Accessori</h5>
                        <div className="table_wrapper_mobile"><Table className="table table-striped table-bordered">
                          <Thead>
                            <Tr>
                              <Th>Codice</Th>
                              <Th>Descrizione</Th>
                              <Th style={{ textAlign: "right" }}>Quantità</Th>
                              <Th style={{ whiteSpace: "nowrap", textAlign: "right" }}>Prezzo cad.</Th>
                              <Th style={{ whiteSpace: "nowrap", textAlign: "right" }}>Prezzo tot.</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {orderTemp.accessoriObbligatori.map((item, index) => <Tr key={item.code}>
                              <Td style={{ whiteSpace: "nowrap" }}>{item.code}</Td>
                              <Td>{item.nome}</Td>
                              <Td style={{ textAlign: "right" }}>{item.quantity}</Td>
                              <Td style={{ textAlign: "right" }}>{Number(prezzoCad(item.prezzo, item.quantity)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                              <Td style={{ textAlign: "right" }}>{Number(prezzoTot(item.prezzo, item.quantity)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                            </Tr>)}
                            {orderTemp.accessori.map((item, index) => <Tr key={item.code}>
                              {item.is_special ?
                                <><Td style={{ whiteSpace: "nowrap" }}>{item.code}</Td>
                                  <Td colSpan={4}>{typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome}
                                    {item.is_special && <><br /><Badge pill className="badge-primary ms-1">
                                      Elemento nota
                                    </Badge></>}</Td></> :
                                <><Td style={{ whiteSpace: "nowrap" }}>{item.code}</Td>
                                  <Td>{typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome}
                                    {item.is_special && <><br /><Badge pill className="badge-primary ms-1">
                                      Elemento nota
                                    </Badge></>}</Td>
                                  <Td style={{ textAlign: "right" }}>{item.quantity}</Td>
                                  <Td style={{ textAlign: "right" }}>{Number(prezzoCad(item.prezzo, item.quantity)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                                  <Td style={{ textAlign: "right" }}>{Number(prezzoTot(item.prezzo, item.quantity)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td></>}
                            </Tr>)}
                          </Tbody>
                        </Table></div></>}
                      {orderTemp.optional.length > 0 && <><h5>Optional</h5>
                        <div className="table_wrapper_mobile">
                          <Table className="table table-striped table-bordered">
                            <Thead>
                              <Tr>
                                <Th>Codice</Th>
                                <Th>Descrizione</Th>
                                <Th style={{ textAlign: "right" }}>Quantità</Th>
                                <Th style={{ whiteSpace: "nowrap", textAlign: "right" }}>Prezzo cad.</Th>
                                <Th style={{ whiteSpace: "nowrap", textAlign: "right" }}>Prezzo tot.</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {orderTemp.optional.map((item, index) => <Tr key={item.code}>
                                {item.is_special ?
                                  <><Td style={{ whiteSpace: "nowrap" }}>{item.code}</Td>
                                    <Td colSpan={4}>{typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome}
                                      {item.is_special && <><br /><Badge pill className="badge-primary ms-1">
                                        Elemento nota
                                      </Badge></>}</Td></> :
                                  <><Td style={{ whiteSpace: "nowrap" }}>{item.code}</Td>
                                    <Td>{typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome}
                                      {item.is_special && <><br /><Badge pill className="badge-primary ms-1">
                                        Elemento nota
                                      </Badge></>}</Td>
                                    <Td style={{ textAlign: "right" }}>{item.quantity}</Td>
                                    <Td style={{ textAlign: "right" }}>{Number(prezzoCad(item.prezzo, item.quantity)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                                    <Td style={{ textAlign: "right" }}>{Number(prezzoTot(item.prezzo, item.quantity)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td></>}
                              </Tr>)}
                            </Tbody>
                          </Table></div></>}
                      <div className="mb-3">
                        <label htmlFor="formControlDiscountPerc" className="mb-2"><strong>Sconto (%)</strong></label>
                        <input type="number" disabled={true} className="form-control" id="formControlDiscountPerc" placeholder="Sconto (%)" value={orderTemp.sconto} onChange={(e) => addDiscount(orderTemp, e.target.value)} />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="formControlDiscountPerc" className="mb-2"><strong>Prezzo netto</strong></label>
                        <input type="number" disabled={true} className="form-control" id="formControlDiscountPerc" placeholder="Prezzo netto" value={orderTemp.netPrice} onChange={(e) => addNetPrice(orderTemp, e.target.value)} />
                      </div>
                      <h5 style={{ textAlign: "right" }}>Totale macchinario: {Number(prezzoTotMacchinarioNoSconto(orderTemp)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</h5>
                      {calcoloScontoEffettivo(orderTemp).percentuale > 0 && <h5 style={{ textAlign: "right" }}>Sconto a voi riservato: {Number(calcoloScontoEffettivo(orderTemp).percentuale).toLocaleString("it-IT", { maximumFractionDigits: 2 }) + "%"} (-{Number(calcoloScontoEffettivo(orderTemp).diff).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"})</h5>}
                      <h4 style={{ textAlign: "right" }}>Netto macchinario: {Number(prezzoTotMacchinario(orderTemp)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</h4>

                      {orderTemp.materialiDiConsumo.length > 0 && <><div className="divider"></div>
                        <h5>Materiale di consumo</h5>
                        <div className="table_wrapper_mobile">
                          <Table className="table table-striped table-bordered">
                            <Thead>
                              <Tr>
                                <Th>Codice</Th>
                                <Th>Descrizione</Th>
                                <Th style={{ textAlign: "right" }}>Quantità</Th>
                                <Th style={{ textAlign: "right" }}>Prezzo di vendita unitario</Th>
                                <Th style={{ textAlign: "right" }}>Sconto</Th>
                                <Th style={{ textAlign: "right" }}>Prezzo di vendita finale</Th>
                                <Th style={{ textAlign: "right" }}>Prezzo totale</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {orderTemp.materialiDiConsumo.map((item, index) => <Tr key={item.code}>
                                {item.is_special ?
                                  <><Td style={{ whiteSpace: "nowrap" }}>{item.code}</Td>
                                    <Td colSpan={6}>{typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome}
                                      {item.is_special && <><br /><Badge pill className="badge-primary ms-1">
                                        Elemento nota
                                      </Badge></>}</Td></> :
                                  <><Td style={{ whiteSpace: "nowrap" }}>{item.code}</Td>
                                    <Td>{typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome}
                                      {item.is_special && <><br /><Badge pill className="badge-primary ms-1">
                                        Elemento nota
                                      </Badge></>}</Td>
                                    <Td style={{ textAlign: "right" }}>{item.quantity}</Td>
                                    <Td style={{ textAlign: "right" }}>{Number(item.prezzo_unitario).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                                    <Td style={{ textAlign: "right" }}>{item.sconto > 0 && item.sconto_type == 1 ? item.sconto + "%" : (item.sconto_type == 0 ? "Omaggio" : (item.sconto_type == 2 ? "Promo" : ""))}</Td>
                                    <Td style={{ textAlign: "right" }}>{Number(item.prezzo_unitario * (item.sconto > 0 && item.sconto_type == 1 ? ((100 - item.sconto) / 100) : 1)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                                    <Td style={{ textAlign: "right" }}>{Number(item.prezzo_totale).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td></>}
                              </Tr>)}
                            </Tbody>
                          </Table>
                        </div>
                        <h5 style={{ textAlign: "right" }}>Totale materiali di consumo: {Number(prezzoTotMaterialeDiConsumo(orderTemp)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</h5></>}
                    </CardBody>
                  </Card>
                </Col>) : ""}

            {order.type !== "Macchinario" && order.order.length > 0 && <>
              <Col md="12">
                <Card>
                  <CardBody>
                    <h5>Materiale di consumo</h5>
                    <div className="table_wrapper_mobile">
                      <Table className="table table-striped table-bordered">
                        <Thead>
                          <Tr>
                            <Th>Codice</Th>
                            <Th>Descrizione</Th>
                            <Th style={{ textAlign: "right" }}>Quantità</Th>
                            <Th style={{ textAlign: "right" }}>Prezzo di vendita unitario</Th>
                            <Th style={{ textAlign: "right" }}>Sconto</Th>
                            <Th style={{ textAlign: "right" }}>Prezzo di vendita finale</Th>
                            <Th style={{ textAlign: "right" }}>Prezzo totale</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {order.order.map((item, index) => <Tr key={item.code}>
                            {item.is_special ?
                              <><Td style={{ whiteSpace: "nowrap" }}>{item.code}</Td>
                                <Td colSpan={6}>{typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome}
                                  {item.is_special && <><br /><Badge pill className="badge-primary ms-1">
                                    Elemento nota
                                  </Badge></>}</Td></> :
                              <><Td style={{ whiteSpace: "nowrap" }}>{item.code}</Td>
                                <Td>{typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome}
                                  {item.is_special && <><br /><Badge pill className="badge-primary ms-1">
                                    Elemento nota
                                  </Badge></>}</Td>
                                <Td style={{ textAlign: "right" }}>{item.quantity}</Td>
                                <Td style={{ textAlign: "right" }}>{Number(item.prezzo_unitario).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                                <Td style={{ textAlign: "right" }}>{item.sconto > 0 && item.sconto_type == 1 ? item.sconto + "%" : (item.sconto_type == 0 ? "Omaggio" : (item.sconto_type == 2 ? "Promo" : ""))}</Td>
                                <Td style={{ textAlign: "right" }}>{Number(item.prezzo_unitario * (item.sconto > 0 && item.sconto_type == 1 ? ((100 - item.sconto) / 100) : 1)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                                <Td style={{ textAlign: "right" }}>{Number(item.prezzo_totale).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td></>}
                          </Tr>)}
                        </Tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col></>}

            <Col md="12" className="ht-important-card">
              <Card>
                <CardBody>
                  <h3 className="m-0" style={{ "textAlign": "right" }}>Totale {(order.proposta === 0 ? "offerta" : "ordine")}: {Number(prezzoTotaleOrdine()).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</h3>
                </CardBody>
              </Card>
            </Col>

            {typeof order.condizioni !== "undefined" ? <Col md="12">
              <Card>
                <div className="px-4 py-3 border-bottom">
                  <h5>Condizioni & pagamenti</h5>
                </div>
                <CardBody>
                  <strong className="d-block mb-2">Condizioni di vendita</strong>
                  {order.condizioni.sort(function (a, b) { return compareStrings(a.condizione, b.condizione); }).map((item) => <small className="d-block px-3" key={item.idCondizione}>{item.condizione}:<strong dangerouslySetInnerHTML={{ __html: item.valore.descrizione }}></strong></small>)}
                  {order.proposta === 1 && typeof order.spedizione.Indirizzo !== "undefined" && <small className="d-block px-3">Indirizzo di spedizione:<p><strong dangerouslySetInnerHTML={{ __html: (order.spedizione.Descrizione ? order.spedizione.Descrizione + "<br/>" : "") + order.spedizione.Indirizzo + "<br/>(" + order.spedizione.Cap + ") " + order.spedizione.Localita + " (" + order.spedizione.Provincia + ")" }}></strong></p></small>}
                  {typeof order.pagamenti !== "undefined" && <><strong className="d-block mb-2">Metodo di pagamento</strong>
                    {order.pagamenti.map((item) => <small className="d-block px-3" key={item.idCondizione}><strong className="ht_no_margin_p" dangerouslySetInnerHTML={{ __html: item.valore.descrizione }}></strong></small>)}</>}
                </CardBody>
              </Card>
            </Col> : ""}

            <Col md="12">
              <Card>
                <div className="px-4 py-3 border-bottom">
                  <h5>Note dell&apos;agente</h5>
                </div>
                <CardBody>
                  <textarea className="form-control" rows={5} disabled={true} onChange={(event) => {
                    let orderTotalForNote = order;
                    orderTotalForNote.note = event.target.value;
                    localStorage.setItem("ht-order", JSON.stringify(orderTotalForNote));
                  }}>{order.note}</textarea>
                </CardBody>
              </Card>
            </Col>

            <Col md="12">
              <Card>
                <div className="px-4 py-3 border-bottom">
                  <h5>Tag</h5>
                </div>
                <CardBody className="d-flex flex-column justify-content-between ht_btn_bar_top tagInput">
                  <TagsInput setTags={setTags} tags={tags} />
                  <span>Premere invio per confermare ogni singolo tag<br />Si possono inserire tag multipli</span>
                  <br />
                  {tagsLoader ? <Spinner className="ms-2" color="primary" /> : <div className="btn btn-primary" onClick={() => {
                    setTagsLoader(true);
                    let data = {};
                    data.orderId = dataOrder.id ? dataOrder.id : dataOrder.code;
                    data.tags = tags;
                    let dettArchivio = JSON.parse(localStorage.getItem("ht-dettaglio-archivio"));
                    dettArchivio.tags = tags;
                    localStorage.setItem("ht-dettaglio-archivio", JSON.stringify(dettArchivio));
                    dispatch(setTagOrder(setTagsLoader, data));
                  }}>Salva i tag</div>}
                </CardBody>
              </Card>
            </Col>

            <Col md="12">
              <Card>
                <div className="px-4 py-3 border-bottom">
                  <h5>Azioni</h5>
                </div>
                <CardBody className="d-flex justify-content-between ht_btn_bar_top">
                  {!dataOrder.is_draft && <div className="btn btn-light"
                    onClick={() => tog_fullscreen()}
                  >
                    <i className="bx bxs-file-pdf font-size-16 align-middle me-2"></i> Visualizza PDF
                  </div>}
                  <div className="btn btn-primary"
                    onClick={() => {
                      let trasformOrder = order;
                      trasformOrder.duplicate = 1;
                      delete trasformOrder.ref_id;
                      delete trasformOrder.was_draft;
                      delete trasformOrder.edited;
                      localforage.getItem("prodotti").then(function (products) {
                        let productsTemp = products;
                        if (trasformOrder.type == "Macchinario") {
                          trasformOrder.order.forEach((item, index) => {
                            // Aggiorno prezzi macchinari
                            let prod = productsTemp.filter(i => i.componente_codice == item.macchinario.code);
                            if (prod.length > 0) {
                              trasformOrder.order[index].macchinario.prezzo = prod[0].componente_prezzo;
                            } else {
                              trasformOrder.order = trasformOrder.order.filter((i) => i.macchinario.code !== item.macchinario.code);
                            }

                            // Aggiorno prezzi accessori
                            trasformOrder.order[index].accessori.forEach((acc, indexAcc) => {
                              let prod = productsTemp.filter(i => i.componente_codice == acc.code);
                              if (prod.length > 0) {
                                trasformOrder.order[index].accessori[indexAcc].prezzo = prod[0].componente_prezzo;
                              } else {
                                trasformOrder.order[index].accessori = trasformOrder.order[index].accessori.filter((i) => i.code !== acc.code);
                              }
                            })

                            // Aggiorno prezzi accessori obbligatori
                            trasformOrder.order[index].accessoriObbligatori.forEach((acc, indexAcc) => {
                              let prod = productsTemp.filter(i => i.componente_codice == acc.code);
                              if (prod.length > 0) {
                                trasformOrder.order[index].accessoriObbligatori[indexAcc].prezzo = prod[0].componente_prezzo;
                              } else {
                                trasformOrder.order[index].accessoriObbligatori = trasformOrder.order[index].accessoriObbligatori.filter((i) => i.code !== acc.code);
                              }
                            })

                            // Aggiorno prezzi optional
                            trasformOrder.order[index].optional.forEach((acc, indexAcc) => {
                              let prod = productsTemp.filter(i => i.componente_codice == acc.code);
                              if (prod.length > 0) {
                                trasformOrder.order[index].optional[indexAcc].prezzo = prod[0].componente_prezzo;
                              } else {
                                trasformOrder.order[index].optional = trasformOrder.order[index].optional.filter((i) => i.code !== acc.code);
                              }
                            })

                            // Aggiorno prezzi materiali di consumo
                            trasformOrder.order[index].materialiDiConsumo.forEach((acc, indexAcc) => {
                              let prod = productsTemp.filter(i => i.componente_codice == acc.code);
                              if (prod.length > 0) {
                                if (trasformOrder.order[index].materialiDiConsumo[indexAcc].sconto_type < 0
                                  || (trasformOrder.order[index].materialiDiConsumo[indexAcc].sconto_type == 1 && trasformOrder.order[index].materialiDiConsumo[indexAcc].scontoUnitario == 0)) {
                                  let prezzi = handleCalculatorPrice({ "qty": trasformOrder.order[index].materialiDiConsumo[indexAcc].quantity, "percentuale": trasformOrder.order[index].materialiDiConsumo[indexAcc].sconto, "valUnitario": trasformOrder.order[index].materialiDiConsumo[indexAcc].scontoUnitario, "omaggio": trasformOrder.order[index].materialiDiConsumo[indexAcc].sconto_type == 0, "promo": trasformOrder.order[index].materialiDiConsumo[indexAcc].sconto_type == 2 }, prod[0]);
                                  trasformOrder.order[index].materialiDiConsumo[indexAcc].prezzo = prezzi.normalPrice;
                                  trasformOrder.order[index].materialiDiConsumo[indexAcc].prezzo_totale = prezzi.totalPrice;
                                  trasformOrder.order[index].materialiDiConsumo[indexAcc].prezzo_unitario = prezzi.unitaryPrice;
                                }
                                trasformOrder.order[index].materialiDiConsumo[indexAcc].prod = prod[0];
                              } else {
                                trasformOrder.order[index].materialiDiConsumo = trasformOrder.order[index].materialiDiConsumo.filter((i) => i.code !== acc.code);
                              }
                            })
                          })
                        } else {
                          // Aggiorno prezzi materiali di consumo
                          trasformOrder.order.forEach((mat, index) => {
                            let prod = productsTemp.filter(i => i.componente_codice == mat.code);
                            if (prod.length > 0) {
                              if (trasformOrder.order[index].sconto_type < 0
                                || (trasformOrder.order[index].sconto_type == 1 && trasformOrder.order[index].scontoUnitario == 0)) {
                                let prezzi = handleCalculatorPrice({ "qty": trasformOrder.order[index].quantity, "percentuale": trasformOrder.order[index].sconto, "valUnitario": trasformOrder.order[index].scontoUnitario, "omaggio": trasformOrder.order[index].sconto_type == 0, "promo": trasformOrder.order[index].sconto_type == 2 }, prod[0]);
                                trasformOrder.order[index].prezzo = prezzi.normalPrice;
                                trasformOrder.order[index].prezzo_totale = prezzi.totalPrice;
                                trasformOrder.order[index].prezzo_unitario = prezzi.unitaryPrice;
                              }
                              trasformOrder.order[index].prod = prod[0];
                            } else {
                              trasformOrder.order = trasformOrder.order.filter((i) => i.code !== mat.code);
                            }
                          })
                        }

                        if (trasformOrder.condizioni) {
                          trasformOrder.condizioni = trasformOrder.condizioni.filter((item) => item.condizione !== "Consegna");
                        }

                        delete trasformOrder.condizioni;
                        delete trasformOrder.pagamenti;

                        localStorage.setItem("ht-order", JSON.stringify(trasformOrder));
                        localStorage.setItem("tags", JSON.stringify(tags));
                        history.push((trasformOrder.proposta === 1 ? "/nuovo-ordine" : "/nuova-offerta") + (trasformOrder.type === "Macchinario" ? "/macchinario" : "/materialediconsumo") + "/scelta-condizionidivendita");
                      });
                    }}
                  >
                    <i className="bx bx-duplicate font-size-16 align-middle me-2"></i> Duplica
                  </div>
                  <div className="btn btn-primary"
                    onClick={() => {
                      let trasformOrder = order;
                      trasformOrder.edited = 1;
                      trasformOrder.code = (dataOrder.code.indexOf(".") > 0 ? dataOrder.code.substring(0, dataOrder.code.indexOf(".")) : dataOrder.code);
                      if (dataOrder.is_draft == 1) {
                        trasformOrder.was_draft = dataOrder.id;
                      }
                      localforage.getItem("prodotti").then(function (products) {
                        let productsTemp = products;
                        if (trasformOrder.type == "Macchinario") {
                          trasformOrder.order.forEach((item, index) => {
                            // Aggiorno prezzi macchinari
                            let prod = productsTemp.filter(i => i.componente_codice == item.macchinario.code);
                            if (prod.length > 0) {
                              trasformOrder.order[index].macchinario.prezzo = prod[0].componente_prezzo;
                            } else {
                              trasformOrder.order = trasformOrder.order.filter((i) => i.macchinario.code !== item.macchinario.code);
                            }

                            // Aggiorno prezzi accessori
                            trasformOrder.order[index].accessori.forEach((acc, indexAcc) => {
                              let prod = productsTemp.filter(i => i.componente_codice == acc.code);
                              if (prod.length > 0) {
                                trasformOrder.order[index].accessori[indexAcc].prezzo = prod[0].componente_prezzo;
                              } else {
                                trasformOrder.order[index].accessori = trasformOrder.order[index].accessori.filter((i) => i.code !== acc.code);
                              }
                            })

                            // Aggiorno prezzi accessori obbligatori
                            trasformOrder.order[index].accessoriObbligatori.forEach((acc, indexAcc) => {
                              let prod = productsTemp.filter(i => i.componente_codice == acc.code);
                              if (prod.length > 0) {
                                trasformOrder.order[index].accessoriObbligatori[indexAcc].prezzo = prod[0].componente_prezzo;
                              } else {
                                trasformOrder.order[index].accessoriObbligatori = trasformOrder.order[index].accessoriObbligatori.filter((i) => i.code !== acc.code);
                              }
                            })

                            // Aggiorno prezzi optional
                            trasformOrder.order[index].optional.forEach((acc, indexAcc) => {
                              let prod = productsTemp.filter(i => i.componente_codice == acc.code);
                              if (prod.length > 0) {
                                trasformOrder.order[index].optional[indexAcc].prezzo = prod[0].componente_prezzo;
                              } else {
                                trasformOrder.order[index].optional = trasformOrder.order[index].optional.filter((i) => i.code !== acc.code);
                              }
                            })

                            // Aggiorno prezzi materiali di consumo
                            trasformOrder.order[index].materialiDiConsumo.forEach((acc, indexAcc) => {
                              let prod = productsTemp.filter(i => i.componente_codice == acc.code);
                              if (prod.length > 0) {
                                if (trasformOrder.order[index].materialiDiConsumo[indexAcc].sconto_type < 0
                                  || (trasformOrder.order[index].materialiDiConsumo[indexAcc].sconto_type == 1 && trasformOrder.order[index].materialiDiConsumo[indexAcc].scontoUnitario == 0)) {
                                  let prezzi = handleCalculatorPrice({ "qty": trasformOrder.order[index].materialiDiConsumo[indexAcc].quantity, "percentuale": trasformOrder.order[index].materialiDiConsumo[indexAcc].sconto, "valUnitario": trasformOrder.order[index].materialiDiConsumo[indexAcc].scontoUnitario, "omaggio": trasformOrder.order[index].materialiDiConsumo[indexAcc].sconto_type == 0, "promo": trasformOrder.order[index].materialiDiConsumo[indexAcc].sconto_type == 2 }, prod[0]);
                                  trasformOrder.order[index].materialiDiConsumo[indexAcc].prezzo = prezzi.normalPrice;
                                  trasformOrder.order[index].materialiDiConsumo[indexAcc].prezzo_totale = prezzi.totalPrice;
                                  trasformOrder.order[index].materialiDiConsumo[indexAcc].prezzo_unitario = prezzi.unitaryPrice;
                                }
                                trasformOrder.order[index].materialiDiConsumo[indexAcc].prod = prod[0];
                              } else {
                                trasformOrder.order[index].materialiDiConsumo = trasformOrder.order[index].materialiDiConsumo.filter((i) => i.code !== acc.code);
                              }
                            })
                          })
                        } else {
                          // Aggiorno prezzi materiali di consumo
                          trasformOrder.order.forEach((mat, index) => {
                            let prod = productsTemp.filter(i => i.componente_codice == mat.code);
                            if (prod.length > 0) {
                              if (trasformOrder.order[index].sconto_type < 0
                                || (trasformOrder.order[index].sconto_type == 1 && trasformOrder.order[index].scontoUnitario == 0)) {
                                let prezzi = handleCalculatorPrice({ "qty": trasformOrder.order[index].quantity, "percentuale": trasformOrder.order[index].sconto, "valUnitario": trasformOrder.order[index].scontoUnitario, "omaggio": trasformOrder.order[index].sconto_type == 0, "promo": trasformOrder.order[index].sconto_type == 2 }, prod[0]);
                                trasformOrder.order[index].prezzo = prezzi.normalPrice;
                                trasformOrder.order[index].prezzo_totale = prezzi.totalPrice;
                                trasformOrder.order[index].prezzo_unitario = prezzi.unitaryPrice;
                              }
                              trasformOrder.order[index].prod = prod[0];
                            } else {
                              trasformOrder.order = trasformOrder.order.filter((i) => i.code !== mat.code);
                            }
                          })
                        }
                        localStorage.setItem("ht-order", JSON.stringify(trasformOrder));
                        localStorage.setItem("tags", JSON.stringify(tags));
                        if (!trasformOrder.condizioni) {
                          history.push((trasformOrder.proposta === 1 ? "/nuovo-ordine" : "/nuova-offerta") + (trasformOrder.type === "Macchinario" ? "/macchinario" : "/materialediconsumo") + "/scelta-condizionidivendita");
                        } else if (trasformOrder.condizioni && !trasformOrder.pagamenti) {
                          history.push((trasformOrder.proposta === 1 ? "/nuovo-ordine" : "/nuova-offerta") + (trasformOrder.type === "Macchinario" ? "/macchinario" : "/materialediconsumo") + "/scelta-condizionidivendita");
                        } else if (trasformOrder.condizioni && trasformOrder.pagamenti) {
                          history.push((trasformOrder.proposta === 1 ? "/nuovo-ordine" : "/nuova-offerta") + (trasformOrder.type === "Macchinario" ? "/macchinario" : "/materialediconsumo") + "/riepilogo");
                        }
                      });
                    }}
                  >
                    <i className="bx bx-edit font-size-16 align-middle me-2"></i> Modifica
                  </div>
                  {order.proposta === 0 && !dataOrder.is_draft && <div className="btn btn-success"
                    onClick={() => {
                      let trasformOrder = order;
                      trasformOrder.proposta = 1;
                      trasformOrder.ref_id = dataOrder.id;
                      delete trasformOrder.condizioni;
                      delete trasformOrder.pagamenti;
                      localStorage.setItem("ht-order", JSON.stringify(trasformOrder));
                      localStorage.setItem("tags", JSON.stringify(tags));
                      if (!order.condizioni) {
                        history.push((trasformOrder.proposta === 1 ? "/nuovo-ordine" : "/nuova-offerta") + (trasformOrder.type === "Macchinario" ? "/macchinario" : "/materialediconsumo") + "/scelta-condizionidivendita");
                      } else if (order.condizioni && !order.pagamenti) {
                        history.push((trasformOrder.proposta === 1 ? "/nuovo-ordine" : "/nuova-offerta") + (trasformOrder.type === "Macchinario" ? "/macchinario" : "/materialediconsumo") + "/scelta-condizionidivendita");
                      } else if (order.condizioni && order.pagamenti) {
                        history.push((trasformOrder.proposta === 1 ? "/nuovo-ordine" : "/nuova-offerta") + (trasformOrder.type === "Macchinario" ? "/macchinario" : "/materialediconsumo") + "/riepilogo");
                      }
                    }}
                  >
                    <i className="bx bx-check-circle font-size-16 align-middle me-2"></i> Trasforma in Ordine
                  </div>}
                  <div className="btn btn-danger"
                    onClick={() => {
                      Swal.fire({
                        title: 'Eliminazione ' + (dataOrder.is_draft == 1 ? "bozza" : (order.proposta === 1 ? "Ordine" : "Offerta")),
                        text: 'Sei sicuro di voler eliminare ' + (order.proposta === 1 ? "la proposta d'ordine " : "l'offerta ") + (dataOrder.code.indexOf(".") > 0 ? dataOrder.code.substring(0, dataOrder.code.indexOf(".")) : dataOrder.code) + '?',
                        type: 'warning',
                        showCancelButton: true,
                        cancelButtonText: 'Annulla',
                        confirmButtonText: 'Sì, elimina!',
                        confirmButtonColor: '#f46a6a',
                      }).then((result) => {
                        if (result.isConfirmed) {
                          let callData = {};
                          if (dataOrder.is_draft == 1) {
                            callData.id = dataOrder.id;
                          } else {
                            callData.code = dataOrder.code;
                          }
                          dispatch(deleteOrder(setDeleteOrderLoaded, callData));
                        }
                      })
                    }}
                  >
                    <i className="bx bx-trash-alt font-size-16 align-middle me-2 "></i> Elimina
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            isOpen={modal_fullscreen}
            toggle={() => {
              tog_fullscreen();
            }}
            className="modal-fullscreen"
          >
            <div className="modal-header">
              <h5
                className="modal-title mt-0"
                id="exampleModalFullscreenLabel"
              >
                {dataOrder.code.indexOf(".") > 0 ? dataOrder.code.substring(0, dataOrder.code.indexOf(".")) : dataOrder.code} - {order.proposta === 1 ? "Proposta d'ordine " : "Offerta "}
              </h5>
              <button
                onClick={() => {
                  setModal_fullscreen(false);
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                {!loadedPDF && <Spinner className="ms-2" color="primary" />}
                {loadedPDF && typeof urlPdfBlob !== "undefined" && <HTFileViewer memo_extension={"pdf"} memo_urlFile={urlPdfBlob} />}
              </div>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

const HTFileViewer = memo(function Greeting({ memo_extension, memo_urlFile }) {
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  return <Worker workerUrl="pdf.worker.js">
    <div style={{
      flex: "1",
      overflow: "hidden",
      height: "100%"
    }}>
      <div
        className="rpv-core__viewer"
        style={{
          display: 'flex',
          height: '100%',
          position: 'relative',
        }}
      >
        <div
          style={{
            alignItems: 'center',
            backgroundColor: '#eeeeee',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            borderRadius: '2px',
            bottom: '16px',
            display: 'flex',
            left: '50%',
            padding: '4px',
            position: 'absolute',
            transform: 'translate(-50%, 0)',
            zIndex: 1,
          }}
        >
          <Toolbar>
            {(props: ToolbarSlot) => {
              const {
                CurrentPageInput,
                GoToNextPage,
                GoToPreviousPage,
                NumberOfPages,
                ZoomIn,
                ZoomOut,
              } = props;
              return (
                <>
                  <div style={{ padding: '0px 2px' }}>
                    <ZoomOut />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <ZoomIn />
                  </div>
                  <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                    <GoToPreviousPage />
                  </div>
                  <div style={{ padding: '0px 2px', width: '4rem' }}>
                    <CurrentPageInput />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    / <NumberOfPages />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <GoToNextPage />
                  </div>
                </>
              );
            }}
          </Toolbar>
        </div>
        <div
          style={{
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <Viewer fileUrl={memo_urlFile} plugins={[toolbarPluginInstance]} />
        </div>
      </div>
    </div>
  </Worker>
});

HTFileViewer.propTypes = {
  memo_urlFile: PropTypes.any,
  memo_extension: PropTypes.any,
};

DettaglioArchivio.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  match: PropTypes.any,
}

export default withRouter(withTranslation()(DettaglioArchivio));
import {
  GET_FILEDOWNLOAD,
  GET_FILEDOWNLOAD_FAIL,
  GET_FILEDOWNLOAD_SUCCESS,
} from "./actionTypes"

export const getFileTraningDownload = (key, setFileLoaded, setUrlFile) => ({
  type: GET_FILEDOWNLOAD,
  payload: {key, setFileLoaded, setUrlFile},
})

export const getFileTraningDownloadSuccess = pdfOrder => ({
  type: GET_FILEDOWNLOAD_SUCCESS,
  payload: pdfOrder,
})

export const getFileTraningDownloadFail = error => ({
  type: GET_FILEDOWNLOAD_FAIL,
  payload: error,
})
import {
  SET_DRAFT_SUCCESS,
  SET_DRAFT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  saveDraft: [],
  error: {},
}

const setDraft = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DRAFT_SUCCESS:
      return {
        ...state,
        saveDraft: action.payload,
      }

    case SET_DRAFT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default setDraft

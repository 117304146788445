import PropTypes from "prop-types";
import React, { memo, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { withRouter, useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Spinner
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

//redux
import { useDispatch } from "react-redux";

import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

import {
  getPdf
} from "store/pdf/actions";

import { withTranslation } from "react-i18next";

import ProductListMaterialeDiConsumo from "components/Heytech/ProductListMaterialeDiConsumo";
import ProgressBar from "components/Heytech/ProgressBar";
import ShortInfo from "components/Heytech/ShortInfo";
import uuid from 'react-uuid';
import SaveBozzaBtn from "components/Heytech/SaveBozzaBtn";
import SaveBozzaAuto from "components/Heytech/SaveBozzaAuto";

const SceltaMaterialiDiConsumo = props => {
  const history = useHistory();
  const [orderTot, setOrderTot] = React.useState(localStorage.getItem("ht-order") ? JSON.parse(localStorage.getItem("ht-order")) : "");
  const [orderTemp, setOrderTemp] = React.useState(localStorage.getItem("ht-orderTemp") ? JSON.parse(localStorage.getItem("ht-orderTemp")) : "");
  const [progressValue, setProgressValue] = React.useState(typeof orderTemp.id === "undefined" && orderTot.type == "Macchinario" ? 60 : (orderTot.type !== "Macchinario" ? 1 : 100));

  const dispatch = useDispatch();

  const [modal_fullscreen, setModal_fullscreen] = React.useState(false);
  const [pdfOpenedData, setPdfOpenedData] = React.useState({});
  const [loadedPDF, setLoadedPDF] = React.useState(false);

  const [numPages, setNumPages] = React.useState(null);
  const [urlPdf, setUrlPdf] = React.useState({});
  const [urlPdfBlob, setUrlPdfBlob] = React.useState(undefined);
  const [changedLocalStorage, setChangedLocalStorage] = React.useState(false);

  useEffect(() => {
    if(loadedPDF && typeof urlPdf !== "undefined" && typeof urlPdf.pdf !== "undefined") {
      const blob = base64toBlob(urlPdf.pdf);
      setUrlPdfBlob(URL.createObjectURL(blob));
    }
  }, [urlPdf, loadedPDF]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const tog_fullscreen = (pdfId = "") => {
    if(pdfId !== ""){
      dispatch(getPdf(pdfId, setLoadedPDF, setUrlPdf));
      setModal_fullscreen(!modal_fullscreen);
      removeBodyCss();
    } else if(typeof pdfOpenedData.id_pdf !== "undefined"){
      dispatch(getPdf(pdfOpenedData.id_pdf, setLoadedPDF, setUrlPdf));
      setModal_fullscreen(!modal_fullscreen);
      removeBodyCss();
    }
  }
  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  }

  const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data;

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{typeof orderTemp.id === "undefined" ? "Scelta" : "Modifica" } materiali di consumo | Gruppo Bisaro Cloud</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Attività" parentsBreadcrumb={[{"title":(orderTot. proposta === 0 ? "Crea Offerta" : "Crea Ordine"), "link":(orderTot. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")}]} breadcrumbItem={(typeof orderTemp.id === "undefined" ? "Scelta" : "Modifica") + " materiali di consumo"} />
          <Row>
            <ShortInfo changedLocalStorage={changedLocalStorage}/>
            <Col md="12">
              <Card>
                <CardBody>
                  <ProgressBar numberBable={typeof orderTemp.id === "undefined" && orderTot.type == "Macchinario" ? 6 : 3} progressValue={progressValue}/>
                  <div className="d-flex justify-content-between pt-4 ht_btn_bar_top">
                    {orderTot.type !== "Macchinario" && <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        history.push((orderTot. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine"));
                      }}
                    >
                      <i className="bx bx-left-arrow-alt font-size-16 align-middle"></i>{" "}
                      Cliente
                    </button>}
                    {orderTot.type == "Macchinario" && <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        history.push((orderTot. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")+"/macchinario/scelta-optional");
                      }}
                    >
                      <i className="bx bx-left-arrow-alt font-size-16 align-middle"></i>{" "}
                      Optional
                    </button>}
                    {typeof orderTemp.id === "undefined" && 
                      <SaveBozzaBtn disabled={JSON.parse(localStorage.getItem("ht-order")).order.length == 0 ? true : false}/>}
                    
                    {typeof orderTemp.id !== "undefined" && <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => {
                        history.push((orderTot. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")+"/macchinario/riepilogo");
                      }}
                    >
                      <i className="bx bx-x font-size-16 align-middle"></i>{" "}
                      Annulla modifica
                    </button>}
                    {typeof orderTemp.id !== "undefined" && <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => {
                        let orderTemp = JSON.parse(localStorage.getItem("ht-orderTemp"));
                        let order = JSON.parse(localStorage.getItem("ht-order"));
                        let orderTempDef = order.order.filter(item => item.id !== orderTemp.id);
                        orderTempDef.push(orderTemp);
                        order.order = orderTempDef;
                        localStorage.setItem("ht-order", JSON.stringify(order));
                        history.push((orderTot. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")+"/macchinario/riepilogo");
                      }}
                    >
                      <i className="bx bx-save font-size-16 align-middle"></i>{" "}
                      Salva modifica
                    </button>}

                    {orderTot.type == "Macchinario" && typeof orderTemp.id === "undefined" && <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        let orderTemp = JSON.parse(localStorage.getItem("ht-orderTemp"));
                        orderTemp.id = uuid();
                        let order = JSON.parse(localStorage.getItem("ht-order"));
                        order.order.push(orderTemp);
                        localStorage.setItem("ht-order", JSON.stringify(order));
                        localStorage.removeItem("ht-orderTemp");
                        history.push((orderTot. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")+"/macchinario/scelta-macchina");
                      }}
                    >
                      <i className="bx bx-plus font-size-16 align-middle"></i>{" "}
                      Aggiungi macchinario
                    </button>}
                    {typeof orderTemp.id === "undefined" && <button
                      type="button"
                      className="btn btn-primary"
                      disabled={orderTot.type !== "Macchinario" && JSON.parse(localStorage.getItem("ht-order")).order.length == 0 ? true : false}
                      onClick={() => {
                        if(orderTot.type == "Macchinario" ){
                          let orderTemp = JSON.parse(localStorage.getItem("ht-orderTemp"));
                          orderTemp.id = uuid();
                          let order = JSON.parse(localStorage.getItem("ht-order"));
                          order.order.push(orderTemp);
                          localStorage.setItem("ht-order", JSON.stringify(order));
                          localStorage.removeItem("ht-orderTemp");
                          history.push((orderTot. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")+"/macchinario/scelta-condizionidivendita");
                        } else {
                          history.push((orderTot. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")+"/materialediconsumo/scelta-condizionidivendita");
                        }
                      }}
                    >
                      Condizioni di vendita{" "}
                      <i className="bx bx-right-arrow-alt font-size-16 align-middle"></i>
                    </button>}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <CardBody>
                  <ProductListMaterialeDiConsumo
                    title={<h5 className="mb-0">Seleziona i materiali di consumo</h5>}
                    filterQuery={orderTot.type == "Macchinario" ? [{"key":"componente_id", "value": (orderTemp.macchinario && orderTemp.macchinario.materiale_di_consumo) ? orderTemp.macchinario.materiale_di_consumo : []}] : "all"}
                    urlPdf={urlPdf} setUrlPdf={setUrlPdf}
                    setLoadedPDF={setLoadedPDF}
                    loadedPDF={loadedPDF}
                    tog_fullscreen={tog_fullscreen}
                    setPdfOpenedData={setPdfOpenedData}
                    changedLocalStorage = {changedLocalStorage}
                    setChangedLocalStorage = {setChangedLocalStorage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            isOpen={modal_fullscreen}
            toggle={() => {
              tog_fullscreen();
            }}
            className="modal-fullscreen"
          >
            <div className="modal-header">
              <h5
                className="modal-title mt-0"
                id="exampleModalFullscreenLabel"
              >
                {pdfOpenedData.nome} - Scheda tecnica
              </h5>
              <button
                onClick={() => {
                  setModal_fullscreen(false);
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                {!loadedPDF && <Spinner className="ms-2" color="primary" />}
                {loadedPDF && typeof urlPdfBlob !== "undefined" && <HTFileViewer memo_extension = {"pdf"} memo_urlFile={urlPdfBlob} />}
              </div>
            </div>
          </Modal>
        </Container>
      </div>
      <SaveBozzaAuto />
    </React.Fragment>
  )
}

const HTFileViewer = memo(function Greeting({ memo_extension, memo_urlFile }) {
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  return <Worker workerUrl="pdf.worker.js">
    <div style={{
      flex: "1",
      overflow: "hidden",
      height: "100%"
    }}>
      <div
        className="rpv-core__viewer"
        style={{
          display: 'flex',
          height: '100%',
          position: 'relative',
        }}
      >
        <div
          style={{
            alignItems: 'center',
            backgroundColor: '#eeeeee',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            borderRadius: '2px',
            bottom: '16px',
            display: 'flex',
            left: '50%',
            padding: '4px',
            position: 'absolute',
            transform: 'translate(-50%, 0)',
            zIndex: 1,
          }}
        >
          <Toolbar>
            {(props: ToolbarSlot) => {
              const {
                CurrentPageInput,
                GoToNextPage,
                GoToPreviousPage,
                NumberOfPages,
                ZoomIn,
                ZoomOut,
              } = props;
              return (
                <>
                  <div style={{ padding: '0px 2px' }}>
                    <ZoomOut />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <ZoomIn />
                  </div>
                  <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                    <GoToPreviousPage />
                  </div>
                  <div style={{ padding: '0px 2px', width: '4rem' }}>
                    <CurrentPageInput />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    / <NumberOfPages />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <GoToNextPage />
                  </div>
                </>
              );
            }}
          </Toolbar>
        </div>
        <div
          style={{
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <Viewer fileUrl={memo_urlFile} plugins={[toolbarPluginInstance]} />
        </div>
      </div>
    </div>
  </Worker>
});

HTFileViewer.propTypes = {
  memo_urlFile: PropTypes.any,
  memo_extension: PropTypes.any,
};

SceltaMaterialiDiConsumo.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
}

export default withRouter(withTranslation()(SceltaMaterialiDiConsumo));
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { withRouter, useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Badge
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import Breadcrumbs from "components/Common/Breadcrumb";

import { withTranslation } from "react-i18next";

import macchinarioLogo from "assets/images/macchinarioLogo.png";
import materialediconsumoLogo from "assets/images/materialediconsumoLogo.png";

import Swal from 'sweetalert2'
import { template } from "lodash";
import ShowPrice from "components/Heytech/ShowPrice";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import SignaturePad from 'react-signature-canvas';

import SaveBozzaBtn from "components/Heytech/SaveBozzaBtn";
import SaveOrdineBtn from "components/Heytech/SaveOrdineBtn";
import TagsInput from "components/Heytech/TagsInput";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SaveBozzaAuto from "components/Heytech/SaveBozzaAuto";

const Riepilogo = props => {
  const history = useHistory();
  const sortOrder = (orderTemp) => {
    if (orderTemp.type == "Macchinario") {
      orderTemp.order.sort((a, b) => {
        a = a.macchinario.nome.toLowerCase();
        b = b.macchinario.nome.toLowerCase();

        return (a < b) ? -1 : (a > b) ? 1 : 0;
      });

      orderTemp.order.forEach((item, index) => {
        item.accessori.sort((a, b) => {
          (a, b) => {
            return parseFloat(a['sort_number']) - parseFloat(b['sort_number']);
          }
        })
        item.accessoriObbligatori.sort((a, b) => {
          a = a.nome.toLowerCase();
          b = b.nome.toLowerCase();

          return (a < b) ? -1 : (a > b) ? 1 : 0;
        })
        item.optional.sort((a, b) => {
          (a, b) => {
            return parseFloat(a['sort_number']) - parseFloat(b['sort_number']);
          }
        })
        item.materialiDiConsumo.sort(
          (a, b) => {
            return parseFloat(a['sort_number']) - parseFloat(b['sort_number']);
          }
        )
      })
    } else {
      orderTemp.order.sort(
        (a, b) => {
          return parseFloat(a['sort_number']) - parseFloat(b['sort_number']);
        }
      )
    }
    return orderTemp;
  }

  const [order, setOrder] = React.useState(sortOrder(localStorage.getItem("ht-order") ? sortOrder(JSON.parse(localStorage.getItem("ht-order"))) : undefined));

  const [modalFullscreen, setModalFullscreen] = React.useState(false);
  const [modalWarranty, setModalWarranty] = React.useState(false);
  const [modalElement, setModalElement] = React.useState({});
  const [modalSpecialDesc, setModalSpecialDesc] = React.useState("");
  const [modalSpecialPrice, setModalSpecialPrice] = React.useState("");
  const [modalQty, setModalQty] = React.useState(1);
  const [modalPercentuale, setModalPercentuale] = React.useState("");
  const [modalValUnitario, setModalValUnitario] = React.useState("");
  const [modalOmaggio, setModalOmaggio] = React.useState(false);
  const [modalPromo, setModalPromo] = React.useState(false);
  const [modalElementId, setModalElementId] = React.useState(false);
  const [modalOrderId, setModalOrderId] = React.useState(false);
  const [modalSortNumber, setModalSortNumber] = React.useState(false);
  const [prezzoUnitarioFinale, setPrezzoUnitarioFinale] = React.useState(0);
  const [prezzoTotaleFinale, setPrezzoTotaleFinale] = React.useState(0);

  const [showOnlyTotalPDF, setShowOnlyTotalPDF] = React.useState(localStorage.getItem("ht-order") ? JSON.parse(localStorage.getItem("ht-order")).no_print_price : false);
  const [hideTotalPDF, setHideTotalPDF] = React.useState(localStorage.getItem("ht-order") ? JSON.parse(localStorage.getItem("ht-order")).no_print_total_price : false);
  let hideDiscountRowPDFDefault = [];
  order.order.map((item) => {
    if (item.no_print_discount) {
      hideDiscountRowPDFDefault.push(item.id);
    }
  })
  const [hideDiscountRowPDF, setHideDiscountRowPDF] = React.useState(hideDiscountRowPDFDefault);

  const [tags, setTags] = React.useState(localStorage.getItem("tags") ? JSON.parse(localStorage.getItem("tags")) : []);
  const [modalFirma, setModalFirma] = React.useState(false);
  const [firmaEmpty, setFirmaEmpty] = React.useState(true);
  const [firmaSalta, setFirmaSalta] = React.useState(false);
  const [prezzoTotaleOrdineNumber, setPrezzoTotaleOrdineNumber] = React.useState(false);
  const sigCanvas = useRef({});
  useEffect(() => {
    var element = document.querySelectorAll(".responsiveTable");
    element.forEach(elem => {
      elem.classList.remove("responsiveTable");
    })
  }, [])

  var signature_realdim = localStorage.getItem('ht-signature-realdim');
  const tog_firma = () => {
    setModalFirma(!modalFirma);
    if (modalFirma) {
      setTimeout(() => {
        if (signature_realdim) {
          sigCanvas.current.fromDataURL(signature_realdim);
        }
      }, 500);
    }
    removeBodyCss();
  }

  const handleCancelFirma = () => {
    localStorage.removeItem('ht-signature');
    localStorage.removeItem('ht-signature-realdim');
    sigCanvas.current.clear();
    setFirmaEmpty(true);
  };
  const handleSaltaFirma = () => {
    setFirmaSalta(true);
    setModalFirma(false);
  };
  const handleSaveFirma = () => {
    if (!sigCanvas.current.isEmpty()) {
      localStorage.setItem('ht-signature', sigCanvas.current.getTrimmedCanvas().toDataURL("image/svg+xml"));
      localStorage.setItem('ht-signature-realdim', sigCanvas.current.toDataURL("image/svg+xml"));
      setModalFirma(false);
      setFirmaEmpty(false);
    }
  };

  useEffect(() => {
    localStorage.removeItem("ht-orderTemp");
    prezzoTotaleOrdine();
  }, []);

  function compareStrings(a, b) {
    // Assuming you want case-insensitive comparison
    a = a.toLowerCase();
    b = b.toLowerCase();

    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }

  const prezzoCad = (prezzo, qty) => {
    if (qty > 0) {
      let arrayPrice = prezzo.filter(item => item.qta < qty);
      if (arrayPrice.length > 0) {
        let priceFinal = 0;
        let rightQty = 0;
        arrayPrice.forEach(item => {
          if (item.qta >= rightQty) {
            rightQty = item.qta;
            priceFinal = item.prezzo;
          }
        })
        return priceFinal;
      }
    } else {
      return 0;
    }
  }

  const prezzoTot = (prezzo, qty) => {
    if (qty > 0) {
      let arrayPrice = prezzo.filter(item => item.qta < qty);
      if (arrayPrice.length > 0) {
        let priceFinal = 0;
        let rightQty = 0;
        arrayPrice.forEach(item => {
          if (item.qta >= rightQty) {
            rightQty = item.qta;
            priceFinal = item.prezzo;
          }
        })
        return priceFinal * qty;
      }
    } else {
      return 0;
    }
  }

  const prezzoTotMacchinario = (order) => {
    let prezzoTotale = 0;
    let arrayPrice = order.macchinario.prezzo.filter(item => item.qta < 1);
    if (arrayPrice.length > 0) {
      let priceFinal = 0;
      let rightQty = 0;
      arrayPrice.forEach(item => {
        if (item.qta >= rightQty) {
          rightQty = item.qta;
          priceFinal = item.prezzo;
        }
      })
      prezzoTotale = prezzoTotale + Number(priceFinal);
    }

    order.accessori.forEach(accessorio => {
      if (accessorio.quantity > 0) {
        let arrayPrice = accessorio.prezzo.filter(item => item.qta < accessorio.quantity);
        if (arrayPrice.length > 0) {
          let priceFinal = 0;
          let rightQty = 0;
          arrayPrice.forEach(item => {
            if (item.qta >= rightQty) {
              rightQty = item.qta;
              priceFinal = item.prezzo;
            }
          })
          prezzoTotale = prezzoTotale + (Number(priceFinal) * accessorio.quantity);
        }
      }
    })

    order.accessoriObbligatori.forEach(accessorio => {
      if (accessorio.quantity > 0) {
        let arrayPrice = accessorio.prezzo.filter(item => item.qta < accessorio.quantity);
        if (arrayPrice.length > 0) {
          let priceFinal = 0;
          let rightQty = 0;
          arrayPrice.forEach(item => {
            if (item.qta >= rightQty) {
              rightQty = item.qta;
              priceFinal = item.prezzo;
            }
          })
          prezzoTotale = prezzoTotale + (Number(priceFinal) * accessorio.quantity);
        }
      }
    })

    order.optional.forEach(optional => {
      if (optional.quantity > 0) {
        let arrayPrice = optional.prezzo.filter(item => item.qta < optional.quantity);
        if (arrayPrice.length > 0) {
          let priceFinal = 0;
          let rightQty = 0;
          arrayPrice.forEach(item => {
            if (item.qta >= rightQty) {
              rightQty = item.qta;
              priceFinal = item.prezzo;
            }
          })
          prezzoTotale = prezzoTotale + (Number(priceFinal) * optional.quantity);
        }
      }
    })

    let prezzoTotaleNoSconto = prezzoTotale;

    if (order.sconto && order.sconto > 0) {
      prezzoTotale = prezzoTotale - (prezzoTotale * (order.sconto / 100));
    }

    if (order.netPrice && order.netPrice > 0) {
      prezzoTotale = order.netPrice;
    }

    let orderTemp = JSON.parse(localStorage.getItem("ht-order"));
    let orderSingle = orderTemp.order.filter((item) => item.id == order.id)[0];
    orderSingle.priceMacchinario = prezzoTotale;
    orderSingle.priceMacchinarioNoSconto = prezzoTotaleNoSconto;
    let orderTempOrder = orderTemp.order.filter((item) => item.id !== order.id);
    orderTempOrder.push(orderSingle);
    orderTemp.order = orderTempOrder;
    localStorage.setItem("ht-order", JSON.stringify(orderTemp));

    return prezzoTotale;
  }

  const prezzoTotMacchinarioNoSconto = (order) => {
    let prezzoTotale = 0;
    let arrayPrice = order.macchinario.prezzo.filter(item => item.qta < 1);
    if (arrayPrice.length > 0) {
      let priceFinal = 0;
      let rightQty = 0;
      arrayPrice.forEach(item => {
        if (item.qta >= rightQty) {
          rightQty = item.qta;
          priceFinal = item.prezzo;
        }
      })
      prezzoTotale = prezzoTotale + Number(priceFinal);
    }

    order.accessori.forEach(accessorio => {
      if (accessorio.quantity > 0) {
        let arrayPrice = accessorio.prezzo.filter(item => item.qta < accessorio.quantity);
        if (arrayPrice.length > 0) {
          let priceFinal = 0;
          let rightQty = 0;
          arrayPrice.forEach(item => {
            if (item.qta >= rightQty) {
              rightQty = item.qta;
              priceFinal = item.prezzo;
            }
          })
          prezzoTotale = prezzoTotale + (Number(priceFinal) * accessorio.quantity);
        }
      }
    })

    order.accessoriObbligatori.forEach(accessorio => {
      if (accessorio.quantity > 0) {
        let arrayPrice = accessorio.prezzo.filter(item => item.qta < accessorio.quantity);
        if (arrayPrice.length > 0) {
          let priceFinal = 0;
          let rightQty = 0;
          arrayPrice.forEach(item => {
            if (item.qta >= rightQty) {
              rightQty = item.qta;
              priceFinal = item.prezzo;
            }
          })
          prezzoTotale = prezzoTotale + (Number(priceFinal) * accessorio.quantity);
        }
      }
    })

    order.optional.forEach(optional => {
      if (optional.quantity > 0) {
        let arrayPrice = optional.prezzo.filter(item => item.qta < optional.quantity);
        if (arrayPrice.length > 0) {
          let priceFinal = 0;
          let rightQty = 0;
          arrayPrice.forEach(item => {
            if (item.qta >= rightQty) {
              rightQty = item.qta;
              priceFinal = item.prezzo;
            }
          })
          prezzoTotale = prezzoTotale + (Number(priceFinal) * optional.quantity);
        }
      }
    })

    return prezzoTotale;
  }

  const calcoloScontoEffettivo = (order) => {
    let totaleNoSconto = prezzoTotMacchinarioNoSconto(order);
    let totaleSconto = prezzoTotMacchinario(order);
    let result = {
      percentuale: 100 - (totaleSconto * 100 / totaleNoSconto),
      diff: totaleNoSconto - totaleSconto
    }
    return result;
  }

  const addDiscount = (orderRow, sconto) => {
    sconto = sconto > 0 ? (sconto <= 100 ? sconto : 100) : (sconto === "" ? sconto : 0);
    orderRow.sconto = sconto !== "" ? Number(sconto) : null;
    let orderTemp = order.order.filter(item => item.id !== orderRow.id);
    let ord = JSON.parse(JSON.stringify(order));
    orderTemp.push(orderRow);
    ord.order = orderTemp;
    setOrder(sortOrder(ord));
    localStorage.setItem("ht-order", JSON.stringify(ord));
    prezzoTotaleOrdine();
  }

  const addNetPrice = (orderRow, netPrice) => {
    netPrice = netPrice > 0 ? netPrice : (netPrice === "" ? netPrice : 0);
    orderRow.netPrice = netPrice !== "" ? Number(netPrice) : null;
    let orderTemp = order.order.filter(item => item.id !== orderRow.id);
    let ord = JSON.parse(JSON.stringify(order));
    orderTemp.push(orderRow);
    ord.order = orderTemp;
    setOrder(sortOrder(ord));
    localStorage.setItem("ht-order", JSON.stringify(ord));
    prezzoTotaleOrdine();
  }

  const prezzoTotMaterialeDiConsumo = (order) => {
    let prezzoTotale = 0;

    order.materialiDiConsumo.forEach(materiale => {
      if (materiale.quantity > 0) {
        prezzoTotale = prezzoTotale + (Number(materiale.prezzo_totale));
      }
    })

    let orderTemp = JSON.parse(localStorage.getItem("ht-order"));
    let orderSingle = orderTemp.order.filter((item) => item.id == order.id)[0];
    orderSingle.priceMaterialeDiConsumo = prezzoTotale;
    let orderTempOrder = orderTemp.order.filter((item) => item.id !== order.id);
    orderTempOrder.push(orderSingle);
    orderTemp.order = orderTempOrder;
    localStorage.setItem("ht-order", JSON.stringify(orderTemp));

    return prezzoTotale;
  }

  const tog_fullscreen = (data, prod, id, orderId, sort_number = 0) => {
    setModalElementId(id);
    setModalOrderId(orderId);
    setModalElement(prod);
    setModalSpecialDesc(prod.nome_speciale);
    setModalSpecialPrice(prod.componente_prezzo[0].prezzo)
    setModalQty(data.qty);
    setModalPercentuale(data.percentuale);
    setModalValUnitario(data.valUnitario);
    setModalOmaggio(data.omaggio);
    setModalPromo(data.promo);
    setModalSortNumber(sort_number);

    setModalFullscreen(!modalFullscreen);
    removeBodyCss();
    handleCalculatorPrice(data, prod);
  }

  const tog_warranty = () => {
    setModalWarranty(!modalWarranty);
    removeBodyCss();
  }

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  }

  const handleCalculatorPrice = (data, prod = false) => {
    //data = {"qty": modalQty, "percentuale": modalPercentuale, "valUnitario": modalValUnitario, "omaggio": modalOmaggio}
    let prodToUse = modalElement
    if (prod) {
      prodToUse = prod;
    }
    let totalPrice = 0;
    let unitaryPrice = 0;
    let qty_data = data.qty;
    if (qty_data < 1) {
      qty_data = 1;
    }
    let arrayPrice = prodToUse.componente_prezzo.filter(item => item.qta <= qty_data);
    if (arrayPrice.length > 0) {
      let priceFinal = 0;
      let rightQty = 0;
      arrayPrice.forEach(item => {
        if (item.qta >= rightQty) {
          rightQty = item.qta;
          priceFinal = item.prezzo;
        }
      })
      unitaryPrice = priceFinal;
    }
    if (qty_data > 0 && !data.omaggio && !data.promo && (data.valUnitario !== "" && data.valUnitario > 0)) {
      unitaryPrice = data.valUnitario;
    }

    /* if(data.qty>0 && !data.omaggio && (data.percentuale!=="" && data.percentuale>0)){
      unitaryPrice = (unitaryPrice)*((100-data.percentuale)/100);
    } */

    if (data.omaggio || data.promo) {
      unitaryPrice = 0;
    }

    if (data.qty > 0 && !data.omaggio && !data.promo && (data.percentuale !== "" && data.percentuale > 0)) {
      totalPrice = (unitaryPrice * data.qty) * ((100 - data.percentuale) / 100);
    } else {
      totalPrice = (unitaryPrice * data.qty);
    }

    setPrezzoUnitarioFinale(unitaryPrice);
    setPrezzoTotaleFinale(totalPrice);
  }

  const editMaterialeDiConsumo = () => {
    if ((!modalElement.componente_speciale && modalQty > 0) || (modalElement.componente_speciale && modalSpecialDesc !== "")) {
      let price = 0;
      let arrayPrice = modalElement.componente_prezzo.filter(item => item.qta < modalQty);
      if (arrayPrice.length > 0) {
        let priceFinal = 0;
        let rightQty = 0;
        arrayPrice.forEach(item => {
          if (item.qta >= rightQty) {
            rightQty = item.qta;
            priceFinal = item.prezzo;
          }
        })
        price = priceFinal;
      }
      let materialeDiConsumo = {
        id: modalElementId,
        code: modalElement.componente_codice,
        nome: modalElement.componente_nome,
        nome_speciale: modalElement.componente_speciale ? modalSpecialDesc : undefined,
        is_special: modalElement.componente_speciale,
        prezzo: Number(price),
        prezzo_totale: Number(prezzoTotaleFinale),
        prezzo_unitario: Number(prezzoUnitarioFinale),
        quantity: Number(modalQty),
        sconto: Number(modalPercentuale),
        scontoUnitario: Number(modalValUnitario),
        sconto_type: modalOmaggio ? 0 : (modalPromo ? 2 : ((modalPercentuale > 0 || modalValUnitario > 0) ? 1 : -1)), //-1: No sconto, 0: Omaggio, 1: Precentuale o valore unitario
        unita_di_misura: modalElement.componente_unita_di_misura,
        prod: modalElement,
        sort_number: modalSortNumber
      }

      let orderTotal = JSON.parse(localStorage.getItem("ht-order"));
      if (orderTotal.type == "Macchinario") {
        let orderTemp = orderTotal.order.filter(item => item.id === modalOrderId);
        orderTemp[0].materialiDiConsumo = orderTemp[0].materialiDiConsumo.filter(item => item.id !== modalElementId);
        orderTemp[0].materialiDiConsumo.push(materialeDiConsumo);
        orderTemp[0].materialiDiConsumo.sort(
          (a, b) => {
            return parseFloat(a['sort_number']) - parseFloat(b['sort_number']);
          }
        )
        let orderTotalTemp = orderTotal.order.filter(item => item.id !== modalOrderId);
        orderTotalTemp.push(orderTemp[0]);
        orderTotal.order = orderTotalTemp;
      } else {
        orderTotal.order = orderTotal.order.filter(item => item.id !== modalOrderId);
        orderTotal.order.push(materialeDiConsumo);
        orderTotal.order.sort(
          (a, b) => {
            return parseFloat(a['sort_number']) - parseFloat(b['sort_number']);
          }
        )
      }
      orderTotal = sortOrder(orderTotal);
      localStorage.setItem("ht-order", JSON.stringify(orderTotal));

      toastr.options = {
        positionClass: "toast-bottom-full-width",
        closeButton: true,
        preventDuplicates: true,
      };
      toastr.success(modalElement.componente_nome + " è stato modificato con successo", "Prodotto modificato con successo");
      setModalElementId(null);
      setModalQty(1);
      setModalPercentuale("");
      setModalValUnitario("");
      setModalOmaggio(false);
      setModalPromo(false);
      setModalSortNumber(false);
      setModalFullscreen(false);
      setOrder(orderTotal);
      prezzoTotaleOrdine();
    }
  }

  const prezzoTotaleOrdine = () => {
    let orderTotal = JSON.parse(localStorage.getItem("ht-order"));
    let prezzoTotale = 0;
    let orders = orderTotal.order;
    // Calcolo macchianari
    if (orderTotal.type == "Macchinario") {
      let prezzoMacchinario = 0
      orders.map(order => {
        prezzoTotale = prezzoTotale + prezzoTotMacchinario(order);

        // Calcolo materiali di consumo
        order.materialiDiConsumo.forEach(materiale => {
          if (!materiale.is_special && materiale.quantity > 0) {
            prezzoTotale = prezzoTotale + (Number(materiale.prezzo_totale));
          }
        })
      });
    } else {
      // Calcolo materiali di consumo
      orders.forEach(materiale => {
        if (!materiale.is_special && materiale.quantity > 0) {
          prezzoTotale = prezzoTotale + (Number(materiale.prezzo_totale));
        }
      })
    }

    let orderTemp = JSON.parse(localStorage.getItem("ht-order"));
    orderTemp.total = prezzoTotale;
    localStorage.setItem("ht-order", JSON.stringify(orderTemp));

    setPrezzoTotaleOrdineNumber(prezzoTotale);
  }

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    let orderTotal = JSON.parse(localStorage.getItem("ht-order"));
    // Calcolo macchianari
    var updatedList = [];
    if (droppedItem.source.droppableId.startsWith("list-MDCM-optional-")) {
      let idMacchinario = droppedItem.source.droppableId;
      idMacchinario = idMacchinario.replace("list-MDCM-optional-", "");
      let orders = orderTotal.order;
      let macchinarioOrder = orders.filter((item) => item.id == idMacchinario)[0];
      updatedList = [...macchinarioOrder.optional];
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      let sortN = 0;
      updatedList.map((item) => {
        sortN = sortN + 1;
        item.sort_number = sortN;
        return item;
      })
      macchinarioOrder.optional = updatedList;
      let index = orders.findIndex(function (item, i) {
        return item.id === idMacchinario
      });
      orders[index] = macchinarioOrder;
      orderTotal.order = orders
      localStorage.setItem("ht-order", JSON.stringify(orderTotal));
      setOrder(orderTotal);
    } else if (droppedItem.source.droppableId.startsWith("list-MDCM-accessori-")) {
      let idMacchinario = droppedItem.source.droppableId;
      idMacchinario = idMacchinario.replace("list-MDCM-accessori-", "");
      let orders = orderTotal.order;
      let macchinarioOrder = orders.filter((item) => item.id == idMacchinario)[0];
      updatedList = [...macchinarioOrder.accessori];
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      let sortN = 0;
      updatedList.map((item) => {
        sortN = sortN + 1;
        item.sort_number = sortN;
        return item;
      })
      macchinarioOrder.accessori = updatedList;
      let index = orders.findIndex(function (item, i) {
        return item.id === idMacchinario
      });
      orders[index] = macchinarioOrder;
      orderTotal.order = orders
      localStorage.setItem("ht-order", JSON.stringify(orderTotal));
      setOrder(orderTotal);
    } else if (droppedItem.source.droppableId !== "list-MDC") {
      let idMacchinario = droppedItem.source.droppableId;
      idMacchinario = idMacchinario.replace("list-MDCM-", "");
      let orders = orderTotal.order;
      let macchinarioOrder = orders.filter((item) => item.id == idMacchinario)[0];
      updatedList = [...macchinarioOrder.materialiDiConsumo];
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      let sortN = 0;
      updatedList.map((item) => {
        sortN = sortN + 1;
        item.sort_number = sortN;
        return item;
      })
      macchinarioOrder.materialiDiConsumo = updatedList;
      let index = orders.findIndex(function (item, i) {
        return item.id === idMacchinario
      });
      orders[index] = macchinarioOrder;
      orderTotal.order = orders
      localStorage.setItem("ht-order", JSON.stringify(orderTotal));
      setOrder(orderTotal);
    } else {
      updatedList = [...order.order];
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      let sortN = 0;
      updatedList.map((item) => {
        sortN = sortN + 1;
        item.sort_number = sortN;
        return item;
      })
      let tempOrder = order;
      tempOrder.order = updatedList;
      localStorage.setItem("ht-order", JSON.stringify(tempOrder));
      setOrder(tempOrder);
    }
  };

  const getScontoType = (item) => {
    if (item.sconto_type == -1) {
      return "";
    } else if (item.sconto_type == 0) {
      return " - Omaggio";
    } else if (item.sconto_type == 2) {
      return " - Promo";
    } else if (item.sconto_type == 1) {
      if (item.sconto > 0) {
        return " - Sconto: " + item.sconto + "%";
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{"Riepilogo " + (JSON.parse(localStorage.getItem("ht-order")).proposta === 0 ? "offerta" : "ordine")} | Gruppo Bisaro Cloud</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Attività" parentsBreadcrumb={[{ "title": (order.proposta === 0 ? "Crea Offerta" : "Crea Ordine"), "link": (order.proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine") }]} breadcrumbItem={"Riepilogo " + (JSON.parse(localStorage.getItem("ht-order")).proposta === 0 ? "offerta" : "ordine")} />
          <Row>
            <Col md="12">
              <Card>
                <div className="px-4 py-3 border-bottom">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item me-3" id="dueDate">
                      <h5><img className="short_list_icon_type" src={order.type === "Macchinario" ? macchinarioLogo : materialediconsumoLogo} alt={(order.proposta === 0 ? "Offerta" : "Proposta d'ordine") + " " + order.type} />{" "}{order.proposta === 0 ? "Offerta" : "Proposta d'ordine"}{" "}{order.type}</h5>
                    </li>
                  </ul>
                </div>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1 overflow-hidden">
                      <h5 className="text-truncate font-size-15">
                        Cliente
                      </h5>
                      <p className="mb-0 font-size-12">
                        {order.cliente && typeof order.cliente.anagrafica_descrizione !== "undefined" && order.cliente.anagrafica_descrizione}
                      </p>
                      <p className="text-muted mb-0 font-size-10">{order.cliente && typeof order.cliente.anagrafica_codice !== "undefined" && ("Codice: " + order.cliente.anagrafica_codice)}</p>
                      <p className="text-muted mb-0 font-size-10">{order.cliente && typeof order.cliente.anagrafica_indirizzo !== "undefined" && order.cliente.anagrafica_indirizzo.length > 0 && (order.cliente.anagrafica_indirizzo[0].Indirizzo + " - " + order.cliente.anagrafica_indirizzo[0].Cap + ", " + order.cliente.anagrafica_indirizzo[0].Localita + " (" + order.cliente.anagrafica_indirizzo[0].Provincia + ")")}</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {typeof order.order !== "undefined" && order.type == "Macchinario" ?
              order.order.map(orderTemp =>
                <Col md="12" key={orderTemp.id}>
                  <Card>
                    <CardBody>
                      <><h5>Macchinario</h5>
                        <div className="table_wrapper_mobile"><Table className="table table-striped table-bordered">
                          <Thead>
                            <Tr>
                              <Th>Codice</Th>
                              <Th>Descrizione</Th>
                              <Th style={{ textAlign: "right" }}>Prezzo</Th>
                              <Th style={{ whiteSpace: "nowrap", textAlign: "center" }}>Azioni</Th>
                            </Tr>
                          </Thead>
                          <tbody>
                            <tr>
                              <Td style={{ whiteSpace: "nowrap" }}>{orderTemp.macchinario.code}</Td>
                              <Td>{orderTemp.macchinario.nome}</Td>
                              <Td style={{ textAlign: "right" }}>{Number(prezzoTot(orderTemp.macchinario.prezzo, 1)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                              <Td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                <div className="btn btn-sm btn-light"
                                  onClick={() => {
                                    localStorage.setItem("ht-orderTemp", JSON.stringify(orderTemp));
                                    history.push((order.proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine") + "/macchinario/scelta-accessori");
                                  }}
                                ><i className="bx bx-edit-alt"></i></div>
                                <div className="d-inline px-2" />
                                <div className="btn btn-sm btn-danger"
                                  onClick={() => {
                                    Swal.fire({
                                      title: 'Eliminazione macchinario',
                                      text: 'Sicuro di voler eliminare ' + orderTemp.macchinario.nome + ' (' + orderTemp.macchinario.code + ')' + '? Verranno eliminati anche tutti gli accessori, optional e materiale di consumo ad esso associato.',
                                      type: 'warning',
                                      showCancelButton: true,
                                      cancelButtonText: 'Annulla',
                                      confirmButtonText: 'Sì, elimina tutto!',
                                      confirmButtonColor: '#f46a6a',
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        let orderTotal = JSON.parse(JSON.stringify(order));
                                        orderTotal.order = orderTotal.order.filter(item => item.id !== orderTemp.id);
                                        orderTotal = sortOrder(orderTotal);
                                        setOrder(orderTotal);
                                        localStorage.setItem("ht-order", JSON.stringify(orderTotal));
                                        prezzoTotaleOrdine();
                                        if (orderTotal.order.length === 0) {
                                          history.push((order.proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine") + "/macchinario/scelta-macchina");
                                        }
                                      }
                                    })
                                  }}
                                ><i className="bx bx-trash-alt"></i></div>
                              </Td>
                            </tr>
                          </tbody>
                        </Table></div></>
                      {(orderTemp.accessoriObbligatori.length > 0 || orderTemp.accessori.length > 0) && <><h5>Accessori</h5>
                        <div className="table_wrapper_mobile"><Table className="table table-striped table-bordered">
                          <Thead>
                            <Tr>
                              <Th>Codice</Th>
                              <Th>Descrizione</Th>
                              <Th style={{ textAlign: "right" }}>Quantità</Th>
                              <Th style={{ whiteSpace: "nowrap", textAlign: "right" }}>Prezzo cad.</Th>
                              <Th style={{ whiteSpace: "nowrap", textAlign: "right" }}>Prezzo tot.</Th>
                              <Th style={{ whiteSpace: "nowrap", textAlign: "center" }}>Azioni</Th>
                            </Tr>
                          </Thead>
                          <DragDropContext onDragEnd={handleDrop}>
                            <Droppable droppableId={"list-MDCM-accessori-" + orderTemp.id}>
                              {(provided) => (
                                <tbody
                                  className={"list-MDCM-accessori-" + orderTemp.id}
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {orderTemp.accessoriObbligatori.map((item, index) => <Tr key={item.code}>
                                    <Td style={{ whiteSpace: "nowrap" }}>{item.code}</Td>
                                    <Th>{item.nome}</Th>
                                    <Td style={{ textAlign: "right" }}>{item.quantity}</Td>
                                    <Td style={{ textAlign: "right" }}>{Number(prezzoCad(item.prezzo, item.quantity)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                                    <Td style={{ textAlign: "right" }}>{Number(prezzoTot(item.prezzo, item.quantity)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                                    <Td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                      <div className="btn btn-sm btn-light"><i className="bx bx-edit-alt"
                                        onClick={() => {
                                          Swal.fire({
                                            title: item.nome + ' (' + item.code + ')',
                                            input: 'number',
                                            inputAttributes: {
                                              autocapitalize: 'off'
                                            },
                                            showCancelButton: true,
                                            confirmButtonText: 'Salva',
                                            confirmButtonColor: '#003A5D',
                                            showLoaderOnConfirm: true,
                                            inputValue: item.quantity,
                                            inputLabel: 'Modifica la quantità',
                                            inputValidator: (value) => {
                                              if (!value || value < 1 || value === "") {
                                                return 'Devi inserire una quantità maggiore di 0'
                                              }
                                            }
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                              let newQuantity = result.value;
                                              let itemToModify = orderTemp.accessoriObbligatori.filter(itemToModify => itemToModify.code === item.code)[0];
                                              itemToModify.quantity = newQuantity;
                                              orderTemp.accessoriObbligatori = orderTemp.accessoriObbligatori.filter(itemToModify => itemToModify.code !== item.code);
                                              orderTemp.accessoriObbligatori.push(itemToModify);
                                              let orderTotal = JSON.parse(JSON.stringify(order));
                                              orderTotal.order = orderTotal.order.filter(orderItem => orderItem.id !== orderTemp.id);
                                              orderTotal.order.push(orderTemp);
                                              orderTotal = sortOrder(orderTotal);
                                              setOrder(orderTotal);
                                              localStorage.setItem("ht-order", JSON.stringify(orderTotal));
                                              prezzoTotaleOrdine();
                                            }
                                          })
                                        }}
                                      ></i></div>
                                    </Td>
                                  </Tr>)}
                                  {orderTemp.accessori.map((item, index) =>
                                    <Draggable key={item.code} draggableId={item.code} index={index}>
                                      {(provided) => (
                                        <tr key={item.code}
                                          ref={provided.innerRef}
                                          {...provided.dragHandleProps}
                                          {...provided.draggableProps}
                                        >
                                          {item.is_special ?
                                            <><Td style={{ whiteSpace: "nowrap" }}>{item.code}</Td>
                                              <Td colSpan={4}>{typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome} {item.is_special && <i className="bx bx-edit-alt" style={{ cursor: "pointer" }} onClick={() => {
                                                Swal.fire({
                                                  title: (typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome) + ' (' + item.code + ')',
                                                  input: 'text',
                                                  inputAttributes: {
                                                    autocapitalize: 'off'
                                                  },
                                                  showCancelButton: true,
                                                  cancelButtonText: 'Annulla',
                                                  confirmButtonText: 'Salva',
                                                  confirmButtonColor: '#003A5D',
                                                  showLoaderOnConfirm: true,
                                                  inputValue: item.nome_speciale,
                                                  inputLabel: 'Modifica il nome',
                                                  didOpen: function () {
                                                    document.querySelector("input#swal2-input").style.fontSize = "14px";
                                                  },
                                                  inputValidator: (value) => {
                                                    if (!value || value === "") {
                                                      return 'Devi inserire un nome non vuoto'
                                                    }
                                                  }
                                                }).then((result) => {
                                                  if (result.isConfirmed) {
                                                    let newName = result.value;
                                                    let itemToModify = orderTemp.accessori.filter(itemToModify => itemToModify.code === item.code)[0];
                                                    itemToModify.nome_speciale = newName;
                                                    orderTemp.accessori = orderTemp.accessori.filter(itemToModify => itemToModify.code !== item.code);
                                                    orderTemp.accessori.push(itemToModify);
                                                    orderTemp.accessori.sort(
                                                      (a, b) => {
                                                        return parseFloat(a['sort_number']) - parseFloat(b['sort_number']);
                                                      }
                                                    )
                                                    let orderTotal = JSON.parse(JSON.stringify(order));
                                                    orderTotal.order = orderTotal.order.filter(orderItem => orderItem.id !== orderTemp.id);
                                                    orderTotal.order.push(orderTemp);
                                                    orderTotal = sortOrder(orderTotal);
                                                    setOrder(orderTotal);
                                                    localStorage.setItem("ht-order", JSON.stringify(orderTotal));
                                                    prezzoTotaleOrdine();
                                                  }
                                                })
                                              }}></i>}{item.is_special && <><br /><Badge pill className="badge-primary ms-1">
                                                Elemento nota
                                              </Badge></>}</Td>
                                              <Td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                <div className="btn btn-sm btn-danger"
                                                  onClick={() => {
                                                    Swal.fire({
                                                      title: 'Eliminazione accessorio',
                                                      text: 'Sicuro di voler eliminare ' + (typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome) + ' (' + item.code + ')' + '?',
                                                      type: 'warning',
                                                      showCancelButton: true,
                                                      cancelButtonText: 'Annulla',
                                                      confirmButtonText: 'Sì, elimina!',
                                                      confirmButtonColor: '#f46a6a',
                                                    }).then((result) => {
                                                      if (result.isConfirmed) {
                                                        orderTemp.accessori = orderTemp.accessori.filter(itemToModify => itemToModify.code !== item.code);
                                                        let orderTotal = JSON.parse(JSON.stringify(order));
                                                        orderTotal.order = orderTotal.order.filter(orderItem => orderItem.id !== orderTemp.id);
                                                        orderTotal.order.push(orderTemp);
                                                        orderTotal = sortOrder(orderTotal);
                                                        setOrder(orderTotal);
                                                        localStorage.setItem("ht-order", JSON.stringify(orderTotal));
                                                        prezzoTotaleOrdine();
                                                      }
                                                    })
                                                  }}
                                                ><i className="bx bx-trash-alt"></i></div>
                                              </Td></> :
                                            <><Td style={{ whiteSpace: "nowrap" }}>{item.code}</Td>
                                              <Td>{typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome} {item.is_special && <i className="bx bx-edit-alt" style={{ cursor: "pointer" }} onClick={() => {
                                                Swal.fire({
                                                  title: (typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome) + ' (' + item.code + ')',
                                                  input: 'text',
                                                  inputAttributes: {
                                                    autocapitalize: 'off'
                                                  },
                                                  showCancelButton: true,
                                                  cancelButtonText: 'Annulla',
                                                  confirmButtonText: 'Salva',
                                                  confirmButtonColor: '#003A5D',
                                                  showLoaderOnConfirm: true,
                                                  inputValue: item.nome_speciale,
                                                  inputLabel: 'Modifica il nome',
                                                  didOpen: function () {
                                                    document.querySelector("input#swal2-input").style.fontSize = "14px";
                                                  },
                                                  inputValidator: (value) => {
                                                    if (!value || value === "") {
                                                      return 'Devi inserire un nome non vuoto'
                                                    }
                                                  }
                                                }).then((result) => {
                                                  if (result.isConfirmed) {
                                                    let newName = result.value;
                                                    let itemToModify = orderTemp.accessori.filter(itemToModify => itemToModify.code === item.code)[0];
                                                    itemToModify.nome_speciale = newName;
                                                    orderTemp.accessori = orderTemp.accessori.filter(itemToModify => itemToModify.code !== item.code);
                                                    orderTemp.accessori.push(itemToModify);
                                                    let orderTotal = JSON.parse(JSON.stringify(order));
                                                    orderTotal.order = orderTotal.order.filter(orderItem => orderItem.id !== orderTemp.id);
                                                    orderTotal.order.push(orderTemp);
                                                    orderTotal = sortOrder(orderTotal);
                                                    setOrder(orderTotal);
                                                    localStorage.setItem("ht-order", JSON.stringify(orderTotal));
                                                    prezzoTotaleOrdine();
                                                  }
                                                })
                                              }}></i>}{item.is_special && <><br /><Badge pill className="badge-primary ms-1">
                                                Elemento nota
                                              </Badge></>}</Td>
                                              <Td style={{ textAlign: "right" }}>{item.quantity}</Td>
                                              <Td style={{ textAlign: "right" }}>{item.is_special && <i className="bx bx-edit-alt" style={{ cursor: "pointer" }} onClick={() => {
                                                Swal.fire({
                                                  title: (typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome) + ' (' + item.code + ')',
                                                  input: 'number',
                                                  inputAttributes: {
                                                    autocapitalize: 'off'
                                                  },
                                                  showCancelButton: true,
                                                  cancelButtonText: 'Annulla',
                                                  confirmButtonText: 'Salva',
                                                  confirmButtonColor: '#003A5D',
                                                  showLoaderOnConfirm: true,
                                                  inputValue: item.prezzo[0].prezzo,
                                                  inputLabel: 'Modifica il prezzo',
                                                  inputValidator: (value) => {
                                                  }
                                                }).then((result) => {
                                                  if (result.isConfirmed) {
                                                    let newPrice = result.value;
                                                    if (!newPrice || newPrice < 1 || newPrice === "") {
                                                      newPrice = 0;
                                                    }
                                                    let itemToModify = orderTemp.accessori.filter(itemToModify => itemToModify.code === item.code)[0];
                                                    itemToModify.prezzo = [{
                                                      prezzo: newPrice,
                                                      qta: 0
                                                    }];
                                                    orderTemp.accessori = orderTemp.accessori.filter(itemToModify => itemToModify.code !== item.code);
                                                    orderTemp.accessori.push(itemToModify);
                                                    let orderTotal = JSON.parse(JSON.stringify(order));
                                                    orderTotal.order = orderTotal.order.filter(orderItem => orderItem.id !== orderTemp.id);
                                                    orderTotal.order.push(orderTemp);
                                                    orderTotal = sortOrder(orderTotal);
                                                    setOrder(orderTotal);
                                                    localStorage.setItem("ht-order", JSON.stringify(orderTotal));
                                                    prezzoTotaleOrdine();
                                                  }
                                                })
                                              }}></i>} {Number(prezzoCad(item.prezzo, item.quantity)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                                              <Td style={{ textAlign: "right" }}>{Number(prezzoTot(item.prezzo, item.quantity)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                                              <Td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                <div className="btn btn-sm btn-light"
                                                  onClick={() => {
                                                    Swal.fire({
                                                      title: (typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome) + ' (' + item.code + ')',
                                                      input: 'number',
                                                      inputAttributes: {
                                                        autocapitalize: 'off'
                                                      },
                                                      showCancelButton: true,
                                                      cancelButtonText: 'Annulla',
                                                      confirmButtonText: 'Salva',
                                                      confirmButtonColor: '#003A5D',
                                                      showLoaderOnConfirm: true,
                                                      inputValue: item.quantity,
                                                      inputLabel: 'Modifica la quantità',
                                                      inputValidator: (value) => {
                                                        if (!value || value < 1 || value === "") {
                                                          return 'Devi inserire una quantità maggiore di 0'
                                                        }
                                                      }
                                                    }).then((result) => {
                                                      if (result.isConfirmed) {
                                                        let newQuantity = result.value;
                                                        let itemToModify = orderTemp.accessori.filter(itemToModify => itemToModify.code === item.code)[0];
                                                        itemToModify.quantity = newQuantity;
                                                        orderTemp.accessori = orderTemp.accessori.filter(itemToModify => itemToModify.code !== item.code);
                                                        orderTemp.accessori.push(itemToModify);
                                                        let orderTotal = JSON.parse(JSON.stringify(order));
                                                        orderTotal.order = orderTotal.order.filter(orderItem => orderItem.id !== orderTemp.id);
                                                        orderTotal.order.push(orderTemp);
                                                        orderTotal = sortOrder(orderTotal);
                                                        setOrder(orderTotal);
                                                        localStorage.setItem("ht-order", JSON.stringify(orderTotal));
                                                        prezzoTotaleOrdine();
                                                      }
                                                    })
                                                  }}
                                                ><i className="bx bx-edit-alt"></i></div>
                                                <div className="d-inline px-2" />
                                                <div className="btn btn-sm btn-danger"
                                                  onClick={() => {
                                                    Swal.fire({
                                                      title: 'Eliminazione accessorio',
                                                      text: 'Sicuro di voler eliminare ' + (typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome) + ' (' + item.code + ')' + '?',
                                                      type: 'warning',
                                                      showCancelButton: true,
                                                      cancelButtonText: 'Annulla',
                                                      confirmButtonText: 'Sì, elimina!',
                                                      confirmButtonColor: '#f46a6a',
                                                    }).then((result) => {
                                                      if (result.isConfirmed) {
                                                        orderTemp.accessori = orderTemp.accessori.filter(itemToModify => itemToModify.code !== item.code);
                                                        let orderTotal = JSON.parse(JSON.stringify(order));
                                                        orderTotal.order = orderTotal.order.filter(orderItem => orderItem.id !== orderTemp.id);
                                                        orderTotal.order.push(orderTemp);
                                                        orderTotal = sortOrder(orderTotal);
                                                        setOrder(orderTotal);
                                                        localStorage.setItem("ht-order", JSON.stringify(orderTotal));
                                                        prezzoTotaleOrdine();
                                                      }
                                                    })
                                                  }}
                                                ><i className="bx bx-trash-alt"></i></div>
                                              </Td>
                                            </>}
                                        </tr>
                                      )}
                                    </Draggable>
                                  )}
                                  {provided.placeholder}
                                </tbody>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </Table></div></>}
                      {orderTemp.optional.length > 0 && <><h5>Optional</h5>
                        <div className="table_wrapper_mobile"><Table className="table table-striped table-bordered">
                          <Thead>
                            <Tr>
                              <Th>Codice</Th>
                              <Th>Descrizione</Th>
                              <Th style={{ textAlign: "right" }}>Quantità</Th>
                              <Th style={{ whiteSpace: "nowrap", textAlign: "right" }}>Prezzo cad.</Th>
                              <Th style={{ whiteSpace: "nowrap", textAlign: "right" }}>Prezzo tot.</Th>
                              <Th style={{ whiteSpace: "nowrap", textAlign: "center" }}>Azioni</Th>
                            </Tr>
                          </Thead>
                          <DragDropContext onDragEnd={handleDrop}>
                            <Droppable droppableId={"list-MDCM-optional-" + orderTemp.id}>
                              {(provided) => (
                                <tbody
                                  className={"list-MDCM-optional-" + orderTemp.id}
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {orderTemp.optional.map((item, index) =>
                                    <Draggable key={item.code} draggableId={item.code} index={index}>
                                      {(provided) => (
                                        <tr key={item.code}
                                          ref={provided.innerRef}
                                          {...provided.dragHandleProps}
                                          {...provided.draggableProps}
                                        >
                                          {item.is_special ?
                                            <>
                                              <Td style={{ whiteSpace: "nowrap" }}>{item.code}</Td>
                                              <Td colSpan="4">{typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome} {item.is_special && <i className="bx bx-edit-alt" style={{ cursor: "pointer" }} onClick={() => {
                                                Swal.fire({
                                                  title: (typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome) + ' (' + item.code + ')',
                                                  input: 'text',
                                                  inputAttributes: {
                                                    autocapitalize: 'off'
                                                  },
                                                  showCancelButton: true,
                                                  cancelButtonText: 'Annulla',
                                                  confirmButtonText: 'Salva',
                                                  confirmButtonColor: '#003A5D',
                                                  showLoaderOnConfirm: true,
                                                  inputValue: item.nome_speciale,
                                                  inputLabel: 'Modifica il nome',
                                                  didOpen: function () {
                                                    document.querySelector("input#swal2-input").style.fontSize = "14px";
                                                  },
                                                  inputValidator: (value) => {
                                                    if (!value || value === "") {
                                                      return 'Devi inserire un nome non vuoto'
                                                    }
                                                  }
                                                }).then((result) => {
                                                  if (result.isConfirmed) {
                                                    let newName = result.value;
                                                    let itemToModify = orderTemp.optional.filter(itemToModify => itemToModify.code === item.code)[0];
                                                    itemToModify.nome_speciale = newName;
                                                    orderTemp.optional = orderTemp.optional.filter(itemToModify => itemToModify.code !== item.code);
                                                    orderTemp.optional.push(itemToModify);
                                                    orderTemp.optional.sort(
                                                      (a, b) => {
                                                        return parseFloat(a['sort_number']) - parseFloat(b['sort_number']);
                                                      }
                                                    )
                                                    let orderTotal = JSON.parse(JSON.stringify(order));
                                                    orderTotal.order = orderTotal.order.filter(orderItem => orderItem.id !== orderTemp.id);
                                                    orderTotal.order.push(orderTemp);
                                                    orderTotal = sortOrder(orderTotal);
                                                    setOrder(orderTotal);
                                                    localStorage.setItem("ht-order", JSON.stringify(orderTotal));
                                                    prezzoTotaleOrdine();
                                                  }
                                                })
                                              }}></i>}{item.is_special && <><br /><Badge pill className="badge-primary ms-1">
                                                Elemento nota
                                              </Badge></>}</Td>
                                              <Td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                <div className="btn btn-sm btn-danger"
                                                  onClick={() => {
                                                    Swal.fire({
                                                      title: 'Eliminazione optional',
                                                      text: 'Sicuro di voler eliminare ' + (typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome) + ' (' + item.code + ')' + '?',
                                                      type: 'warning',
                                                      showCancelButton: true,
                                                      cancelButtonText: 'Annulla',
                                                      confirmButtonText: 'Sì, elimina!',
                                                      confirmButtonColor: '#f46a6a',
                                                    }).then((result) => {
                                                      if (result.isConfirmed) {
                                                        orderTemp.optional = orderTemp.optional.filter(itemToModify => itemToModify.code !== item.code);
                                                        let orderTotal = JSON.parse(JSON.stringify(order));
                                                        orderTotal.order = orderTotal.order.filter(orderItem => orderItem.id !== orderTemp.id);
                                                        orderTotal.order.push(orderTemp);
                                                        orderTotal = sortOrder(orderTotal);
                                                        setOrder(orderTotal);
                                                        localStorage.setItem("ht-order", JSON.stringify(orderTotal));
                                                        prezzoTotaleOrdine();
                                                      }
                                                    })
                                                  }}
                                                ><i className="bx bx-trash-alt"></i></div>
                                              </Td></> :
                                            <>
                                              <Td style={{ whiteSpace: "nowrap" }}>{item.code}</Td>
                                              <Td>{typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome} {item.is_special && <i className="bx bx-edit-alt" style={{ cursor: "pointer" }} onClick={() => {
                                                Swal.fire({
                                                  title: (typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome) + ' (' + item.code + ')',
                                                  input: 'text',
                                                  inputAttributes: {
                                                    autocapitalize: 'off'
                                                  },
                                                  showCancelButton: true,
                                                  cancelButtonText: 'Annulla',
                                                  confirmButtonText: 'Salva',
                                                  confirmButtonColor: '#003A5D',
                                                  showLoaderOnConfirm: true,
                                                  inputValue: item.nome_speciale,
                                                  inputLabel: 'Modifica il nome',
                                                  didOpen: function () {
                                                    document.querySelector("input#swal2-input").style.fontSize = "14px";
                                                  },
                                                  inputValidator: (value) => {
                                                    if (!value || value === "") {
                                                      return 'Devi inserire un nome non vuoto'
                                                    }
                                                  }
                                                }).then((result) => {
                                                  if (result.isConfirmed) {
                                                    let newName = result.value;
                                                    let itemToModify = orderTemp.optional.filter(itemToModify => itemToModify.code === item.code)[0];
                                                    itemToModify.nome_speciale = newName;
                                                    orderTemp.optional = orderTemp.optional.filter(itemToModify => itemToModify.code !== item.code);
                                                    orderTemp.optional.push(itemToModify);
                                                    let orderTotal = JSON.parse(JSON.stringify(order));
                                                    orderTotal.order = orderTotal.order.filter(orderItem => orderItem.id !== orderTemp.id);
                                                    orderTotal.order.push(orderTemp);
                                                    orderTotal = sortOrder(orderTotal);
                                                    setOrder(orderTotal);
                                                    localStorage.setItem("ht-order", JSON.stringify(orderTotal));
                                                    prezzoTotaleOrdine();
                                                  }
                                                })
                                              }}></i>}{item.is_special && <><br /><Badge pill className="badge-primary ms-1">
                                                Elemento personalizzato
                                              </Badge></>}</Td>
                                              <Td style={{ textAlign: "right" }}>{item.quantity}</Td>
                                              <Td style={{ textAlign: "right" }}>{item.is_special && <i className="bx bx-edit-alt" style={{ cursor: "pointer" }} onClick={() => {
                                                Swal.fire({
                                                  title: (typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome) + ' (' + item.code + ')',
                                                  input: 'number',
                                                  inputAttributes: {
                                                    autocapitalize: 'off'
                                                  },
                                                  showCancelButton: true,
                                                  cancelButtonText: 'Annulla',
                                                  confirmButtonText: 'Salva',
                                                  confirmButtonColor: '#003A5D',
                                                  showLoaderOnConfirm: true,
                                                  inputValue: item.prezzo[0].prezzo,
                                                  inputLabel: 'Modifica il prezzo',
                                                  inputValidator: (value) => {
                                                  }
                                                }).then((result) => {
                                                  if (result.isConfirmed) {
                                                    let newPrice = result.value;
                                                    if (!newPrice || newPrice < 1 || newPrice === "") {
                                                      newPrice = 0;
                                                    }
                                                    let itemToModify = orderTemp.optional.filter(itemToModify => itemToModify.code === item.code)[0];
                                                    itemToModify.prezzo = [{
                                                      prezzo: newPrice,
                                                      qta: 0
                                                    }];
                                                    orderTemp.optional = orderTemp.optional.filter(itemToModify => itemToModify.code !== item.code);
                                                    orderTemp.optional.push(itemToModify);
                                                    let orderTotal = JSON.parse(JSON.stringify(order));
                                                    orderTotal.order = orderTotal.order.filter(orderItem => orderItem.id !== orderTemp.id);
                                                    orderTotal.order.push(orderTemp);
                                                    orderTotal = sortOrder(orderTotal);
                                                    setOrder(orderTotal);
                                                    localStorage.setItem("ht-order", JSON.stringify(orderTotal));
                                                    prezzoTotaleOrdine();
                                                  }
                                                })
                                              }}></i>} {Number(prezzoCad(item.prezzo, item.quantity)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                                              <Td style={{ textAlign: "right" }}>{Number(prezzoTot(item.prezzo, item.quantity)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                                              <Td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                <div className="btn btn-sm btn-light"
                                                  onClick={() => {
                                                    Swal.fire({
                                                      title: (typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome) + ' (' + item.code + ')',
                                                      input: 'number',
                                                      inputAttributes: {
                                                        autocapitalize: 'off'
                                                      },
                                                      showCancelButton: true,
                                                      cancelButtonText: 'Annulla',
                                                      confirmButtonText: 'Salva',
                                                      confirmButtonColor: '#003A5D',
                                                      showLoaderOnConfirm: true,
                                                      inputValue: item.quantity,
                                                      inputLabel: 'Modifica la quantità',
                                                      inputValidator: (value) => {
                                                        if (!value || value < 1 || value === "") {
                                                          return 'Devi inserire una quantità maggiore di 0'
                                                        }
                                                      }
                                                    }).then((result) => {
                                                      if (result.isConfirmed) {
                                                        let newQuantity = result.value;
                                                        let itemToModify = orderTemp.optional.filter(itemToModify => itemToModify.code === item.code)[0];
                                                        itemToModify.quantity = newQuantity;
                                                        orderTemp.optional = orderTemp.optional.filter(itemToModify => itemToModify.code !== item.code);
                                                        orderTemp.optional.push(itemToModify);
                                                        let orderTotal = JSON.parse(JSON.stringify(order));
                                                        orderTotal.order = orderTotal.order.filter(orderItem => orderItem.id !== orderTemp.id);
                                                        orderTotal.order.push(orderTemp);
                                                        orderTotal = sortOrder(orderTotal);
                                                        setOrder(orderTotal);
                                                        localStorage.setItem("ht-order", JSON.stringify(orderTotal));
                                                        prezzoTotaleOrdine();
                                                      }
                                                    })
                                                  }}
                                                ><i className="bx bx-edit-alt"></i></div>
                                                <div className="d-inline px-2" />
                                                <div className="btn btn-sm btn-danger"
                                                  onClick={() => {
                                                    Swal.fire({
                                                      title: 'Eliminazione optional',
                                                      text: 'Sicuro di voler eliminare ' + (typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome) + ' (' + item.code + ')' + '?',
                                                      type: 'warning',
                                                      showCancelButton: true,
                                                      cancelButtonText: 'Annulla',
                                                      confirmButtonText: 'Sì, elimina!',
                                                      confirmButtonColor: '#f46a6a',
                                                    }).then((result) => {
                                                      if (result.isConfirmed) {
                                                        orderTemp.optional = orderTemp.optional.filter(itemToModify => itemToModify.code !== item.code);
                                                        let orderTotal = JSON.parse(JSON.stringify(order));
                                                        orderTotal.order = orderTotal.order.filter(orderItem => orderItem.id !== orderTemp.id);
                                                        orderTotal.order.push(orderTemp);
                                                        orderTotal = sortOrder(orderTotal);
                                                        setOrder(orderTotal);
                                                        localStorage.setItem("ht-order", JSON.stringify(orderTotal));
                                                        prezzoTotaleOrdine();
                                                      }
                                                    })
                                                  }}
                                                ><i className="bx bx-trash-alt"></i></div>
                                              </Td></>}
                                        </tr>
                                      )}
                                    </Draggable>
                                  )}
                                  {provided.placeholder}
                                </tbody>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </Table></div></>}
                      <div className="mb-3">
                        <label htmlFor="formControlDiscountPerc" className="mb-2"><strong>Sconto (%)</strong></label>
                        <input type="number" className="form-control" id="formControlDiscountPerc" placeholder="Sconto (%)" value={orderTemp.sconto} onChange={(e) => addDiscount(orderTemp, e.target.value)} />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="formControlDiscountPerc" className="mb-2"><strong>Prezzo netto</strong></label>
                        <input type="number" className="form-control" id="formControlDiscountPerc" placeholder="Prezzo netto" value={orderTemp.netPrice} onChange={(e) => addNetPrice(orderTemp, e.target.value)} />
                      </div>
                      <h5 style={{ textAlign: "right" }}>Totale macchinario: {Number(prezzoTotMacchinarioNoSconto(orderTemp)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</h5>
                      {calcoloScontoEffettivo(orderTemp).percentuale > 0 && <h5 style={{ textAlign: "right" }}>Sconto a voi riservato: {Number(calcoloScontoEffettivo(orderTemp).percentuale).toLocaleString("it-IT", { maximumFractionDigits: 2 }) + "%"} (-{Number(calcoloScontoEffettivo(orderTemp).diff).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"})</h5>}
                      <h4 style={{ textAlign: "right" }}>Netto macchinario: {Number(prezzoTotMacchinario(orderTemp)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</h4>
                      <div
                        className="form-check form-switch form-switch-md mb-3"
                      >
                        <input
                          type="checkbox"
                          className="form-check-input mt-0"
                          id="hideTotalPrice"
                          checked={hideDiscountRowPDF.filter((i) => i == orderTemp.id).length > 0}
                          onClick={() => {
                            let ord = JSON.parse(localStorage.getItem("ht-order"));
                            ord.order.map((item) => {
                              if (item.id == orderTemp.id) {
                                return item.no_print_discount = !hideDiscountRowPDF.filter((i) => i == orderTemp.id).length > 0
                              }
                            })
                            let hideDiscountRowPDFTemp = [];
                            if (hideDiscountRowPDF.filter((i) => i == orderTemp.id).length > 0) {
                              hideDiscountRowPDFTemp = JSON.parse(JSON.stringify(hideDiscountRowPDF.filter((i) => i !== orderTemp.id)));
                            } else {
                              hideDiscountRowPDFTemp = JSON.parse(JSON.stringify(hideDiscountRowPDF));
                              hideDiscountRowPDFTemp.push(orderTemp.id);
                            }
                            localStorage.setItem("ht-order", JSON.stringify(ord));
                            setHideDiscountRowPDF(hideDiscountRowPDFTemp);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="hideTotalPrice"
                        >
                          Nascondi lo sconto nel PDF
                        </label>
                      </div>

                      {orderTemp.materialiDiConsumo.length > 0 && <><div className="divider"></div>
                        <h5>Materiale di consumo</h5>
                        <div className="table_wrapper_mobile"><Table className="table table-striped table-bordered">
                          <Thead>
                            <Tr>
                              <Th>Codice</Th>
                              <Th>Descrizione</Th>
                              <Th style={{ textAlign: "right" }}>Quantità</Th>
                              <Th style={{ textAlign: "right" }}>Prezzo di vendita unitario</Th>
                              <Th style={{ textAlign: "right" }}>Sconto</Th>
                              <Th style={{ textAlign: "right" }}>Prezzo di vendita finale</Th>
                              <Th style={{ textAlign: "right" }}>Prezzo totale</Th>
                              <Th style={{ textAlign: "center" }}>Azioni</Th>
                            </Tr>
                          </Thead>
                          <DragDropContext onDragEnd={handleDrop}>
                            <Droppable droppableId={"list-MDCM-" + orderTemp.id}>
                              {(provided) => (
                                <tbody
                                  className={"list-MDCM-" + orderTemp.id}
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {orderTemp.materialiDiConsumo.map((item, index) =>
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                      {(provided) => (
                                        <tr key={item.id}
                                          ref={provided.innerRef}
                                          {...provided.dragHandleProps}
                                          {...provided.draggableProps}
                                        >
                                          <Td style={{ whiteSpace: "nowrap" }}>{item.code}</Td>
                                          {item.is_special ?
                                            <Td colSpan="6">{typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome}
                                              {item.is_special && <><br /><Badge pill className="badge-primary ms-1">
                                                Elemento nota
                                              </Badge></>}
                                            </Td> :
                                            <><Td>{typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome}
                                              {item.is_special && <><br /><Badge pill className="badge-primary ms-1">
                                                Elemento nota
                                              </Badge></>}
                                            </Td>
                                              <Td style={{ textAlign: "right" }}>{item.quantity}</Td>
                                              <Td style={{ textAlign: "right" }}>{Number(item.prezzo_unitario).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                                              <Td style={{ textAlign: "right" }}>{item.sconto > 0 && item.sconto_type == 1 ? item.sconto + "%" : (item.sconto_type == 0 ? "Omaggio" : (item.sconto_type == 2 ? "Promo" : ""))}</Td>
                                              <Td style={{ textAlign: "right" }}>{Number(item.prezzo_unitario * (item.sconto > 0 && item.sconto_type == 1 ? ((100 - item.sconto) / 100) : 1)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                                              <Td style={{ textAlign: "right" }}>{Number(item.prezzo_totale).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td></>
                                          }
                                          <Td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                            <div className="btn btn-sm btn-light"
                                              onClick={() => {
                                                tog_fullscreen({ "qty": item.quantity, "percentuale": item.sconto > 0 ? item.sconto : "", "valUnitario": item.scontoUnitario > 0 ? item.scontoUnitario : "", "omaggio": item.sconto_type === 0, "promo": item.sconto_type === 2 }, item.prod, item.id, orderTemp.id, item.sort_number)
                                              }
                                              }
                                            ><i className="bx bx-edit-alt"></i></div>
                                            <div className="d-inline px-2" />
                                            <div className="btn btn-sm btn-danger"
                                              onClick={() => {
                                                Swal.fire({
                                                  title: 'Eliminazione materiale di consumo',
                                                  text: 'Sicuro di voler eliminare ' + item.nome + " (" + item.code + getScontoType(item) + ")" + '?',
                                                  type: 'warning',
                                                  showCancelButton: true,
                                                  cancelButtonText: 'Annulla',
                                                  confirmButtonText: 'Sì, elimina!',
                                                  confirmButtonColor: '#f46a6a',
                                                }).then((result) => {
                                                  if (result.isConfirmed) {
                                                    orderTemp.materialiDiConsumo = orderTemp.materialiDiConsumo.filter(itemToModify => itemToModify.id !== item.id);
                                                    let orderTotal = JSON.parse(JSON.stringify(order));
                                                    orderTotal.order = orderTotal.order.filter(orderItem => orderItem.id !== orderTemp.id);
                                                    orderTotal.order.push(orderTemp);
                                                    orderTotal = sortOrder(orderTotal);
                                                    setOrder(orderTotal);
                                                    localStorage.setItem("ht-order", JSON.stringify(orderTotal));
                                                    prezzoTotaleOrdine();
                                                  }
                                                })
                                              }}
                                            ><i className="bx bx-trash-alt"></i></div>
                                          </Td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  )}
                                  {provided.placeholder}
                                </tbody>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </Table></div>
                        <h5 style={{ textAlign: "right" }}>Totale materiali di consumo: {Number(prezzoTotMaterialeDiConsumo(orderTemp)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</h5></>}
                    </CardBody>
                  </Card>
                </Col>) : ""}

            {order.type !== "Macchinario" && order.order.length > 0 && <>
              <Col md="12">
                <Card>
                  <CardBody>
                    <h5>Materiale di consumo</h5>
                    <div className="table_wrapper_mobile"><Table className="table table-striped table-bordered">
                      <Thead>
                        <Tr>
                          <Th>Codice</Th>
                          <Th>Descrizione</Th>
                          <Th style={{ textAlign: "right" }}>Quantità</Th>
                          <Th style={{ textAlign: "right" }}>Prezzo di vendita unitario</Th>
                          <Th style={{ textAlign: "right" }}>Sconto</Th>
                          <Th style={{ textAlign: "right" }}>Prezzo di vendita finale</Th>
                          <Th style={{ textAlign: "right" }}>Prezzo totale</Th>
                          <Th style={{ textAlign: "center" }}>Azioni</Th>
                        </Tr>
                      </Thead>
                      <DragDropContext onDragEnd={handleDrop}>
                        <Droppable droppableId="list-MDC">
                          {(provided) => (
                            <tbody
                              className="list-MDC"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {order.order.map((item, index) =>
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                  {(provided) => (
                                    <tr key={item.id}
                                      ref={provided.innerRef}
                                      {...provided.dragHandleProps}
                                      {...provided.draggableProps}
                                    >
                                      <Td style={{ whiteSpace: "nowrap" }}>{item.code}</Td>
                                      {item.is_special ?
                                        <Td colSpan="6">{typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome}
                                          {item.is_special && <><br /><Badge pill className="badge-primary ms-1">
                                            Elemento nota
                                          </Badge></>}
                                        </Td> :
                                        <><Td>{typeof item.nome_speciale !== "undefined" && item.nome_speciale !== "" ? item.nome_speciale : item.nome}
                                          {item.is_special && <><br /><Badge pill className="badge-primary ms-1">
                                            Elemento nota
                                          </Badge></>}
                                        </Td>
                                          <Td style={{ textAlign: "right" }}>{item.quantity}</Td>
                                          <Td style={{ textAlign: "right" }}>{Number(item.prezzo_unitario).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                                          <Td style={{ textAlign: "right" }}>{item.sconto > 0 && item.sconto_type == 1 ? item.sconto + "%" : (item.sconto_type == 0 ? "Omaggio" : (item.sconto_type == 2 ? "Promo" : ""))}</Td>
                                          <Td style={{ textAlign: "right" }}>{Number(item.prezzo_unitario * (item.sconto > 0 && item.sconto_type == 1 ? ((100 - item.sconto) / 100) : 1)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td>
                                          <Td style={{ textAlign: "right" }}>{Number(item.prezzo_totale).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</Td></>
                                      }
                                      <Td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                        <div className="btn btn-sm btn-light"
                                          onClick={() => {
                                            tog_fullscreen({ "qty": item.quantity, "percentuale": item.sconto > 0 ? item.sconto : "", "valUnitario": item.scontoUnitario > 0 ? item.scontoUnitario : "", "omaggio": item.sconto_type === 0, "promo": item.sconto_type === 2 }, item.prod, item.id, item.id, item.sort_number)
                                          }
                                          }
                                        ><i className="bx bx-edit-alt"></i></div>
                                        <div className="d-inline px-2" />
                                        <div className="btn btn-sm btn-danger"
                                          onClick={() => {
                                            Swal.fire({
                                              title: 'Eliminazione materiale di consumo',
                                              text: 'Sicuro di voler eliminare ' + item.nome + " (" + item.code + getScontoType(item) + ")" + '?',
                                              type: 'warning',
                                              showCancelButton: true,
                                              cancelButtonText: 'Annulla',
                                              confirmButtonText: 'Sì, elimina!',
                                              confirmButtonColor: '#f46a6a',
                                            }).then((result) => {
                                              if (result.isConfirmed) {
                                                let orderTotal = JSON.parse(JSON.stringify(order));
                                                orderTotal.order = orderTotal.order.filter(orderItem => orderItem.id !== item.id);
                                                orderTotal = sortOrder(orderTotal);
                                                setOrder(orderTotal);
                                                localStorage.setItem("ht-order", JSON.stringify(orderTotal));
                                                prezzoTotaleOrdine();
                                              }
                                            })
                                          }}
                                        ><i className="bx bx-trash-alt"></i></div>
                                      </Td>
                                    </tr>
                                  )}
                                </Draggable>
                              )}
                              {provided.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Table></div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        history.push((order.proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine") + "/materialediconsumo/scelta");
                      }}
                    >
                      <i className="bx bx-plus font-size-16 align-middle"></i>{" "}
                      Aggiungi Materiale di consumo
                    </button>
                  </CardBody>
                </Card>
              </Col></>}

            <Col md="12" className="ht-important-card">
              <Card>
                <CardBody>
                  <h3 className="m-0" style={{ "textAlign": "right" }}>Totale {(order.proposta === 0 ? "offerta" : "ordine")}: {Number(prezzoTotaleOrdineNumber).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + "€"}</h3>
                </CardBody>
              </Card>
            </Col>

            {typeof order.condizioni !== "undefined" ? <Col md="12">
              <Card>
                <div className="px-4 py-3 border-bottom">
                  <h5>Condizioni & pagamenti <div className="btn btn-sm btn-light"
                    onClick={() => {
                      history.push((order.proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine") + "/macchinario/scelta-condizionidivendita");
                    }}
                  ><i className="bx bx-edit-alt"></i></div></h5>
                </div>
                <CardBody>
                  <strong className="d-block mb-2">Condizioni di vendita</strong>
                  {order.condizioni.sort(function (a, b) { return compareStrings(a.condizione, b.condizione); }).map((item) => <small className="d-block px-3" key={item.idCondizione}>{item.condizione}:<strong dangerouslySetInnerHTML={{ __html: item.valore.descrizione }}></strong></small>)}
                  {order.proposta === 1 && typeof order.spedizione.Indirizzo !== "undefined" && <small className="d-block px-3">Indirizzo di spedizione:<p><strong dangerouslySetInnerHTML={{ __html: (order.spedizione.Descrizione ? order.spedizione.Descrizione + "<br/>" : "") + order.spedizione.Indirizzo + "<br/>(" + order.spedizione.Cap + ") " + order.spedizione.Localita + " (" + order.spedizione.Provincia + ")" }}></strong></p></small>}
                  {typeof order.pagamenti !== "undefined" && <><strong className="d-block mb-2">Metodo di pagamento</strong>
                    {order.pagamenti.map((item) => <small className="d-block px-3" key={item.idCondizione}><strong className="ht_no_margin_p" dangerouslySetInnerHTML={{ __html: item.valore.descrizione }}></strong></small>)}</>}
                </CardBody>
              </Card>
            </Col> : ""}

            <Col md="12">
              <Card>
                <div className="px-4 py-3 border-bottom">
                  <h5>Note dell&apos;agente</h5>
                </div>
                <CardBody>
                  <textarea className="form-control" rows={5} onChange={(event) => {
                    let orderTotalForNote = JSON.parse(localStorage.getItem("ht-order"));
                    orderTotalForNote.note = event.target.value;
                    localStorage.setItem("ht-order", JSON.stringify(orderTotalForNote));
                  }}>{JSON.parse(localStorage.getItem("ht-order")).note}</textarea>
                </CardBody>
              </Card>
            </Col>

            {JSON.parse(localStorage.getItem('ht-order')).proposta == 0 && <Col md="12">
              <Card>
                <div className="px-4 py-3 border-bottom">
                  <h5>Impostazioni PDF</h5>
                </div>
                <CardBody>
                  <div
                    className="form-check form-switch form-switch-md mb-3"
                  >
                    <input
                      type="checkbox"
                      className="form-check-input mt-0"
                      id="showTotalPrice"
                      checked={showOnlyTotalPDF}
                      onClick={() => {
                        let ord = JSON.parse(localStorage.getItem("ht-order"));
                        ord.no_print_price = !showOnlyTotalPDF
                        localStorage.setItem("ht-order", JSON.stringify(ord));
                        setShowOnlyTotalPDF(!showOnlyTotalPDF)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="showTotalPrice"
                    >
                      Nel PDF visualizza solo il prezzo totale
                    </label>
                  </div>
                  <div
                    className="form-check form-switch form-switch-md mb-3"
                  >
                    <input
                      type="checkbox"
                      className="form-check-input mt-0"
                      id="hideTotalPrice"
                      checked={hideTotalPDF}
                      onClick={() => {
                        let ord = JSON.parse(localStorage.getItem("ht-order"));
                        ord.no_print_total_price = !hideTotalPDF
                        localStorage.setItem("ht-order", JSON.stringify(ord));
                        setHideTotalPDF(!hideTotalPDF)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="hideTotalPrice"
                    >
                      Nel PDF nascondi il prezzo totale
                    </label>
                  </div>
                </CardBody>
              </Card>
            </Col>}

            <Col md="12">
              <Card>
                <div className="px-4 py-3 border-bottom">
                  <h5>Tag</h5>
                </div>
                <CardBody className="d-flex flex-column justify-content-between ht_btn_bar_top tagInput">
                  <TagsInput setTags={setTags} tags={tags} />
                  <span>Premere invio per confermare ogni singolo tag<br />Si possono inserire tag multipli</span>
                </CardBody>
              </Card>
            </Col>

            <Col md="12">
              <Card>
                <CardBody className="d-flex justify-content-between ht_btn_bar_top">
                  {JSON.parse(localStorage.getItem('ht-order')).type !== "Materiale di consumo" && (localStorage.getItem("warranty") && JSON.parse(localStorage.getItem("warranty")).length > 0) && <div className="btn btn-light"
                    onClick={() => {
                      tog_warranty();
                    }}
                  >Leggi la garanzia</div>}
                  <SaveBozzaBtn />
                  {!firmaSalta && (JSON.parse(localStorage.getItem('ht-order')).proposta == 1 && (!localStorage.getItem('ht-signature') || firmaEmpty)) ?
                    <div className="btn btn-primary"
                      onClick={() => {
                        tog_firma();
                      }}
                    >Firma acquirente</div> :
                    <SaveOrdineBtn />
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          size="xl"
          isOpen={modalFullscreen}
          toggle={() => {
            setModalFullscreen(false);
          }}
          className={modalElement.componente_speciale ? "modal-fullscreen partial_fullscreen-50" : "modal-fullscreen partial_fullscreen-80"}
        >
          <div className="modal-header">
            <h5
              className="modal-title mt-0 container"
              id={"modalFullscreenLabel" + modalElement.componente_codice}
            >
              {modalElement.componente_nome} - {"Modifica l'" + (JSON.parse(localStorage.getItem("ht-order")).proposta === 0 ? "offerta" : "ordine")}
            </h5>
            <button
              onClick={() => {
                setModalFullscreen(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body container">
            <div className="form_materialediconsumo">
              <h5><strong>{modalElement.componente_nome}</strong> <small>({modalElement.componente_codice})</small></h5>
              {/* <p><strong>Prezzo unitario di listino:</strong> <ShowPrice price={modalElement.componente_prezzo} qty={modalQty}/></p> */}
              {modalElement.componente_note !== "" ? <p><strong>Note:</strong> {modalElement.componente_note}</p> : null}
              <div className="divider"></div>
              {modalElement.componente_speciale && <><div className="d-flex flex-row align-items-center">
                <label htmlFor="formControlDescriprion" className="mb-0"><strong>Descrizione:</strong></label><div className="d-inline mx-3"></div>
                <input type="text" className="form-control" id="formControlDescriprion" placeholder="Descrizione" value={modalSpecialDesc} onChange={(e) => {
                  setModalSpecialDesc(e.target.value);
                  let tempElement = modalElement;
                  tempElement.nome_speciale = e.target.value;
                  setModalElement(tempElement);
                }} />
              </div>
                {/* <br/>
                <div className="d-flex flex-row align-items-center">
                  <label htmlFor="formControlPrezzo" className="mb-0"><strong>Prezzo:</strong></label><div className="d-inline mx-3"></div>
                  <input type="number" className="form-control" id="formControlPrezzo" placeholder="Prezzo" value={modalSpecialPrice} onChange={(e) => {
                    setModalSpecialPrice(e.target.value);
                    let tempElement = modalElement;
                    tempElement.componente_prezzo = [{
                      "prezzo": e.target.value,
                      "qta": 0
                    }];
                    setModalElement(tempElement);
                    handleCalculatorPrice({"qty": modalQty, "percentuale": modalPercentuale, "valUnitario": modalValUnitario, "omaggio": modalOmaggio});
                  }}/>
                </div> */}
                <div className="divider"></div></>
              }
              {!modalElement.componente_speciale && <><div className="d-flex flex-row align-items-center">
                <label htmlFor="formControlQuantity" className="mb-0"><strong>Quantità:</strong></label><div className="d-inline mx-3"></div>
                <input type="text" className="form-control" id="formControlQuantity" placeholder="Quantità" value={modalQty.toString().replace(".", ",")} onFocus={(e) => e.target.select()} onChange={(e) => {
                  if (e.target.value == "" || /^\d*\,?\d*$/.test(e.target.value)) {
                    setModalQty(e.target.value.replace(",", "."));
                    handleCalculatorPrice({ "qty": e.target.value.replace(",", "."), "percentuale": modalPercentuale, "valUnitario": modalValUnitario, "omaggio": modalOmaggio, "promo": modalPromo });
                  }
                }} />
              </div>
                <div className="divider"></div>
                <div>
                  <h3>Sconto</h3>
                  <div className="d-flex flex-row align-items-center mb-2">
                    <label htmlFor="formControlDiscountPerc" className="mb-0"><strong>Sconto in percentuale (%):</strong></label><div className="d-inline mx-3"></div>
                    <input type="text" className="form-control" id="formControlDiscountPerc" placeholder="Sconto in percentuale (%)" value={modalPercentuale.toString().replace(".", ",")} onFocus={(e) => e.target.select()} onChange={(e) => {
                      if (e.target.value == "" || /^\d*\,?\d*$/.test(e.target.value)) {
                        let value = (e.target.value === "" || (e.target.value.replace(",", ".") >= 0 && e.target.value.replace(",", ".") < 100) ? e.target.value.replace(",", ".") : (e.target.value.replace(",", ".") >= 100 ? 100 : 0));
                        setModalPercentuale(value);
                        handleCalculatorPrice({ "qty": modalQty, "percentuale": value, "valUnitario": modalValUnitario, "omaggio": modalOmaggio, "promo": modalPromo });
                      }
                    }} />
                  </div>
                  <div className="d-flex flex-row align-items-center mb-2">
                    <label htmlFor="formControlDiscountVal" className="mb-0"><strong>Valore netto unitario di vendita (€):</strong></label><div className="d-inline mx-3"></div>
                    <input type="text" className="form-control" id="formControlDiscountVal" placeholder="Valore netto unitario di vendita (€)" value={modalValUnitario.toString().replace(".", ",")} onFocus={(e) => e.target.select()} onChange={(e) => {
                      if (e.target.value == "" || /^\d*\,?\d*$/.test(e.target.value)) {
                        let value = (e.target.value === "" || e.target.value.replace(",", ".") >= 0 ? e.target.value.replace(",", ".") : "");
                        setModalValUnitario(value);
                        handleCalculatorPrice({ "qty": modalQty, "percentuale": modalPercentuale, "valUnitario": value, "omaggio": modalOmaggio, "promo": modalPromo });
                      }
                    }} />
                  </div>
                  <div className="d-flex flex-row align-items-center mb-2">
                    <label htmlFor="formControlDiscountOmaggio" className="mb-0"><strong>Omaggio:</strong></label><div className="d-inline mx-3"></div>
                    <input type="checkbox" id="formControlDiscountOmaggio" checked={modalOmaggio} value={modalOmaggio} onClick={(e) => {
                      setModalOmaggio(!modalOmaggio);
                      setModalPromo(false);
                      handleCalculatorPrice({ "qty": modalQty, "percentuale": modalPercentuale, "valUnitario": modalValUnitario, "omaggio": !modalOmaggio, "promo": false });
                    }} />
                  </div>
                  {modalElement.componente_promo && <div className="d-flex flex-row align-items-center mb-2">
                    <label htmlFor="formControlDiscountPromo" className="mb-0"><strong>Promo:</strong></label><div className="d-inline mx-3"></div>
                    <input type="checkbox" id="formControlDiscountPromo" checked={modalPromo} value={modalPromo} onClick={(e) => {
                      setModalOmaggio(false);
                      setModalPromo(!modalPromo);
                      handleCalculatorPrice({ "qty": modalQty, "percentuale": modalPercentuale, "valUnitario": modalValUnitario, "omaggio": false, "promo": !modalPromo });
                    }} />
                  </div>}
                </div>
                <div className="divider"></div>
                {!modalElement.componente_speciale &&
                  <><p><strong>Prezzo di listino unitario:</strong> <ShowPrice price={modalElement.componente_prezzo} qty={1} /> - Prov: {modalElement.componente_classe_provv}</p>
                    {modalElement.componente_prezzo && modalElement.componente_prezzo.length > 1 &&
                      <>
                        <p><strong>Listino promo:</strong></p>
                        <ul>
                          {
                            modalElement.componente_prezzo.map((prz, key) =>
                              prz.qta > 0 && <li key={key}>{Number(prz.prezzo).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + " € (min: " + prz.qta + " " + modalElement.componente_unita_di_misura + ") - Prov: " + prz.classe_provv}</li>
                            )
                          }
                        </ul>
                      </>
                    }
                  </>
                }
                <h5><strong>Prezzo di vendita unitario:</strong> {Number(prezzoUnitarioFinale).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + " €"}</h5>
                <h4><strong>Totale riga:</strong> {Number(prezzoTotaleFinale).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + " €"}</h4></>}
              <div className={(!modalElement.componente_speciale && modalQty > 0) || ((modalElement.componente_speciale && modalSpecialDesc !== "")) ? "btn btn-primary mt-3" : "btn btn-primary mt-3 disabled"} style={{ "width": "100%" }} onClick={() => { editMaterialeDiConsumo() }}>{"Salva le modifiche"}</div>
            </div>
          </div>
        </Modal>

        <Modal
          size="xl"
          isOpen={modalWarranty}
          toggle={() => {
            tog_warranty();
          }}
          className="modal-fullscreen"
        >
          <div className="modal-header">
            <h5
              className="modal-title mt-0 container"
              id={"modalWarrantyLabel" + modalElement.componente_codice}
            >
              Garanzia
            </h5>
            <button
              onClick={() => {
                setModalWarranty(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body container">
            <div dangerouslySetInnerHTML={{ __html: (localStorage.getItem("warranty") && JSON.parse(localStorage.getItem("warranty")).length > 0 ? JSON.parse(localStorage.getItem("warranty"))[0].testo : "") }}></div>
          </div>
        </Modal>

        <Modal
          size="xl"
          isOpen={modalFirma}
          toggle={() => {
            tog_firma();
          }}
          className="modal-fullscreen partial_fullscreen-40"
        >
          <div className="modal-header">
            <h5
              className="modal-title mt-0 container"
              id={"modalFirmaLabel" + modalElement.componente_codice}
            >
              Firma dell&apos;acquirente
            </h5>
            <button
              onClick={() => {
                setModalFirma(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-0">
            <SignaturePad canvasProps={{ className: "signPad" }} ref={sigCanvas} />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                handleCancelFirma();
              }}
              className="btn btn-light "
            >
              Cancella
            </button>
            <button
              type="button"
              onClick={() => {
                handleSaltaFirma();
              }}
              className="btn btn-light "
            >
              Salta la firma
            </button>
            <button
              type="button"
              className="btn btn-primary "
              onClick={() => {
                handleSaveFirma();
              }}
            >
              Salva
            </button>
          </div>
        </Modal>
      </div>
      <SaveBozzaAuto />
    </React.Fragment>
  )
}

Riepilogo.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
}

export default withRouter(withTranslation()(Riepilogo));
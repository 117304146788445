import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Route, Redirect, useHistory } from "react-router-dom"

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  getVerifyToket
} from "store/verifyToket/actions";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = React.useState(true);

  useEffect(() => {
    if(navigator.onLine){
      dispatch(getVerifyToket(setIsLoggedIn));
    } else {
      setIsLoggedIn(localStorage.getItem("authUser") && JSON.parse(localStorage.getItem("authUser")).api_token !== "");
    }
  }, [])

  useEffect(() => {
    if(!isLoggedIn && isAuthProtected){
      history.push("/login");
    }
  }, [isLoggedIn])

  return <Route
    {...rest}
    render={props => {
      if (isLoggedIn && isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware;

import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//contacts
import contacts from "./contacts/reducer"

//products
import products from "./products/reducer"

//archivio
import archivio from "./archivio/reducer"

//tags
import tags from "./tags/reducer"

//pdf
import pdf from "./pdf/reducer"

//pdf order
import pdfOrder from "./pdfOrder/reducer"

// draft
import setDraft from "./saveDraft/reducer"

// order
import setOrder from "./saveOrder/reducer"

// tag
import setTagOrder from "./saveTagsInOrder/reducer"

// edit order
import editOrder from "./editOrder/reducer"

// delete order
import deleteOrder from "./deleteOrder/reducer"

//conditions
import conditions from "./conditions/reducer"

//warranty
import warranty from "./warranty/reducer"

//verifyToket
import verifyToket from "./verifyToket/reducer"

import filesTraning from "./filesTraning/reducer"
import fileTraningDownload from "./fileTraningDownload/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  contacts,
  products,
  pdf,
  conditions,
  warranty,
  setDraft,
  setOrder,
  setTagOrder,
  archivio,
  tags,
  editOrder,
  deleteOrder,
  pdfOrder,
  verifyToket,
  filesTraning,
  fileTraningDownload
})

export default rootReducer

import {
  GET_PDF_SUCCESS,
  GET_PDF_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  pdf: [],
  userProfile: {},
  error: {},
}

const pdf = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PDF_SUCCESS:
      return {
        ...state,
        pdf: action.payload,
      }

    case GET_PDF_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default pdf

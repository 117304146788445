import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_PRODUCTS } from "./actionTypes"

import {
  getProductsSuccess,
  getProductsFail
} from "./actions"
import localForage from 'localforage';

//Include Both Helper File with needed methods
import { getProducts } from "../../helpers/backend_helper"

function* fetchProducts({ payload: setProductsLoaded }) {
  try {
    const response = yield call(getProducts)
    yield put(getProductsSuccess(response.data));
    localForage.removeItem("prodotti");
    localForage.setItem("prodotti", response.data);
    setProductsLoaded(true);
  } catch (error) {
    yield put(getProductsFail(error));
    setProductsLoaded(false);
  }
}

function* productsSaga() {
  yield takeEvery(GET_PRODUCTS, fetchProducts)
}

export default productsSaga;

import PropTypes from "prop-types";
import React, { memo, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { withRouter, useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Spinner
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

//redux
import { useDispatch } from "react-redux";

import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

import {
  getPdf
} from "store/pdf/actions";

import { withTranslation } from "react-i18next";

import ProductList from "components/Heytech/ProductList";
import ProgressBar from "components/Heytech/ProgressBar";
import ShortInfo from "components/Heytech/ShortInfo";
import SaveBozzaBtn from "components/Heytech/SaveBozzaBtn";
import  localforage from "localforage";
import SaveBozzaAuto from "components/Heytech/SaveBozzaAuto";

const SceltaAccessori = props => {
  const history = useHistory();

  const [htOrder, setHtOrder] = React.useState(localStorage.getItem("ht-order") ? JSON.parse(localStorage.getItem("ht-order")) : undefined);
  const [selectedRoute, setSelectedRoute] = React.useState("");
  const [selectedProduct, setSelectedProduct] = React.useState(localStorage.getItem("ht-orderTemp") ? JSON.parse(localStorage.getItem("ht-orderTemp")).accessori.map((item) => item.code) : []);
  const [productData, setProductData] = React.useState([]);
  const [prodQty, setProdQty] = React.useState(localStorage.getItem("ht-orderTemp") ? JSON.parse(localStorage.getItem("ht-orderTemp")).accessori.map((item) => {return {"code": item.code, "qty": (item.quantity > 0 ? item.quantity : 1)}}) : []);
  const [orderTemp, setOrderTemp] = React.useState(localStorage.getItem("ht-orderTemp") ? JSON.parse(localStorage.getItem("ht-orderTemp")) : "");
  const [obbligatoriAccessori, setObbligatoriAccessori] = React.useState(localStorage.getItem("ht-orderTemp") ? JSON.parse(localStorage.getItem("ht-orderTemp")).macchinario.accessori_obbligatori : null);
  const [progressValue, setProgressValue] = React.useState(typeof orderTemp.id === "undefined" ? 20 : 1);

  const dispatch = useDispatch();

  const [modal_fullscreen, setModal_fullscreen] = React.useState(false);
  const [pdfOpenedData, setPdfOpenedData] = React.useState({});
  const [loadedPDF, setLoadedPDF] = React.useState(false);

  const [numPages, setNumPages] = React.useState(null);
  const [urlPdf, setUrlPdf] = React.useState({});
  const [urlPdfBlob, setUrlPdfBlob] = React.useState(undefined);
  const [changedLocalStorage, setChangedLocalStorage] = React.useState(false);

  useEffect(() => {
    if(selectedProduct.length > 0 && productData.length > 0){
      let accessoriList = productData.map((prod) => {
        return {
          "code": prod.componente_codice,
          "nome": prod.componente_nome,
          "prezzo": prod.componente_prezzo,
          "quantity": prodQty.find(item => item.code === prod.componente_codice) ? (prodQty.find(item => item.code === prod.componente_codice).qty > 0 ? prodQty.find(item => item.code === prod.componente_codice).qty : 1) : 1,
          "unita_di_misura": prod.componente_unita_di_misura,
          "nome_speciale": prod.nome_speciale,
          "is_special": prod.componente_speciale
        }
      })
      let supportOrder = orderTemp;
      supportOrder.accessori = accessoriList;
      localStorage.setItem("ht-orderTemp", JSON.stringify(supportOrder));
      setOrderTemp(supportOrder);
      setChangedLocalStorage(!changedLocalStorage);
    } else if(selectedProduct.length > 0 && productData.length == 0){
      localforage.getItem("prodotti").then(function (products) {
        let productsTemp = products;
        productsTemp = products.filter(product => selectedProduct.includes(product.componente_codice));
        let qtyOrder = JSON.parse(localStorage.getItem("ht-orderTemp"));
        productsTemp.map((prod) => {
          prod.quantity = qtyOrder.accessori.find(item => item.code == prod.componente_codice) ? qtyOrder.accessori.find(item => item.code == prod.componente_codice).quantity : 1;
          prod.nome_speciale = qtyOrder.accessori.find(item => item.code == prod.componente_codice) ? qtyOrder.accessori.find(item => item.code == prod.componente_codice).nome_speciale : undefined;
        });
        setProductData(productsTemp);
      });
    } else if(selectedProduct.length === 0){
      let supportOrder = orderTemp;
      supportOrder.accessori = [];
      localStorage.setItem("ht-orderTemp", JSON.stringify(supportOrder));
      setChangedLocalStorage(!changedLocalStorage);
    }
  }, [selectedProduct, prodQty]);

  useEffect(() => {
    if(typeof obbligatoriAccessori !== "undefined" && obbligatoriAccessori !== null){
      localforage.getItem("prodotti").then(function (products) {
        let productsTemp = products;
        productsTemp = products.filter(product => obbligatoriAccessori.includes(product.componente_id));
        let productsTempDef = productsTemp.map((prod) => {
          return {
            "code": prod.componente_codice,
            "nome": prod.componente_nome,
            "prezzo": prod.componente_prezzo,
            "quantity":  prodQty.find(item => item.code === prod.componente_codice) ? (prodQty.find(item => item.code === prod.componente_codice).qty > 0 ? prodQty.find(item => item.code === prod.componente_codice).qty : 1) : 1,
            "unita_di_misura": prod.componente_unita_di_misura,
          }
        })
        let supportOrder = orderTemp;
        supportOrder.accessoriObbligatori = productsTempDef;
        setOrderTemp(supportOrder);
        localStorage.setItem("ht-orderTemp", JSON.stringify(supportOrder));
      });
    }
  }, [obbligatoriAccessori, prodQty])

  useEffect(() => {
    if(loadedPDF && typeof urlPdf !== "undefined" && typeof urlPdf.pdf !== "undefined") {
      const blob = base64toBlob(urlPdf.pdf);
      setUrlPdfBlob(URL.createObjectURL(blob));
    }
  }, [urlPdf, loadedPDF]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const tog_fullscreen = (pdfId = "") => {
    if(pdfId !== ""){
      dispatch(getPdf(pdfId, setLoadedPDF, setUrlPdf));
      setModal_fullscreen(!modal_fullscreen);
      removeBodyCss();
    } else if(typeof pdfOpenedData.id_pdf !== "undefined"){
      dispatch(getPdf(pdfOpenedData.id_pdf, setLoadedPDF, setUrlPdf));
      setModal_fullscreen(!modal_fullscreen);
      removeBodyCss();
    }
  }
  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  }

  const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data;

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{typeof orderTemp.id === "undefined" ? "Scelta" : "Modifica" } accessori | Gruppo Bisaro Cloud</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Attività" parentsBreadcrumb={[{"title":"Crea "+(htOrder. proposta === 0 ? "Offerta" : "Ordine"), "link":(htOrder. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")}]} breadcrumbItem={(typeof orderTemp.id === "undefined" ? "Scelta" : "Modifica") + " accessori"} />
          <Row>
            <ShortInfo changedLocalStorage={changedLocalStorage}/>
            <Col md="12">
              <Card>
                <CardBody>
                  <ProgressBar numberBable={typeof orderTemp.id === "undefined" ? 6 : 3} progressValue={progressValue}/>
                  <div className="d-flex justify-content-between pt-4 ht_btn_bar_top">
                    {typeof orderTemp.id === "undefined" && <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        history.push((htOrder. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")+"/macchinario/scelta-macchina");
                      }}
                    >
                      <i className="bx bx-left-arrow-alt font-size-16 align-middle"></i>{" "}
                      Macchinario
                    </button>}
                    {typeof orderTemp.id === "undefined" && 
                      <SaveBozzaBtn disabled={JSON.parse(localStorage.getItem("ht-order")).order.length == 0 ? true : false}/>}

                    {typeof orderTemp.id !== "undefined" && <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => {
                        history.push((htOrder. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")+"/macchinario/riepilogo");
                      }}
                    >
                      <i className="bx bx-x font-size-16 align-middle"></i>{" "}
                      Annulla modifica
                    </button>}
                    {typeof orderTemp.id !== "undefined" && <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => {
                        let orderTemp = JSON.parse(localStorage.getItem("ht-orderTemp"));
                        let order = JSON.parse(localStorage.getItem("ht-order"));
                        let orderTempDef = order.order.filter(item => item.id !== orderTemp.id);
                        orderTempDef.push(orderTemp);
                        order.order = orderTempDef;
                        localStorage.setItem("ht-order", JSON.stringify(order));
                        history.push((htOrder. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")+"/macchinario/riepilogo");
                      }}
                    >
                      <i className="bx bx-save font-size-16 align-middle"></i>{" "}
                      Salva modifica
                    </button>}
                    
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {history.push((htOrder. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")+"/macchinario/scelta-optional");}}
                    >
                      Optional{" "}
                      <i className="bx bx-right-arrow-alt font-size-16 align-middle"></i>
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <CardBody>
                  {orderTemp.macchinario.accessori_obbligatori && <><ProductList
                    title={<h5 className="mb-0">Accessori obbligatori</h5>}
                    filterQuery={[{"key":"componente_id", "value": orderTemp.macchinario.accessori_obbligatori ? orderTemp.macchinario.accessori_obbligatori : []}]}
                    productData={productData}
                    setProductData={setProductData}
                    urlPdf={urlPdf} setUrlPdf={setUrlPdf}
                    setLoadedPDF={setLoadedPDF}
                    loadedPDF={loadedPDF}
                    tog_fullscreen={tog_fullscreen}
                    setPdfOpenedData={setPdfOpenedData}
                    prodQty = {prodQty}
                    alwaysOpen = {true}
                    setProdQty = {setProdQty}
                    oneList = {true}
                  /> <br/><br/></>}
                  <ProductList
                    title={<h5 className="mb-0">Seleziona gli accessori</h5>}
                    filterQuery={[{"key":"componente_id", "value": orderTemp.macchinario.accessori ? orderTemp.macchinario.accessori : []}]}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    productData={productData}
                    setProductData={setProductData}
                    urlPdf={urlPdf} setUrlPdf={setUrlPdf}
                    setLoadedPDF={setLoadedPDF}
                    loadedPDF={loadedPDF}
                    tog_fullscreen={tog_fullscreen}
                    setPdfOpenedData={setPdfOpenedData}
                    multiSelect={true}
                    withQty={true}
                    prodQty = {prodQty}
                    alwaysOpen = {true}
                    setProdQty = {setProdQty}
                    oneList = {true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            isOpen={modal_fullscreen}
            toggle={() => {
              tog_fullscreen();
            }}
            className="modal-fullscreen"
          >
            <div className="modal-header">
              <h5
                className="modal-title mt-0"
                id="exampleModalFullscreenLabel"
              >
                {pdfOpenedData.nome} - Scheda tecnica
              </h5>
              <button
                onClick={() => {
                  setModal_fullscreen(false);
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                {!loadedPDF && <Spinner className="ms-2" color="primary" />}
                {loadedPDF && typeof urlPdfBlob !== "undefined" && <HTFileViewer memo_extension = {"pdf"} memo_urlFile={urlPdfBlob} />}
              </div>
            </div>
          </Modal>
        </Container>
      </div>
      <SaveBozzaAuto />
    </React.Fragment>
  )
}

const HTFileViewer = memo(function Greeting({ memo_extension, memo_urlFile }) {
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  return <Worker workerUrl="pdf.worker.js">
    <div style={{
      flex: "1",
      overflow: "hidden",
      height: "100%"
    }}>
      <div
        className="rpv-core__viewer"
        style={{
          display: 'flex',
          height: '100%',
          position: 'relative',
        }}
      >
        <div
          style={{
            alignItems: 'center',
            backgroundColor: '#eeeeee',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            borderRadius: '2px',
            bottom: '16px',
            display: 'flex',
            left: '50%',
            padding: '4px',
            position: 'absolute',
            transform: 'translate(-50%, 0)',
            zIndex: 1,
          }}
        >
          <Toolbar>
            {(props: ToolbarSlot) => {
              const {
                CurrentPageInput,
                GoToNextPage,
                GoToPreviousPage,
                NumberOfPages,
                ZoomIn,
                ZoomOut,
              } = props;
              return (
                <>
                  <div style={{ padding: '0px 2px' }}>
                    <ZoomOut />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <ZoomIn />
                  </div>
                  <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                    <GoToPreviousPage />
                  </div>
                  <div style={{ padding: '0px 2px', width: '4rem' }}>
                    <CurrentPageInput />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    / <NumberOfPages />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <GoToNextPage />
                  </div>
                </>
              );
            }}
          </Toolbar>
        </div>
        <div
          style={{
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <Viewer fileUrl={memo_urlFile} plugins={[toolbarPluginInstance]} />
        </div>
      </div>
    </div>
  </Worker>
});

HTFileViewer.propTypes = {
  memo_urlFile: PropTypes.any,
  memo_extension: PropTypes.any,
};

SceltaAccessori.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
}

export default withRouter(withTranslation()(SceltaAccessori));
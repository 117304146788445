import {
  GET_VERIFYTOKET_SUCCESS,
  GET_VERIFYTOKET_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  verifyToket: [],
  error: {},
}

const verifyToket = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VERIFYTOKET_SUCCESS:
      return {
        ...state,
        verifyToket: action.payload,
      }

    case GET_VERIFYTOKET_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default verifyToket

import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react"
import {
  Row,
  Col,
  Collapse,
  Modal,
  Badge,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import classnames from "classnames";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { size } from "lodash";
import CustomSearch from "components/Heytech/CustomSearch";

import ShowPrice from "components/Heytech/ShowPrice";
import uuid from 'react-uuid';
import localforage from "localforage";
import SaveBozzaAuto from "./SaveBozzaAuto";

const ProductListMaterialeDiConsumo = props => {

  const [openCollapse, setOpenCollapse] = React.useState(false);
  const [searchField, setSearchField] = React.useState("");
  const [productsList, setProductsList] = React.useState([]);
  const [productsListFiltered, setProductsListFiltered] = React.useState([]);
  const [productsGroupsList, setProductsGroupsList] = React.useState([]);
  const [modalFullscreen, setModalFullscreen] = React.useState(false);
  const [modalElement, setModalElement] = React.useState({});
  const [modalSpecialDesc, setModalSpecialDesc] = React.useState("");
  const [modalSpecialPrice, setModalSpecialPrice] = React.useState("");
  const [modalQty, setModalQty] = React.useState(0);
  const [modalPercentuale, setModalPercentuale] = React.useState("");
  const [modalValUnitario, setModalValUnitario] = React.useState("");
  const [modalOmaggio, setModalOmaggio] = React.useState(false);
  const [modalPromo, setModalPromo] = React.useState(false);
  const [prezzoUnitarioFinale, setPrezzoUnitarioFinale] = React.useState(0);
  const [prezzoTotaleFinale, setPrezzoTotaleFinale] = React.useState(0);

  useEffect(() => {
    localforage.getItem("prodotti").then(function (products) {
      let productsTemp = products;
      if (props.filterQuery) {
        productsTemp = products.filter(product => {
          let isInQuery = true;
          if (props.filterQuery !== "all") {
            props.filterQuery.map((query) => {
              if (!query.value.includes(product[query.key])) isInQuery = false;
            })
          }
          return isInQuery;
        });
      }
      productsTemp.sort(function (a, b) {
        return compareStrings(a.componente_raggruppamenti, b.componente_raggruppamenti);
      })
      var groups = [];
      productsTemp.map(item => {
        if (groups.filter((i) => i.id === item.componente_raggruppamenti_id).length === 0) {
          groups.push({ "nome": item.componente_raggruppamenti, "id": item.componente_raggruppamenti_id });
        }
      })
      setProductsGroupsList(groups);
      setProductsList(productsTemp);
    });
  }, [productsList])

  function compareStrings(a, b) {
    // Assuming you want case-insensitive comparison
    a = a.toLowerCase();
    b = b.toLowerCase();

    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }

  const getProductsFiltered = (raggruppamenti_id, searchQuery, returnNumber = false) => {
    var splitSearchQuery = searchQuery.split(" ");
    var tempProdFilter = productsList.filter(item => {
      if (searchQuery === "") {
        return item.componente_raggruppamenti_id === raggruppamenti_id;
      } else if (splitSearchQuery.filter(iter => item.componente_nome.toLowerCase().indexOf(iter.toLowerCase()) !== -1).length == splitSearchQuery.length || item.componente_codice.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) {
        return item.componente_raggruppamenti_id === raggruppamenti_id;
      } else false
    })
    if (returnNumber) {
      return tempProdFilter.length;
    } else {
      setProductsListFiltered(tempProdFilter);
    }
  }

  const sizePerPage = 20;
  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: size(productsListFiltered), // replace later with size(users),
    custom: false,
    hideSizePerPage: true,
    showTotal: true,
  };
  const defaultSorted = [
    {
      dataField: "componente_nome", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const tog_fullscreen = (prod) => {
    setModalElement(prod);
    setModalQty(0);
    setModalPercentuale("");
    setModalValUnitario("");
    setModalOmaggio(false);
    setModalPromo(false);
    setModalFullscreen(!modalFullscreen);
    removeBodyCss();
    handleCalculatorPrice({ "qty": 0, "percentuale": "", "valUnitario": "", "omaggio": "", "promo": "" }, prod);
  }
  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  }

  const productListColumns = [
    {
      text: "Descrizione",
      dataField: "componente_nome",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, product) => (
        <div className={typeof props.selectedProduct !== "undefined" ? "cliccable" : ""}>
          <h5 className="font-size-14 mb-1 text-dark">
            {product.componente_nome}
            {product.componente_speciale && <><br /><Badge pill className="badge-primary ms-1">
              Elemento nota
            </Badge></>}
          </h5>
          {!product.componente_speciale && <p className="text-muted mb-0">{product.componente_codice} {!product.componente_speciale && " - "}{!product.componente_speciale && <ShowPrice price={product.componente_prezzo} qty={1} />}{product.componente_classe_provv !== "" ? " - Prov: " + product.componente_classe_provv : ""}{product.componente_note !== "" ? <><br />{product.componente_note}</> : ""}</p>}
        </div>
      ),
    },
    {
      text: "Aggiungi all'" + (JSON.parse(localStorage.getItem("ht-order")).proposta === 0 ? "offerta" : "ordine"),
      dataField: "add_to_order",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, product) => (
        <button
          type="button"
          className="btn btn-primary "
          onClick={() => tog_fullscreen(product)}
        >
          {"Aggiungi all'" + (JSON.parse(localStorage.getItem("ht-order")).proposta === 0 ? "offerta" : "ordine")}
        </button>
      ),
    },
    {
      text: "Scheda tecnica",
      dataField: "componente_pdf",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, product) => (
        product.componente_pdf.length > 0 ? <button
          type="button"
          className="btn btn-outline-primary "
          onClick={() => {
            props.setPdfOpenedData({ "id_pdf": product.componente_pdf[0].id, "nome": product.componente_nome });
            props.tog_fullscreen(product.componente_pdf[0].id);
          }}
        >
          <i className="bx bx-show-alt font-size-16 align-middle me-2"></i>{" "}
          Visualizza
        </button> : ""
      )
    }
  ];

  var node = useRef();

  const keyField = "componente_codice";

  const handleCalculatorPrice = (data, prod = false) => {
    //data = {"qty": modalQty, "percentuale": modalPercentuale, "valUnitario": modalValUnitario, "omaggio": modalOmaggio, "promo": modalPromo}
    let prodToUse = modalElement
    if (prod) {
      prodToUse = prod;
    }
    let totalPrice = 0;
    let unitaryPrice = 0;
    let qty_data = data.qty;
    if (qty_data < 1) {
      qty_data = 1;
    }
    let arrayPrice = prodToUse.componente_prezzo.filter(item => item.qta <= qty_data);
    if (arrayPrice.length > 0) {
      let priceFinal = 0;
      let rightQty = 0;
      arrayPrice.forEach(item => {
        if (item.qta >= rightQty) {
          rightQty = item.qta;
          priceFinal = item.prezzo;
        }
      })
      unitaryPrice = priceFinal;
    }
    if (qty_data > 0 && !data.omaggio && (data.valUnitario !== "" && data.valUnitario > 0)) {
      unitaryPrice = data.valUnitario;
    }

    /* if(data.qty>0 && !data.omaggio && (data.percentuale!=="" && data.percentuale>0)){
      unitaryPrice = (unitaryPrice)*((100-data.percentuale)/100);
    } */

    if (data.omaggio || data.promo) {
      unitaryPrice = 0;
    }

    if (qty_data > 0 && (!data.omaggio && !data.promo) && (data.percentuale !== "" && data.percentuale > 0)) {
      totalPrice = (unitaryPrice * data.qty) * ((100 - data.percentuale) / 100);
    } else {
      totalPrice = (unitaryPrice * data.qty);
    }

    setPrezzoUnitarioFinale(unitaryPrice);
    setPrezzoTotaleFinale(totalPrice);
  }

  const getScontoType = () => {
    if (modalOmaggio) {
      return 0;
    } if (modalPromo) {
      return 2;
    } else if (modalPercentuale > 0 || modalValUnitario > 0) {
      return 1;
    } else {
      return -1;
    }
  }

  const addToOrder = () => {
    if ((!modalElement.componente_speciale && modalQty > 0) || (modalElement.componente_speciale && modalSpecialDesc !== "")) {
      let price = 0;
      let arrayPrice = modalElement.componente_prezzo.filter(item => item.qta < modalQty);
      if (arrayPrice.length > 0) {
        let priceFinal = 0;
        let rightQty = 0;
        arrayPrice.forEach(item => {
          if (item.qta >= rightQty) {
            rightQty = item.qta;
            priceFinal = item.prezzo;
          }
        })
        price = priceFinal;
      }

      let orderTotal = JSON.parse(localStorage.getItem("ht-order"));
      let sortNumber = 0;
      if (orderTotal.type == "Macchinario") {
        let order = JSON.parse(localStorage.getItem("ht-orderTemp"));
        sortNumber = order.materialiDiConsumo.length > 0 ? order.materialiDiConsumo.sort(
          function (a, b) {
            return parseFloat(b['sort_number']) - parseFloat(a['sort_number']);
          }
        )[0]['sort_number'] : 0;
      } else {
        sortNumber = orderTotal.order.length > 0 ? orderTotal.order.sort(
          function (a, b) {
            return parseFloat(b['sort_number']) - parseFloat(a['sort_number']);
          }
        )[0]['sort_number'] : 0;
      }

      let materialeDiConsumo = {
        id: uuid(),
        code: modalElement.componente_codice,
        nome: modalElement.componente_nome,
        nome_speciale: modalElement.componente_speciale ? modalSpecialDesc : undefined,
        is_special: modalElement.componente_speciale,
        prezzo: modalElement.componente_speciale ? undefined : Number(price),
        prezzo_totale: modalElement.componente_speciale ? undefined : Number(prezzoTotaleFinale),
        prezzo_unitario: modalElement.componente_speciale ? undefined : Number(prezzoUnitarioFinale),
        quantity: modalElement.componente_speciale ? undefined : Number(modalQty),
        sconto: modalElement.componente_speciale ? undefined : Number(modalPercentuale),
        scontoUnitario: modalElement.componente_speciale ? undefined : Number(modalValUnitario),
        sconto_type: modalElement.componente_speciale ? undefined : getScontoType(),
        unita_di_misura: modalElement.componente_speciale ? undefined : modalElement.componente_unita_di_misura,
        sort_number: sortNumber + 1,
        prod: modalElement,
      }

      if (orderTotal.type == "Macchinario") {
        let order = JSON.parse(localStorage.getItem("ht-orderTemp"));
        order.materialiDiConsumo.push(materialeDiConsumo);
        order.materialiDiConsumo.sort(
          function (a, b) {
            return parseFloat(a['sort_number']) - parseFloat(b['sort_number']);
          }
        )
        localStorage.setItem("ht-orderTemp", JSON.stringify(order));
      } else {
        orderTotal.order.push(materialeDiConsumo);
        orderTotal.order.sort(
          function (a, b) {
            return parseFloat(a['sort_number']) - parseFloat(b['sort_number']);
          }
        )
        localStorage.setItem("ht-order", JSON.stringify(orderTotal));
      }
      toastr.options = {
        positionClass: "toast-bottom-full-width",
        closeButton: true,
        preventDuplicates: true,
      };
      toastr.success(modalElement.componente_nome + " è stato aggiunto con successo", "Prodotto aggiunto con successo");
      setModalQty(0);
      setModalPercentuale("");
      setModalValUnitario("");
      setModalOmaggio(false);
      setModalPromo(false);
      setModalFullscreen(false);
      setModalSpecialDesc("");
      props.setChangedLocalStorage(!props.changedLocalStorage);
    }
  }

  return (
    <>
      <div className="product_list_heder d-flex justify-content-between align-middle mb-3">
        {props.title}
        <div className="search-box d-inline-block">
          <div className="position-relative">
            <CustomSearch value={searchField} placeholder="Ricerca" onChange={(e) => {
              setSearchField(e.currentTarget.value);
              getProductsFiltered(openCollapse, e.currentTarget.value);
            }} />
          </div>
        </div>
      </div>
      <div
        className="accordion accordion-flush"
        id="accordionFlushExample"
      >
        {productsGroupsList.reduce((partialSum, item) => partialSum + getProductsFiltered(item.id, searchField, true), 0) == 0 ? <h2 style={{ textAlign: "center" }}>Non ci sono elementi</h2> : productsGroupsList.map((item, index) => {
          var count = getProductsFiltered(item.id, searchField, true);

          return count > 0 && <div className="accordion-item" key={item.id}>
            <h2 className="accordion-header" id="headingFlushOne">
              <button
                className={classnames(
                  "accordion-button",
                  "fw-medium",
                  { collapsed: openCollapse !== item.id }
                )}
                type="button"
                onClick={() => {
                  getProductsFiltered(item.id, searchField);
                  setOpenCollapse(openCollapse === item.id ? false : item.id)
                }}
                style={{ cursor: "pointer" }}
              >
                {item.nome}
                <span className="badge bg-primary ms-1">{count}</span>
              </button>
            </h2>

            <Collapse
              isOpen={openCollapse === item.id}
              className="accordion-collapse"
            >
              <div className="accordion-body">
                <div className="text-muted">
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField={keyField}
                    columns={productListColumns}
                    data={productsListFiltered}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField={keyField}
                          data={productsListFiltered}
                          columns={productListColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={keyField}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={node}
                                      className="ht_mobile_vertical"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      );
                    }}
                  </PaginationProvider>
                </div>
              </div>
            </Collapse>
          </div>
        })}
        <Modal
          size="xl"
          isOpen={modalFullscreen}
          toggle={() => {
            setModalFullscreen(false);
          }}
          className={modalElement.componente_speciale ? "modal-fullscreen partial_fullscreen-50" : "modal-fullscreen partial_fullscreen-80"}
        >
          <div className="modal-header">
            <h5
              className="modal-title mt-0 container"
              id={"modalFullscreenLabel" + modalElement.componente_codice}
            >
              {modalElement.componente_nome} - {"Aggiungi all'" + (JSON.parse(localStorage.getItem("ht-order")).proposta === 0 ? "offerta" : "ordine")}
            </h5>
            <button
              onClick={() => {
                setModalFullscreen(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body container">
            <div className="form_materialediconsumo">
              <h5><strong>{modalElement.componente_nome}</strong> <small>({modalElement.componente_codice})</small></h5>
              {!modalElement.componente_speciale &&
                <>{modalElement.componente_note !== "" ? <p><strong>Note:</strong> {modalElement.componente_note}</p> : null}</>
              }
              <div className="divider"></div>
              {modalElement.componente_speciale && <><div className="d-flex flex-row align-items-center">
                <label htmlFor="formControlDescriprion" className="mb-0"><strong>Nota:</strong></label><div className="d-inline mx-3"></div>
                <input type="text" className="form-control" id="formControlDescriprion" placeholder="Nota" value={modalSpecialDesc} onChange={(e) => {
                  setModalSpecialDesc(e.target.value);
                  let tempElement = modalElement;
                  tempElement.nome_speciale = e.target.value;
                  setModalElement(tempElement);
                }} />
              </div>{/* <br/>
                <div className="d-flex flex-row align-items-center">
                  <label htmlFor="formControlPrezzo" className="mb-0"><strong>Prezzo:</strong></label><div className="d-inline mx-3"></div>
                  <input type="number" className="form-control" id="formControlPrezzo" placeholder="Prezzo" value={modalSpecialPrice} onChange={(e) => {
                    setModalSpecialPrice(e.target.value);
                    let tempElement = modalElement;
                    tempElement.componente_prezzo = [{
                      "prezzo": e.target.value,
                      "qta": 0
                    }];
                    setModalElement(tempElement);
                    handleCalculatorPrice({"qty": modalQty, "percentuale": modalPercentuale, "valUnitario": modalValUnitario, "omaggio": modalOmaggio});
                  }}/>
                </div> */}
                <div className="divider"></div></>
              }
              {!modalElement.componente_speciale &&
                <><div className="d-flex flex-row align-items-center">
                  <label htmlFor="formControlQuantity" className="mb-0"><strong>Quantità ({modalElement.componente_unita_di_misura}):</strong></label><div className="d-inline mx-3"></div>
                  <input type="text" className="form-control" id="formControlQuantity" placeholder="Quantità" value={modalQty.toString().replace(".", ",")} onFocus={(e) => e.target.select()} onChange={(e) => {
                    if (e.target.value == "" || /^\d*\,?\d*$/.test(e.target.value)) {
                      setModalQty(e.target.value.replace(",", "."));
                      handleCalculatorPrice({ "qty": e.target.value.replace(",", "."), "percentuale": modalPercentuale, "valUnitario": modalValUnitario, "omaggio": modalOmaggio, "promo": modalPromo });
                    }
                  }} />
                </div>
                  <div className="divider"></div>
                  <div>
                    <h3>Sconto</h3>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label htmlFor="formControlDiscountPerc" className="mb-0"><strong>Sconto in percentuale (%):</strong></label><div className="d-inline mx-3"></div>
                      <input type="text" className="form-control" id="formControlDiscountPerc" placeholder="Sconto in percentuale (%)" value={modalPercentuale.toString().replace(".", ",")} onFocus={(e) => e.target.select()} onChange={(e) => {
                        if (e.target.value == "" || /^\d*\,?\d*$/.test(e.target.value)) {
                          let value = (e.target.value === "" || (e.target.value.replace(",", ".") >= 0 && e.target.value.replace(",", ".") < 100) ? e.target.value.replace(",", ".") : (e.target.value.replace(",", ".") >= 100 ? 100 : 0));
                          setModalPercentuale(value);
                          handleCalculatorPrice({ "qty": modalQty, "percentuale": value, "valUnitario": modalValUnitario, "omaggio": modalOmaggio, "promo": modalPromo });
                        }
                      }} />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label htmlFor="formControlDiscountVal" className="mb-0"><strong>Valore netto unitario di vendita (€):</strong></label><div className="d-inline mx-3"></div>
                      <input type="text" className="form-control" id="formControlDiscountVal" placeholder="Valore netto unitario di vendita (€)" value={modalValUnitario.toString().replace(".", ",")} onFocus={(e) => e.target.select()} onChange={(e) => {
                        if (e.target.value == "" || /^\d*\,?\d*$/.test(e.target.value)) {
                          let value = (e.target.value === "" || e.target.value.replace(",", ".") >= 0 ? e.target.value.replace(",", ".") : "");
                          setModalValUnitario(value);
                          handleCalculatorPrice({ "qty": modalQty, "percentuale": modalPercentuale, "valUnitario": value, "omaggio": modalOmaggio, "promo": modalPromo });
                        }
                      }} />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                      <label htmlFor="formControlDiscountOmaggio" className="mb-0"><strong>Omaggio:</strong></label><div className="d-inline mx-3"></div>
                      <input type="checkbox" id="formControlDiscountOmaggio" checked={modalOmaggio} value={modalOmaggio} onClick={(e) => {
                        setModalPromo(false);
                        setModalOmaggio(!modalOmaggio);
                        handleCalculatorPrice({ "qty": modalQty, "percentuale": modalPercentuale, "valUnitario": modalValUnitario, "omaggio": !modalOmaggio, "promo": false });
                      }} />
                    </div>
                  </div>
                  {modalElement.componente_promo && <div className="d-flex flex-row align-items-center mb-2">
                    <label htmlFor="formControlDiscountPromo" className="mb-0"><strong>Promo:</strong></label><div className="d-inline mx-3"></div>
                    <input type="checkbox" id="formControlDiscountPromo" checked={modalPromo} value={modalPromo} onClick={(e) => {
                      setModalPromo(!modalPromo);
                      setModalOmaggio(false);
                      handleCalculatorPrice({ "qty": modalQty, "percentuale": modalPercentuale, "valUnitario": modalValUnitario, "omaggio": false, "promo": !modalPromo });
                    }} />
                  </div>}
                  <div className="divider"></div>
                  {!modalElement.componente_speciale &&
                    <><p><strong>Prezzo di listino unitario:</strong> <ShowPrice price={modalElement.componente_prezzo} qty={1} /> - Prov: {modalElement.componente_classe_provv}</p>
                      {modalElement.componente_prezzo && modalElement.componente_prezzo.filter(p => p.qta > 1).length > 0 &&
                        <>
                          <p><strong>Listino promo:</strong></p>
                          <ul>
                            {
                              modalElement.componente_prezzo.map((prz, key) =>
                                prz.qta > 1 && <li key={key}>{Number(prz.prezzo).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + " € (min: " + prz.qta + " " + modalElement.componente_unita_di_misura + ") - Prov: " + prz.classe_provv}</li>
                              )
                            }
                          </ul>
                        </>
                      }
                    </>
                  }
                  <h5><strong>Prezzo di vendita unitario:</strong> {Number(prezzoUnitarioFinale * (modalPercentuale > 0 && !modalOmaggio && !modalPromo ? ((100 - modalPercentuale) / 100) : 1)).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + " €"}</h5>
                  <h4><strong>Totale riga:</strong> {Number(prezzoTotaleFinale).toLocaleString("it-IT", { minimumFractionDigits: 2 }) + " €"}</h4></>}
              <div className={(!modalElement.componente_speciale && modalQty > 0) || ((modalElement.componente_speciale && modalSpecialDesc !== "")) ? "btn btn-primary mt-3" : "btn btn-primary mt-3 disabled"} style={{ "width": "100%" }} onClick={() => { addToOrder() }}>{"Aggiungi all'" + (JSON.parse(localStorage.getItem("ht-order")).proposta === 0 ? "offerta" : "ordine")}</div>
            </div>
          </div>
          <SaveBozzaAuto />
        </Modal>
      </div>
    </>
  )
}

export default ProductListMaterialeDiConsumo

ProductListMaterialeDiConsumo.propTypes = {
  title: PropTypes.any,
  location: PropTypes.object,
  t: PropTypes.any,
  setSelectedProduct: PropTypes.func,
  selectedProduct: PropTypes.array,
  setProductData: PropTypes.func,
  productData: PropTypes.array,
  setUrlPdf: PropTypes.func,
  urlPdf: PropTypes.object,
  setLoadedPDF: PropTypes.func,
  loadedPDF: PropTypes.bool,
  tog_fullscreen: PropTypes.func,
  setPdfOpenedData: PropTypes.func,
  filterQuery: PropTypes.any,
  changedLocalStorage: PropTypes.bool,
  setChangedLocalStorage: PropTypes.func,
};
import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { DELETE_ORDER } from "./actionTypes"

import {
  deleteOrderSuccess,
  deleteOrderFail
} from "./actions"

//Include Both Helper File with needed methods
import { deleteOrder } from "../../helpers/backend_helper"

function* fetchEditOrder({ payload: {deleteOrderLoaded, data} }) {
  try {
    const response = yield call(deleteOrder, data);
    yield put(deleteOrderSuccess(response));
    deleteOrderLoaded(response);
  } catch (error) {
    yield put(deleteOrderFail(error));
    deleteOrderLoaded(0);
  }
}

function* deleteOrderSaga() {
  yield takeEvery(DELETE_ORDER, fetchEditOrder)
}

export default deleteOrderSaga;

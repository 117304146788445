import React, {useEffect, useState} from "react"
import {
  Card,
  CardBody,
  Col,
  DropdownMenu,
  DropdownToggle,
  Form,
  Row,
  UncontrolledDropdown,
} from "reactstrap"

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom"
import { getFilesTraning } from "../../store/filesTraning/actions"

const FileList = (props) => {
  const params = new URLSearchParams(location.search);
  const [filesLoaded, setFilesLoaded] = useState(true);
  const [folders, setFolders] = useState([]);
  useEffect(() => {
    if(props.filesTraning == false){
      props.onGetFilesTraning(setFilesLoaded);
    } else{
      let path = params.get("path");
      let levelFolder = [];
      if(path){
        levelFolder = getSubFolder(props.filesTraning.filter(folder => folder.type=="dir" && typeof folder.path === 'string' && path.startsWith(folder.path))[0], path);
      } else {
        levelFolder = props.filesTraning.filter(folder => folder.type=="dir")
      }      
      let remapFolder = [];
      levelFolder.map(singleFolder => {
        remapFolder.push({
          id : singleFolder.path,
          name: singleFolder.basename,
          file: singleFolder.contents.filter(folder => folder.type!=="dir").length,
          cartelle: singleFolder.contents.filter(folder => folder.type=="dir").length
        })
      })
      setFolders(remapFolder);
    }
  }, [props.filesTraning, location.search])

  const getSubFolder = (folders, path) => {
    if(path && typeof folders !== "undefined" && typeof folders.path !== "undefined" && folders.path !== path){
      let newFinder = folders.contents.filter((folder) => folder.type=="dir" && typeof folder.path === 'string' && path.startsWith(folder.path));
      if(newFinder.length > 0){
        return getSubFolder(newFinder[0], path);
      } else{
        return newFinder.contents.filter(folder => folder.type=="dir");
      }
    } else {
      if(typeof folders !== "undefined" && typeof folders.contents !== "undefined"){
        return folders.contents.filter(folder => folder.type=="dir");
      } else {
        return [];
      }
    }
  }

  return (
    folders.length > 0 &&
    <React.Fragment>
      <div>
        <Row className="mb-3">
          <Col xl={3} sm={6}>
            <div className="mt-2">
              <h5>Cartelle</h5>
            </div>
          </Col>
          <Col xl={9} sm={6}>
            {/* <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
              <div className="search-box mb-2 me-2">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control bg-light border-light rounded"
                    placeholder="Search..."
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </div>

              <UncontrolledDropdown className="mb-0" direction="right">
                <DropdownToggle
                  color="white"
                  className="btn btn-link text-muted mt-n2"
                >
                  <i className="mdi mdi-dots-vertical font-size-20"></i>
                </DropdownToggle>

                <DropdownMenu>
                  <Link className="dropdown-item" to="#">
                    Share Files
                  </Link>
                  <Link className="dropdown-item" to="#">
                    Share with me
                  </Link>
                  <Link className="dropdown-item" to="#">
                    Other Actions
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Form> */}
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          {folders.map((folder, key) => (
            <Col xl={4} sm={6} key={key}>
              <Link to={"/file-manager?path="+folder.id}>
                <Card className="shadow-none border">
                  <CardBody className="p-3">
                    <div className="">
                      <div className="float-end ms-2">
                      </div>
                      <div className="avatar-xs me-3 mb-3">
                        <div className="avatar-title bg-transparent rounded">
                          <i className="bx bxs-folder font-size-24 text-warning"></i>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="overflow-hidden me-auto">
                          <h5 className="font-size-14 text-truncate mb-1">
                            {folder.name}
                          </h5>
                          <p className="text-muted text-truncate mb-0">
                            {folder.file} {folder.file == 1 ? "documento" : "documenti"} e {folder.cartelle} {folder.cartelle == 1 ? "cartella" : "cartelle"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </React.Fragment>
  )
}

FileList.propTypes = {
  filesTraning: PropTypes.any,
  onGetFilesTraning: PropTypes.func,
};

const mapStateToProps = ({ filesTraning }) => ({
  filesTraning: filesTraning.filesTraning,
});

const mapDispatchToProps = dispatch => ({
  onGetFilesTraning: setFilesLoaded => dispatch(getFilesTraning(setFilesLoaded)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FileList));

import {
  GET_PDFORDER,
  GET_PDFORDER_FAIL,
  GET_PDFORDER_SUCCESS,
} from "./actionTypes"

export const getPdfOrder = (idPDFORDER, setPdfOrderLoaded, setUrlPdfOrder) => ({
  type: GET_PDFORDER,
  payload: {idPDFORDER, setPdfOrderLoaded, setUrlPdfOrder},
})

export const getPdfOrderSuccess = pdfOrder => ({
  type: GET_PDFORDER_SUCCESS,
  payload: pdfOrder,
})

export const getPdfOrderFail = error => ({
  type: GET_PDFORDER_FAIL,
  payload: error,
})
import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_USERS } from "./actionTypes"

import {
  getUsersSuccess,
  getUsersFail
} from "./actions"


//Include Both Helper File with needed methods
import { getUsers } from "../../helpers/backend_helper"
import localForage from 'localforage';

function* fetchUsers({ payload: setUsersLoaded }) {
  try {
    const response = yield call(getUsers)
    yield put(getUsersSuccess(response));
    localForage.removeItem("clienti_diretti");
    localForage.removeItem("clienti_sotto_agenti");
    localForage.setItem("clienti_diretti", response.clienti_diretti);
    localForage.setItem("clienti_sotto_agenti", response.clienti_sotto_agenti);
    setUsersLoaded(true);
  } catch (error) {
    yield put(getUsersFail(error));
    setUsersLoaded(false);
  }
}

function* contactsSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
}

export default contactsSaga;

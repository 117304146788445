import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { SET_DRAFT } from "./actionTypes"

import {
  setDraftSuccess,
  setDraftFail
} from "./actions"

//Include Both Helper File with needed methods
import { setDraft } from "../../helpers/backend_helper"

function* fetchSaveDraft({ payload: {setDraftLoaded, data} }) {
  try {
    const response = yield call(setDraft, data);
    yield put(setDraftSuccess(response));
    setDraftLoaded(response);
  } catch (error) {
    yield put(setDraftFail(error));
    setDraftLoaded(0);
  }
}

function* setDraftSaga() {
  yield takeEvery(SET_DRAFT, fetchSaveDraft)
}

export default setDraftSaga;

import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Row,
  Button,
  Nav,
  NavItem,
  NavLink,
  Badge,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import CustomSearch from "components/Heytech/CustomSearch";

import { size } from "lodash";

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import classnames from "classnames";
import localforage from "localforage";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import  * as url from "helpers/url_helper";
import { post } from "helpers/api_helper";

/**
 * Componente per la visualizzazione della tabella dei clienti
 * @param {*} props 
 * @param {object} location
 * @param {*} t
 * @param {function} setSelectedClient
 * @param {number} selectedClient
 * @param {boolean} withCreateBtn
 * @param {function} setClientData
 * @returns 
 */

const ClientList = props => {
  const [ customers, setCustomers ] = useState([]);
  const [ customersNoCapoArea, setCustomersNoCapoArea ] = useState([]);
  const [ customersCapoArea, setCustomersCapoArea ] = useState([]);

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [searchField, setSearchField] = React.useState("");
  const history = useHistory();

  useEffect(() => {
    localforage.getItem("clienti_diretti").then(function (item) {
      let userPreSelected = item.filter((user) => user.anagrafica_id === props.selectedClient);
      if(userPreSelected.length > 0){
        props.setClientData(userPreSelected[0]);
      }
      setCustomersNoCapoArea(item);
    });
    localforage.getItem("clienti_sotto_agenti").then(function (item) {
      let userPreSelected = item.filter((user) => user.anagrafica_id === props.selectedClient);
      if(userPreSelected.length > 0){
        props.setClientData(userPreSelected[0]);
      }
      setCustomersCapoArea(item);
    });
  }, [customActiveTab])

  useEffect(() => {
    if(customActiveTab == 1){
      setCustomers(customersNoCapoArea);
    } else {
      setCustomers(customersCapoArea);
    }
  }, [customersNoCapoArea, customersCapoArea])

  const { SearchBar } = Search;
  const sizePerPage = 20;
  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: size(customers), // replace later with size(users),
    custom: false,
    hideSizePerPage: true,
    showTotal: true,
  };
  const defaultSorted = [
    {
      dataField: "anagrafica_descrizione", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const selectRow = {
    mode: "radio",
    selected: [props.selectedClient],
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      props.setSelectedClient(row.anagrafica_id);
      props.setClientData(row);
    }
  };

  const contactListColumns = [
    {
      dataField: "img",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, customer) => (
        <>
          <div className="avatar-xs">
              <span className="avatar-title rounded-circle">
                {typeof customer.anagrafica_descrizione !== "undefined" && customer.anagrafica_descrizione !== null ? customer.anagrafica_descrizione.charAt(0) : ""}
              </span>
            </div>
        </>
      ),
    },
    {
      text: "Denominazione",
      dataField: "anagrafica_descrizione",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, customer) => (
        <>
          <h5 className="font-size-14 mb-1 text-dark">
            {customer.anagrafica_descrizione}
          </h5>
          <p className="text-muted mb-0">{customer.anagrafica_codice}{customer.anagrafica_qualifica && <Badge pill className={customer.anagrafica_qualifica =="Cliente" ? "badge-primary ms-1" : "badge-warning bg-warning ms-1"}>{customer.anagrafica_qualifica}</Badge>}</p>
        </>
      ),
    },
    {
      dataField: "anagrafica_email",
      text: "E-mail",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, customer) => (
        customer.anagrafica_email !== "" ? <>
          <a href={"mailto:" + customer.anagrafica_email} target="_blank" rel="noreferrer" >
            {customer.anagrafica_email}
          </a>
        </> : ""
      ),
    },
    {
      text: "Località",
      dataField: "anagrafica_indirizzo[0].Localita",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, customer) => (
        <>
          {customer.anagrafica_indirizzo.length > 0 ? customer.anagrafica_indirizzo[0].Localita : ""}
        </>
      ),
    },
  ];

  var node = useRef();

  const keyField = "anagrafica_id";

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
    if(tab == 1){
      setCustomers(customersNoCapoArea);
    } else {
      setCustomers(customersCapoArea);
    }
    console.log(customersNoCapoArea, customersCapoArea);
  };

  const handleSearch = (searchQuery) => {
    var splitSearchQuery = searchQuery.split(" ");
    var customerList = [];
    if(customActiveTab == 1){
      customerList = customersNoCapoArea;
    } else {
      customerList = customersCapoArea;
    }
    var tempClientFilter = customerList.filter(item => {
      if(searchQuery === "") {
        return true;
      } else {
        return (item.anagrafica_descrizione !== null && splitSearchQuery.filter(iter => item.anagrafica_descrizione.toLowerCase().indexOf(iter.toLowerCase()) !== -1).length == splitSearchQuery.length)
          || (item.anagrafica_codice !== null && item.anagrafica_codice.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1)
          //|| (item.anagrafica_email !== null && splitSearchQuery.filter(iter => item.anagrafica_email.toLowerCase().indexOf(iter.toLowerCase()) !== -1).length == splitSearchQuery.length)
          //|| (item.anagrafica_indirizzo.length > 0 && splitSearchQuery.filter(iter => item.anagrafica_indirizzo[0].Localita.toLowerCase().indexOf(iter.toLowerCase()) !== -1).length == splitSearchQuery.length);
      }
    })
    setCustomers(tempClientFilter);
  }
  return (
    <>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField={keyField}
        columns={contactListColumns}
        data={customers}
      >
        {({ paginationProps, paginationTableProps }) => {
          return (
            <ToolkitProvider
              keyField={keyField}
              data={customers}
              columns={contactListColumns}
              bootstrap4
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <div className="product_list_heder d-md-flex justify-content-between align-middle mb-3">
                    {props.title && props.title !== "" && <>
                      {props.title}
                    </> }
                    <>
                      <div className="search-box ms-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <CustomSearch value={searchField} placeholder="Ricerca" onChange = {(e) => {
                            setSearchField(e.currentTarget.value);
                            handleSearch(e.currentTarget.value);
                          }}/>
                        </div>
                      </div>
                    </>
                    {props.withCreateBtn && <>
                      <div className="text-md-end d-flex justify-content-between align-middle gap-2">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          disabled={!((customersNoCapoArea.filter((user) => user.anagrafica_id === props.selectedClient).length > 0 && customersNoCapoArea.filter((user) => user.anagrafica_id === props.selectedClient)[0].anagrafica_qualifica !=="Cliente") || 
                            (customersCapoArea.filter((user) => user.anagrafica_id === props.selectedClient).length > 0 && customersCapoArea.filter((user) => user.anagrafica_id === props.selectedClient)[0].anagrafica_qualifica !=="Cliente"))}
                          onClick={() => {
                            let customerSelectedModulo = {};
                            if(customersNoCapoArea.filter((user) => user.anagrafica_id === props.selectedClient).length > 0){
                              customerSelectedModulo = customersNoCapoArea.filter((user) => user.anagrafica_id === props.selectedClient)[0];
                            } else if(customersCapoArea.filter((user) => user.anagrafica_id === props.selectedClient).length > 0){
                              customerSelectedModulo = customersCapoArea.filter((user) => user.anagrafica_id === props.selectedClient)[0];
                            }
                            Swal.fire({
                              title: 'Invia il modulo alla seguente e-mail',
                              input: 'email',
                              inputValue: customerSelectedModulo.anagrafica_email ? customerSelectedModulo.anagrafica_email : "",
                              inputAttributes: {
                                autocapitalize: 'off',
                                required: true
                              },
                              showCancelButton: true,
                              cancelButtonText: 'Annulla',
                              confirmButtonText: 'Invia',
                              confirmButtonColor: '#003a5d',
                              showLoaderOnConfirm: true,
                              preConfirm: (email) => {
                                let body = {id:customerSelectedModulo.anagrafica_id,email:email};
                                return post(url.SEND_MODULO_ANAGRAFICA, body).then(response => {
                                  if (!response.email_success) {
                                    throw new Error(response.email_err)
                                  }
                                  return response
                                })
                                .catch(error => {
                                  Swal.showValidationMessage(
                                    `Errore: ${error}`
                                  )
                                })
                              },
                              allowOutsideClick: () => !Swal.isLoading()
                            }).then((result) => {
                              if (result.isConfirmed) {
                                Swal.fire({
                                  title: 'Modulo inviato con successo',
                                  icon: 'success',
                                  showCancelButton: false,
                                  confirmButtonColor: '#003A5D',
                                  confirmButtonText: 'Ok',
                                  allowOutsideClick: false
                                }).then((result) => {
                                  if (result.value) {
                                  }
                                });
                              }
                            })
                          }}
                        >
                          <i className="bx bx-send" />{" "}
                          Invia modulo dati anagrafici
                        </Button>{" "}
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={() => {
                            localStorage.setItem("ht-temp-customer", props.selectedClient);
                            history.push("/nuova-offerta");
                          }}
                        >
                          <i className="bx bx-briefcase-alt-2" />{" "}
                          Crea Offerta
                        </Button>{" "}
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={() => {
                            localStorage.setItem("ht-temp-customer", props.selectedClient);
                            history.push("/nuovo-ordine");
                          }}
                        >
                          <i className="bx bx-message-square-edit" />{" "}
                          Crea Ordine
                        </Button>
                      </div>
                    </>}
                  </div>
                  <Row>
                    <Col xl="12">
                      {(customersNoCapoArea.length > 0 && customersCapoArea.length >0) && <Nav tabs className="nav-tabs-custom nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customActiveTab === "1",
                            })}
                            onClick={() => {
                              toggleCustom("1");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">Clienti diretti</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customActiveTab === "2",
                            })}
                            onClick={() => {
                              toggleCustom("2");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-user"></i>
                            </span>
                            <span className="d-none d-sm-block">Clienti da Capo Area</span>
                          </NavLink>
                        </NavItem>
                      </Nav>}
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={keyField}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                          selectRow={selectRow}
                          defaultSorted={defaultSorted}
                          classes={
                            "table align-middle table-nowrap table-hover"
                          }
                          bordered={false}
                          striped={false}
                          responsive
                          ref={node}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          );
        }}
      </PaginationProvider>
    </>
  )
}

export default ClientList

ClientList.propTypes = {
  title: PropTypes.any,
  location: PropTypes.object,
  t: PropTypes.any,
  setSelectedClient: PropTypes.func,
  selectedClient: PropTypes.number,
  withCreateBtn: PropTypes.bool,
  setClientData: PropTypes.func,
};
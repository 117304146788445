import {
  GET_WARRANTY_SUCCESS,
  GET_WARRANTY_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  warranty: [],
  error: {},
}

const warranty = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WARRANTY_SUCCESS:
      return {
        ...state,
        warranty: action.payload,
      }

    case GET_WARRANTY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default warranty

import {
  GET_FILEDOWNLOAD_SUCCESS,
  GET_FILEDOWNLOAD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  fileTraining: "",
  userProfile: {},
  error: {},
}

const fileTraningDownload = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FILEDOWNLOAD_SUCCESS:
      return {
        ...state,
        fileTraining: action.payload,
      }

    case GET_FILEDOWNLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default fileTraningDownload

import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ARCHIVIO } from "./actionTypes"

import {
  getArchivioSuccess,
  getArchivioFail
} from "./actions"

import localForage from 'localforage';

//Include Both Helper File with needed methods
import { getArchivio } from "../../helpers/backend_helper"
import localforage from "localforage";

function* fetchArchivio({ payload: { setArchivioData, setArchivioAggiornato, idLast, isTS } }) {
  try {
    const response = yield call(getArchivio, idLast, isTS)
    yield put(getArchivioSuccess(response.data));
    if (idLast == "") {
      setArchivioData(response.data);
      setArchivioAggiornato(1);
      localForage.removeItem("archivio");
      localForage.setItem("archivio", response.data);
      localStorage.setItem("archivioTS", parseInt(new Date().getTime() / 1000));
      localStorage.setItem("firstArchivioTS", parseInt(new Date().getTime() / 1000));
    } else {
      if(isTS){
        localforage.getItem("archivio").then(function (item) {
          let newArray = item.filter((el) => (el.is_draft == 1 && response.drafts.includes(el.id) ) || (!response.deleted.includes(el.id))); //FIltro le bozze e gli eliminati
          newArray = newArray.filter(el => response.data.filter(dat => dat.id == el.id).length == 0);
          let responseArray = response.data.concat(newArray);
          setArchivioData(responseArray);
          setArchivioAggiornato(1);
          localForage.removeItem("archivio");
          localForage.setItem("archivio", responseArray);
          localStorage.setItem("archivioTS", parseInt(new Date().getTime() / 1000));
        });
      } else {
        localforage.getItem("archivio").then(function (item) {
          let responseArray = response.data.concat(item);
          setArchivioData(responseArray);
          setArchivioAggiornato(1);
          localForage.removeItem("archivio");
          localForage.setItem("archivio", responseArray);
          localStorage.setItem("archivioTS", parseInt(new Date().getTime() / 1000));
        });
      }
    }
  } catch (error) {
    console.log(error);
    yield put(getArchivioFail(error));
    if (error.response.status === 401) {
      window.location.replace("/login");
    }
  }
}

function* archivioSaga() {
  yield takeEvery(GET_ARCHIVIO, fetchArchivio)
}

export default archivioSaga;

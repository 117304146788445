import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_VERIFYTOKET } from "./actionTypes"

import {
  getVerifyToketSuccess,
  getVerifyToketFail
} from "./actions"

import localForage from 'localforage';

//Include Both Helper File with needed methods
import { getVerifyToket } from "../../helpers/backend_helper"

function* fetchVerifyToket({ payload: setVerifyToketLoaded }) {
  try {
    const response = yield call(getVerifyToket)
    yield put(getVerifyToketSuccess(response.data));
    setVerifyToketLoaded(true);
  } catch (error) {
    yield put(getVerifyToketFail(error));
    setVerifyToketLoaded(false);
  }
}

function* verifyToketSaga() {
  yield takeEvery(GET_VERIFYTOKET, fetchVerifyToket)
}

export default verifyToketSaga;

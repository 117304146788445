import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { EDIT_ORDER } from "./actionTypes"

import {
  editOrderSuccess,
  editOrderFail
} from "./actions"

//Include Both Helper File with needed methods
import { editOrder } from "../../helpers/backend_helper"

function* fetchEditOrder({ payload: {editOrderLoaded, data} }) {
  try {
    const response = yield call(editOrder, data);
    yield put(editOrderSuccess(response));
    editOrderLoaded(response);
  } catch (error) {
    yield put(editOrderFail(error));
    editOrderLoaded(0);
  }
}

function* editOrderSaga() {
  yield takeEvery(EDIT_ORDER, fetchEditOrder)
}

export default editOrderSaga;

import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import ProductsList from "pages/ProductsList/index"
import CreaOfferta from "pages/Crea/CreaOfferta/index"
import CreaOrdine from "pages/Crea/CreaOrdine/index"
import SceltaMacchina from "pages/Crea/SceltaMacchina/index"
import SceltaAccessori from "pages/Crea/SceltaAccessori/index"
import SceltaOptional from "pages/Crea/SceltaOptional/index"
import SceltaMaterialiDiConsumo from "pages/Crea/SceltaMaterialiDiConsumo/index"
import SceltaCondizioniDiVendita from "pages/Crea/SceltaCondizioniDiVendita/index"
import SceltaMetodoDiPagamento from "pages/Crea/SceltaMetodiDiPagamento/index"
import Riepilogo from "pages/Crea/Riepilogo/index"
import ContactsList from "pages/CustomersList/ContactList/contacts-list"
import Archivio from "pages/Archivio/index"
import DettaglioArchivio from "pages/Archivio/dettaglio"
import DettaglioLocalArchivio from "pages/Archivio/dettaglioLocal"
import FileManager from "pages/FileManager/index"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/products-list", component: ProductsList },
  { path: "/customers-list", component: ContactsList },
  { path: "/file-manager", component: FileManager },

  // Offerta
  { path: "/nuova-offerta/macchinario/scelta-macchina", component: SceltaMacchina },
  { path: "/nuova-offerta/macchinario/scelta-accessori", component: SceltaAccessori },
  { path: "/nuova-offerta/macchinario/scelta-optional", component: SceltaOptional },
  { path: "/nuova-offerta/macchinario/scelta-materialediconsumo", component: SceltaMaterialiDiConsumo },
  { path: "/nuova-offerta/macchinario/scelta-condizionidivendita", component: SceltaCondizioniDiVendita },
  { path: "/nuova-offerta/macchinario/scelta-metododipagamento", component: SceltaMetodoDiPagamento },
  { path: "/nuova-offerta/macchinario/riepilogo", component: Riepilogo },
  { path: "/nuova-offerta/materialediconsumo/scelta", component: SceltaMaterialiDiConsumo },
  { path: "/nuova-offerta/materialediconsumo/scelta-condizionidivendita", component: SceltaCondizioniDiVendita },
  { path: "/nuova-offerta/materialediconsumo/scelta-metododipagamento", component: SceltaMetodoDiPagamento },
  { path: "/nuova-offerta/materialediconsumo/riepilogo", component: Riepilogo },
  { path: "/nuova-offerta", component: CreaOfferta },

  // Archivio
  { path: "/archivio/dettaglio-archivio/:id", component: DettaglioArchivio },
  { path: "/archivio/dettaglio-local-archivio/:id", component: DettaglioLocalArchivio },
  { path: "/archivio", component: Archivio },

  // Ordine
  { path: "/nuovo-ordine/macchinario/scelta-macchina", component: SceltaMacchina },
  { path: "/nuovo-ordine/macchinario/scelta-accessori", component: SceltaAccessori },
  { path: "/nuovo-ordine/macchinario/scelta-optional", component: SceltaOptional },
  { path: "/nuovo-ordine/macchinario/scelta-materialediconsumo", component: SceltaMaterialiDiConsumo },
  { path: "/nuovo-ordine/macchinario/scelta-condizionidivendita", component: SceltaCondizioniDiVendita },
  { path: "/nuovo-ordine/macchinario/scelta-metododipagamento", component: SceltaMetodoDiPagamento },
  { path: "/nuovo-ordine/macchinario/riepilogo", component: Riepilogo },
  { path: "/nuovo-ordine/materialediconsumo/scelta", component: SceltaMaterialiDiConsumo },
  { path: "/nuovo-ordine/materialediconsumo/scelta-condizionidivendita", component: SceltaCondizioniDiVendita },
  { path: "/nuovo-ordine/materialediconsumo/scelta-metododipagamento", component: SceltaMetodoDiPagamento },
  { path: "/nuovo-ordine/materialediconsumo/riepilogo", component: Riepilogo },
  { path: "/nuovo-ordine", component: CreaOrdine },

  // Profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/customers-list" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }

import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_FILEDOWNLOAD } from "./actionTypes"

import {
  getFileTraningDownloadSuccess,
  getFileTraningDownloadFail
} from "./actions"

//Include Both Helper File with needed methods
import { getFileTraning } from "../../helpers/backend_helper"

function* fetchFileTraningDownload({ payload: {key, setFileLoaded, setUrlFile} }) {
  try {
    const response = yield call(getFileTraning, key);
    yield put(getFileTraningDownloadSuccess(response.data));

    setUrlFile(response);
    setFileLoaded(true);
  } catch (error) {
    yield put(getFileTraningDownloadFail(error));
    setFileLoaded(false);
  }
}

function* fileTraningDownloadSaga() {
  yield takeEvery(GET_FILEDOWNLOAD, fetchFileTraningDownload)
}

export default fileTraningDownloadSaga;

import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { withRouter, useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

import { withTranslation } from "react-i18next";

import ProgressBar from "components/Heytech/ProgressBar";
import ShortInfo from "components/Heytech/ShortInfo";
import  MetodoDiPagamentoList from "components/Heytech/MetodoDiPagamentoList";
import SaveBozzaBtn from "components/Heytech/SaveBozzaBtn";
import SaveBozzaAuto from "components/Heytech/SaveBozzaAuto";

const SceltaMetodiDiPagamento = props => {
  const history = useHistory();

  const [orderTot, setOrderTot] = React.useState(localStorage.getItem("ht-order") ? JSON.parse(localStorage.getItem("ht-order")) : "");
  const [progressValue, setProgressValue] = React.useState(100);
  const [changedLocalStorage, setChangedLocalStorage] = React.useState(false);
  const [metodiDiPagamento, setMetodiDiPagamento] = React.useState(JSON.parse(localStorage.getItem("ht-order")).pagamenti ? JSON.parse(localStorage.getItem("ht-order")).pagamenti : []);
  const [allMetodoDiPagamentoSelected, setAllMetodoDiPagamentoSelected] = React.useState(false);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Scelta metodi di pagamento | Gruppo Bisaro Cloud</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Attività" parentsBreadcrumb={[{"title":(orderTot. proposta === 0 ? "Crea Offerta" : "Crea Ordine"), "link":(orderTot. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")}]} breadcrumbItem="Scelta metodi di pagamento" />
          <Row>
            <ShortInfo changedLocalStorage={changedLocalStorage}/>
            <Col md="12">
              <Card>
                <CardBody>
                  <ProgressBar numberBable={orderTot.type == "Macchinario" ? 6 : 3} progressValue={progressValue}/>
                  <div className="d-flex justify-content-between pt-4 ht_btn_bar_top">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        if(orderTot.type == "Macchinario"){
                          history.push((orderTot. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")+"/macchinario/scelta-condizionidivendita");
                        } else {
                          history.push((orderTot. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")+"/materialediconsumo/scelta-condizionidivendita");                          
                        }
                      }}
                    >
                      <i className="bx bx-left-arrow-alt font-size-16 align-middle"></i>{" "}
                      Condizioni di vendita
                    </button>
                    <SaveBozzaBtn />
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        if(orderTot.type == "Macchinario"){
                          history.push((orderTot. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")+"/macchinario/riepilogo");
                        } else {
                          history.push((orderTot. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")+"/materialediconsumo/riepilogo");                       
                        }
                      }}
                      disabled={!allMetodoDiPagamentoSelected}
                    >
                      Riepilogo{" "}
                      <i className="bx bx-right-arrow-alt font-size-16 align-middle"></i>
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <CardBody>
                  <h5 className="mb-5">Seleziona i metodi di pagamento</h5>
                  <MetodoDiPagamentoList changedLocalStorage={changedLocalStorage} setChangedLocalStorage={setChangedLocalStorage} pagamentiPreset={metodiDiPagamento} setPagamenti={setMetodiDiPagamento} setAllPagamentiSelected={setAllMetodoDiPagamentoSelected}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <SaveBozzaAuto />
    </React.Fragment>
  )
}

SceltaMetodiDiPagamento.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
}

export default withRouter(withTranslation()(SceltaMetodiDiPagamento));
import {
  GET_FILESTRANING_SUCCESS,
  GET_FILESTRANING_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  filesTraning: false,
  error: {},
}

const filesTraning = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FILESTRANING_SUCCESS:
      return {
        ...state,
        filesTraning: action.payload,
      }

    case GET_FILESTRANING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default filesTraning

import {
  GET_VERIFYTOKET,
  GET_VERIFYTOKET_FAIL,
  GET_VERIFYTOKET_SUCCESS,
} from "./actionTypes"

export const getVerifyToket = setVerifyToketLoaded => ({
  type: GET_VERIFYTOKET,
  payload: setVerifyToketLoaded
})

export const getVerifyToketSuccess = verifyToket => ({
  type: GET_VERIFYTOKET_SUCCESS,
  payload: verifyToket,
})

export const getVerifyToketFail = error => ({
  type: GET_VERIFYTOKET_FAIL,
  payload: error,
})
import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {
    getTags
} from "store/tags/actions";

import { useDispatch } from "react-redux";

const TagsInput = props => {
    const dispatch = useDispatch();

    const [suggestionsTags, setSuggestionsTags] = React.useState(false);
    const [suggestions, setSuggestions] = React.useState([]);
    const [inputTag, setInputTag] = React.useState("");

    useEffect(() => {
        if(!suggestionsTags){
            dispatch(getTags(setSuggestionsTags));
        }
    }, [suggestionsTags])

    const handleChange = e => {
        const { value } = e.target;
        setInputTag(value);
        handleSuggestion();
    };

    const handleKeyDown = e => {
        if (e.keyCode === 9) {
            e.preventDefault();
        }
        const text = inputTag;
        if ([9, 13].includes(e.keyCode) && text && !props.tags.includes(text)) {
            localStorage.setItem("tags", JSON.stringify([...props.tags, text]));
            props.setTags([...props.tags, text]);
            setInputTag("");
        }
    };

    const handleSuggestion = () => {
        const suggestFilterInput = suggestionsTags ? suggestionsTags.filter(suggest =>
            suggest.toLowerCase().includes(inputTag.toLowerCase())
        ) : [];

        const suggestFilterTags = suggestFilterInput.filter(
            suggest => !props.tags.includes(suggest)
        );
        
        setSuggestions(suggestFilterTags);
    };

    const handleDelete = i => {
        const newTags = props.tags.filter((tag, j) => i !== j);
        localStorage.setItem("tags", JSON.stringify(newTags));
        props.setTags(newTags);
    };

    const AddTags = text => {
        localStorage.setItem("tags", JSON.stringify([...props.tags, text]));
        props.setTags([...props.tags, text]);
        setInputTag("");
    };

    return (
        <div className="tags-content">
            {props.tags.map((tag, i) => (
                <div key={i} className="tag">
                    {tag}
                    {!props.disabled && <div className="remove-tag" onClick={() => handleDelete(i)}>
                        ×
                    </div>}
                </div>
            ))}
            {!props.disabled && <div className="tags-input" style={{flex: "1", minWidth: "200px"}}>
                <input
                    type="text"
                    value={inputTag}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Aggiungi un tag"
                    style={{width: "100%"}}
                />
                {inputTag && Boolean(suggestions.length) && (
                    <div className="tags-suggestions">
                        {suggestions.map((suggest, key) => (
                        <div
                            key={key}
                            className="suggestion-item"
                            onClick={() => AddTags(suggest)}
                        >
                            {suggest}
                        </div>
                        ))}
                    </div>
                )}
            </div>}
        </div>
    );
}


export default TagsInput

TagsInput.propTypes = {
    setTags: PropTypes.func,
    tags: PropTypes.array,
    disabled: PropTypes.bool
};
import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_PDFORDER } from "./actionTypes"

import {
  getPdfOrderSuccess,
  getPdfOrderFail
} from "./actions"

//Include Both Helper File with needed methods
import { getPdfOrder } from "../../helpers/backend_helper"

function* fetchPdfOrder({ payload: {idPDFORDER, setPdfOrderLoaded, setUrlPdfOrder} }) {
  try {
    const response = yield call(getPdfOrder, idPDFORDER);
    yield put(getPdfOrderSuccess(response.data));

    setUrlPdfOrder(response);
    setPdfOrderLoaded(true);
  } catch (error) {
    yield put(getPdfOrderFail(error));
    setPdfOrderLoaded(false);
  }
}

function* pdfOrderSaga() {
  yield takeEvery(GET_PDFORDER, fetchPdfOrder)
}

export default pdfOrderSaga;

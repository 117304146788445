import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_PDF } from "./actionTypes"

import {
  getPdfSuccess,
  getPdfFail
} from "./actions"

//Include Both Helper File with needed methods
import { getPdf } from "../../helpers/backend_helper"

function* fetchPdf({ payload: {idPDF, setPdfLoaded, setUrlPdf} }) {
  try {
    const response = yield call(getPdf, idPDF);
    yield put(getPdfSuccess(response.data));

    setUrlPdf(response);
    setPdfLoaded(true);
  } catch (error) {
    yield put(getPdfFail(error));
    setPdfLoaded(false);
  }
}

function* pdfSaga() {
  yield takeEvery(GET_PDF, fetchPdf)
}

export default pdfSaga;

import React, {memo, useEffect, useState} from "react"

import {
  Table,
  Modal,
  Spinner
} from "reactstrap"

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom"
import { getFilesTraning } from "../../store/filesTraning/actions"

//import { Document, Page } from 'react-pdf';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { getFileTraningDownload } from "store/actions";
import { useDispatch } from "react-redux";
import { getS3File } from "helpers/backend_helper";

import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

const RecentFile = props => {
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  const [filesLoaded, setFilesLoaded] = useState(true);
  const [files, setFiles] = useState([]);
  const [modal_fullscreen, setModal_fullscreen] = React.useState(false);
  const [urlFile, setUrlFile] = React.useState("");
  const [urlFileBlob, setUrlFileBlob] = React.useState(undefined);
  const [loadedPDF, setLoadedPDF] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);

  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  useEffect(() => {
    if(props.filesTraning == false){
      props.onGetFilesTraning(setFilesLoaded);
    } else{
      let path = params.get("path");
      let levelFile = [];
      if(path){
        levelFile = getSubFolder(props.filesTraning.filter(folder => folder.type=="dir" && typeof folder.path === 'string' && path.startsWith(folder.path))[0], path);
      } else {
        levelFile = props.filesTraning.filter(folder => folder.type!=="dir")
      }
      let remapFile = [];
      levelFile.map(singleFolder => {
        remapFile.push({
          id: singleFolder.path,
          icon: getIcon(singleFolder.extension),
          file: singleFolder.basename,
          date: singleFolder.datetime,
          size: singleFolder.size,
          extension: singleFolder.extension
        })
      })
      setFiles(remapFile);
    }
  }, [props.filesTraning, location.search])

  const getSubFolder = (folders, path) => {
    if(path && typeof folders !== "undefined" && typeof folders.path !== "undefined" && folders.path !== path){
      let newFinder = folders.contents.filter((folder) => folder.type=="dir" && typeof folder.path === 'string' && path.startsWith(folder.path));
      if(newFinder.length > 0){
        return getSubFolder(newFinder[0], path);
      } else{
        return newFinder.contents.filter(folder => folder.type!=="dir");
      }
    } else {
      if(typeof folders !== "undefined" && typeof folders.contents !== "undefined"){
        return folders.contents.filter(folder => folder.type!=="dir");
      } else {
        return [];
      }
    }
  }

  const tog_fullscreen = ( file = "") => {
    if(file !== ""){
      dispatch(getFileTraningDownload(file.id, setLoadedPDF, setUrlFile));
      setModal_fullscreen(file);
    } else {
      setUrlFile(false);
      setModal_fullscreen(false);
    }
    removeBodyCss();
  }
  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  }

  useEffect(async () => {
    if(urlFile){
      let blob = await fetch(urlFile).then(r => r.blob());
      setUrlFileBlob(URL.createObjectURL(blob));
    }
  },[urlFile])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const getIcon = (ext) => {
    let iconClass = "";
    switch (ext) {
      case "pdf":
        iconClass = "mdi mdi-file-pdf-box font-size-16 align-middle text-primary me-2";
        break;
      case "jpg":
      case "png":
      case "jpeg":
        iconClass = "mdi mdi-image font-size-16 align-middle text-primary me-2";
        break;
      case "csv":
        iconClass = "mdi mdi-file-delimited font-size-16 align-middle text-primary me-2";
        break;
      case "xlsx":
      case "xls":
        iconClass = "mdi mdi-microsoft-excel font-size-16 align-middle text-primary me-2";
        break;
      case "pptx":
      case "ppt":
        iconClass = "mdi mdi-microsoft-powerpoint font-size-16 align-middle text-primary me-2";
        break;
      case "docx":
      case "doc":
        iconClass = "mdi mdi-microsoft-word font-size-16 align-middle text-primary me-2";
        break;
      case "html":
        iconClass = "mdi mdi-language-html5 font-size-16 align-middle text-primary me-2";
        break;
      case "mp4":
      case "mov":
      case "avi":
      case "flv":
      case "webm":
        iconClass = "mdi mdi-play-box font-size-16 align-middle text-primary me-2";
        break;
      case "mp3":
        iconClass = "mdi mdi-music-box font-size-16 align-middle text-primary me-2";
        break;
      default:
        iconClass = "mdi mdi-file-document font-size-16 align-middle text-primary me-2";
        break;
    }
    return iconClass;
  }

  return (
    files.length > 0 &&
    <React.Fragment>
      <div className="mt-4">
        <div className="d-flex flex-wrap">
          <h5 className="font-size-16 me-3">Documenti</h5>

          {/* <div className="ms-auto">
            <Link to="#" className="fw-medium text-reset">View All</Link>
          </div> */}
        </div>
        <hr className="mt-2" />

        <div className="table-responsive file-manager">
          <Table className="table align-middle table-nowrap table-hover mb-0">
            <thead>
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">Date modificate</th>
                <th scope="col" colSpan="2">
                  Dimensione
                </th>
              </tr>
            </thead>
            <tbody>
              {files.map((file, key) => (
                <tr key={key}>
                  <td>
                    <div onClick={()=>tog_fullscreen(file)} className="text-dark fw-medium" style={{cursor: "pointer"}}>
                      <i className={file.icon}></i> {file.file}
                    </div>
                  </td>
                  <td>{file.date}</td>
                  <td>{file.size}</td>
                  {/* <td>
                    <UncontrolledDropdown className="mb-2" direction="left">
                      <DropdownToggle
                        color="white"
                        className="btn font-size-16 btn-link text-muted mt-n2"
                      >
                        <i className="mdi mdi-dots-horizontal"></i>
                      </DropdownToggle>

                      <DropdownMenu className="dropdown-menu-end">
                        <Link className="dropdown-item" to="#">
                          Open
                        </Link>
                        <Link className="dropdown-item" to="#">
                          Edit
                        </Link>
                        <Link className="dropdown-item" to="#">
                          Rename
                        </Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="#">
                          Remove
                        </Link>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal
        size="xl"
        isOpen={modal_fullscreen !== false}
        toggle={() => {
          tog_fullscreen();
        }}
        className="modal-fullscreen"
      >
        <div className="modal-header">
          <h5
            className="modal-title mt-0"
            id="fileModalFullscreenLabel"
          >
            {modal_fullscreen && modal_fullscreen.file}
          </h5>
          <button
            onClick={() => {
              setModal_fullscreen(false);
              setUrlFile(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="text-center">
            { urlFile == false ? <Spinner className="ms-2" color="primary" /> :
            <>
              {modal_fullscreen && urlFile && modal_fullscreen.extension !== "mp4" && modal_fullscreen.extension !== "pdf" && 
                <HTFileViewer memo_modal_fullscreen = {modal_fullscreen} memo_urlFile={urlFile} />
              }
              {modal_fullscreen && modal_fullscreen.extension == "mp4" && urlFile &&
                <video controls controlsList="nodownload" style={{width: "100%"}}>
                  <source src={urlFile} type="video/mp4" />
                  Your browser does not support HTML video.
                </video>
              }
            </>
            }
          </div>
          {modal_fullscreen && modal_fullscreen.extension == "pdf" && urlFile &&
            <Worker workerUrl="pdf.worker.js">
              <div style={{
                flex: "1",
                overflow: "hidden",
                height: "100%"
              }}>
                <div
                  className="rpv-core__viewer"
                  style={{
                    display: 'flex',
                    height: '100%',
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      alignItems: 'center',
                      backgroundColor: '#eeeeee',
                      border: '1px solid rgba(0, 0, 0, 0.2)',
                      borderRadius: '2px',
                      bottom: '16px',
                      display: 'flex',
                      left: '50%',
                      padding: '4px',
                      position: 'absolute',
                      transform: 'translate(-50%, 0)',
                      zIndex: 1,
                    }}
                  >
                    <Toolbar>
                      {(props: ToolbarSlot) => {
                        const {
                          CurrentPageInput,
                          GoToNextPage,
                          GoToPreviousPage,
                          NumberOfPages,
                          ZoomIn,
                          ZoomOut,
                        } = props;
                        return (
                          <>
                            <div style={{ padding: '0px 2px' }}>
                              <ZoomOut />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                              <ZoomIn />
                            </div>
                            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                              <GoToPreviousPage />
                            </div>
                            <div style={{ padding: '0px 2px', width: '4rem' }}>
                              <CurrentPageInput />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                              / <NumberOfPages />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                              <GoToNextPage />
                            </div>
                          </>
                        );
                      }}
                    </Toolbar>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      overflow: 'hidden',
                    }}
                  >
                    <Viewer fileUrl={urlFile} plugins={[toolbarPluginInstance]} />
                  </div>
                </div>
              </div>
            </Worker>
          }
        </div>
      </Modal>
    </React.Fragment>
  )
}

const HTFileViewer = memo(function Greeting({memo_modal_fullscreen, memo_urlFile}) {
  return <DocViewer
    pluginRenderers={DocViewerRenderers}
    documents={[{uri: memo_urlFile, fileType: memo_modal_fullscreen.extension}]}
    config={{
      header: {
        disableHeader: true,
        disableFileName: true,
        retainURLParams: true
      }
    }}
  />;
});

HTFileViewer.propTypes = {
  memo_urlFile: PropTypes.any,
  memo_modal_fullscreen: PropTypes.any,
};

RecentFile.propTypes = {
  filesTraning: PropTypes.any,
  onGetFilesTraning: PropTypes.func,
};

const mapStateToProps = ({ filesTraning }) => ({
  filesTraning: filesTraning.filesTraning,
});

const mapDispatchToProps = dispatch => ({
  onGetFilesTraning: setFilesLoaded => dispatch(getFilesTraning(setFilesLoaded)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RecentFile));

import {
  SET_ORDER,
  SET_ORDER_FAIL,
  SET_ORDER_SUCCESS,
} from "./actionTypes"

export const setOrder = (setOrderLoaded, data) => ({
  type: SET_ORDER,
  payload: {setOrderLoaded, data},
})

export const setOrderSuccess = result => {
  return ({
    type: SET_ORDER_SUCCESS,
    payload: result,
  })
}

export const setOrderFail = error => ({
  type: SET_ORDER_FAIL,
  payload: error,
})
import {
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  editOrder: [],
  error: {},
}

const editOrder = (state = INIT_STATE, action) => {
  switch (action.type) {
    case EDIT_ORDER_SUCCESS:
      return {
        ...state,
        editOrder: action.payload,
      }

    case EDIT_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default editOrder

import {
  SET_TAGORDER_SUCCESS,
  SET_TAGORDER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  saveOrder: [],
  error: {},
}

const setTagOrder = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TAGORDER_SUCCESS:
      return {
        ...state,
        saveOrder: action.payload,
      }

    case SET_TAGORDER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default setTagOrder

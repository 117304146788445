import PropTypes from "prop-types";
import React, { useEffect } from "react"
//redux
import { useDispatch } from "react-redux";
import {Spinner} from 'reactstrap';

import {
  setOrder
} from "store/saveOrder/actions";

import {
  editOrder
} from "store/editOrder/actions";

import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";

const SaveOrdineBtn = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [orderLoaded, setOrderLoaded] = React.useState(false);
  const [orderData, setOrderData] = React.useState(false);
  const [draftLocalId, setDraftLocalId] = React.useState(false);

  useEffect(() => {
    if(orderData && orderData.id){
      if(draftLocalId && draftLocalId > 0){
        let ordersLocal = JSON.parse(localStorage.getItem("ht-save-offline"));
        ordersLocal = ordersLocal.filter((o) => o.offlineId !== draftLocalId);
        if(ordersLocal.length > 0 ){
          localStorage.setItem("ht-save-offline", JSON.stringify(ordersLocal));
        } else {
          localStorage.removeItem("ht-save-offline");
        }
      }
      let order = JSON.parse(localStorage.getItem("ht-order"));
      let bodyMsg = (order.proposta == 1 ? 'La ' : "L'") + (order.proposta == 1 ? "Proposta d'ordine" : "Offerta") + ' è stata salvata con successo!<br/>Riceverai un e-mail di conferma.';
      if(!orderData.email_success){
        bodyMsg = (order.proposta == 1 ? 'La ' : "L'") + (order.proposta == 1 ? "Proposta d'ordine" : "Offerta") + ' è stata salvata con successo!<br/><strong>C\'è stato un problema nell\'invio dell\'email,<br/>contatta il Gruppo Bisaro per segnalare il problema.</strong>'
      }
      Swal.fire({
        title: (order.proposta == 1 ? "Proposta d'ordine " : "Offerta ") + order.type + ' salvata con successo',
        html: bodyMsg,
        icon: !orderData.email_success ? 'warning' : 'success',
        showCancelButton: false,
        confirmButtonColor: '#003A5D',
        confirmButtonText: 'Ok',
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem("ht-order");
          localStorage.removeItem("tags");
          localStorage.removeItem("ht-signature-realdim");
          localStorage.removeItem("ht-signature");
          history.push("/archivio");
        }
      });
    } else if (orderData === 0){
      Swal.fire({
        title: 'Ooops! Qualcosa è andato storto.',
        html: 'Verificare che tutti i dati siano corretti!',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#003A5D',
        confirmButtonText: 'Ok',
        allowOutsideClick: false
      }).then((result) => {
        setOrderLoaded(false);
      });
    }
  }, [orderData])

  return (
    <div className="btn btn-primary"
      onClick={() => {
        let order = JSON.parse(localStorage.getItem('ht-order'));
        let is_edit = order.edited == 1;
        let code = order.code;
        if(is_edit) {
          delete order.edited;
          delete order.code;
        }
        if(order.proposta == 1){
          if(localStorage.getItem("ht-signature")){
            order.signature = localStorage.getItem("ht-signature");
          } else {
            order.signature = "salta";
          }
        }
        order.cliente.codice = order.cliente.anagrafica_codice;
        let callData = {
          "data": order
        }
        if(!is_edit){
          if( typeof order.ref_id !== "undefined" && order.ref_id > 0 ){
            callData.ref_id = order.ref_id;
          }          
        } else {
          callData.code = code;
        }
        if( typeof order.was_draft !== "undefined" && order.was_draft > 0 ){
          callData.was_draft = order.was_draft;
        }
        if(localStorage.getItem("tags")){
          callData.tags = JSON.parse(localStorage.getItem("tags"));
        }
        if(navigator.onLine){
          if(typeof order.offlineId !== "undefined" && order.offlineId > 0){
            setDraftLocalId(order.offlineId);
            delete order["offlineId"];
            callData.data = order;
            localStorage.setItem("ht-order", JSON.stringify(order));
            if(typeof code == "undefined")
              is_edit = false;
          }
          if(is_edit && !(typeof order.was_draft !== "undefined" && order.was_draft > 0)){
            dispatch(editOrder(setOrderData, callData));
          } else {
            dispatch(setOrder(setOrderData, callData));
          }
          setOrderLoaded(true);
        } else {
          let save_offline = localStorage.getItem('ht-save-offline') ? JSON.parse(localStorage.getItem('ht-save-offline')) : [];
          if(typeof order.offlineId !== "undefined" && order.offlineId > 0){
            save_offline = save_offline.filter((item) => {
              return item.offlineId !== order.offlineId;
            });
          }
          callData.offlineId = Date.now();
          if(is_edit && !(typeof order.was_draft !== "undefined" && order.was_draft > 0)){
            callData.offLineStatus = "Edit";
          } else {
            callData.offLineStatus = "Order";
          }
          order.offlineId = callData.offlineId;
          localStorage.setItem('ht-order', JSON.stringify(order));
          save_offline.push(callData);
          
          localStorage.setItem('ht-save-offline', JSON.stringify(save_offline));
          let bodyMsg = (order.proposta == 1 ? 'La ' : "L'") + (order.proposta == 1 ? "Proposta d'ordine" : "Offerta") + ' è stata salvata con successo!<br/>Ricorda si sincronizzare i dati appena torni on-line.';
          Swal.fire({
            title: (order.proposta == 1 ? "Proposta d'ordine " : "Offerta ") + order.type + ' salvata con successo',
            html: bodyMsg,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#003A5D',
            confirmButtonText: 'Ok',
            allowOutsideClick: false
          }).then((result) => {
            if (result.value) {
              localStorage.removeItem("ht-order");
              localStorage.removeItem("tags");
              localStorage.removeItem("ht-signature-realdim");
              localStorage.removeItem("ht-signature");
              history.push("/archivio");
            }
          });
        }
      }}
      disabled = {orderLoaded === true || props.disabled}
    >{!(orderLoaded === true) ?  ((JSON.parse(localStorage.getItem('ht-order')).edited == 1 && !(typeof JSON.parse(localStorage.getItem('ht-order')).was_draft !== "undefined" && JSON.parse(localStorage.getItem('ht-order')).was_draft > 0)) ? "Modifica e invia e-mail" : "Salva e invia e-mail") : <Spinner
        color="light"
        style={{"width": "15px", "height": "15px"}}
      >
        Loading...
      </Spinner>}
    </div>
  )
}

export default SaveOrdineBtn

SaveOrdineBtn.propTypes = {
  disabled: PropTypes.bool
};
import {
  GET_TAGS,
  GET_TAGS_FAIL,
  GET_TAGS_SUCCESS,
} from "./actionTypes"

export const getTags = setTagsData => ({
  type: GET_TAGS,
  payload: setTagsData,
})

export const getTagsSuccess = tags => ({
  type: GET_TAGS_SUCCESS,
  payload: tags,
})

export const getTagsFail = error => ({
  type: GET_TAGS_FAIL,
  payload: error,
})
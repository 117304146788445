import React, { useState, useEffect } from "react"
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledAlert,
  UncontrolledDropdown,
} from "reactstrap"
import { getFilesTraning } from "../../store/filesTraning/actions"

const FileRightBar = (props) => {
  const [filesLoaded, setFilesLoaded] = useState(true);
  const [folders, setFolders] = useState(false);

  useEffect(() => {
    if(props.filesTraning == false){
      props.onGetFilesTraning(setFilesLoaded);
    }
    setFolders(props.filesTraning);
  }, [props.filesTraning])

  return (
    <React.Fragment>
      <Card className="filemanager-sidebar me-md-2 mw-100">
        <CardBody>
          <div className="d-flex flex-column h-100">
            <div className="mb-4">
              <Link to={"/file-manager"} className="d-flex align-items-center">
                <i className="mdi mdi-folder font-size-16 text-warning me-2"></i>{" "}
                <span className="me-auto" style={{maxWidth: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>Documenti</span>
              </Link>
              {folders && <DirAndSubdir dirs={folders}/>}
            </div>

            {/* <div className="mt-auto">
              <UncontrolledAlert color="success" className="px-3 mb-0 alert-dismissible">
                <div className="mb-3">
                  <i className="bx bxs-folder-open h1 text-success"></i>
                </div>

                <div>
                  <h5 className="text-success">Upgrade Features</h5>
                  <p>Cum sociis natoque penatibus et</p>
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-link text-decoration-none text-success"
                    >
                      Upgrade <i className="mdi mdi-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </UncontrolledAlert>
            </div> */}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

const DirAndSubdir = (props) => {
  const params = new URLSearchParams(location.search);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = (key) => {
    if(isOpen == key){
      setIsOpen(false);
    } else {
      setIsOpen(key)
    }
  };
  useEffect(()=>{
    setIsOpen(params.get("path"))
  }, [location.search])

  return (
    <ul className="list-unstyled categories-list">
      {
        props.dirs && props.dirs.map((dir) => {
          if(dir.type == "dir"){
            return <li key={dir.path}>
              {dir.contents.length > 0 && dir.contents.filter((cont) => cont.type == "dir").length > 0 ? 
                <div className="custom-accordion">
                  <Link
                    className="text-body fw-medium py-1 d-flex align-items-center"
                    onClick={() => toggle(dir.path)}
                    to={"/file-manager?path="+dir.path}
                  >
                    <i className="mdi mdi-folder font-size-16 text-warning me-2"></i>{" "}
                    <span style={{maxWidth: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", textDecoration: (dir.path==params.get("path") ? "underline" : "none")}}>{dir.basename}</span>{" "}
                    {/* <i className="mdi mdi-chevron-up accor-down-icon ms-auto"></i> */}
                    <i
                      className={
                        isOpen==dir.path
                          ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                          : "mdi mdi-chevron-down accor-down-icon ms-auto"
                      }
                    />
                  </Link>
                  <Collapse isOpen={typeof isOpen === 'string' && isOpen.startsWith(dir.path)}>
                    <DirAndSubdir dirs={dir.contents}/>
                  </Collapse>
                </div>
                :
                <Link to={"/file-manager?path="+dir.path} className="d-flex align-items-center">
                  <i className="mdi mdi-folder font-size-16 text-warning me-2"></i>{" "}
                  <span className="me-auto" style={{maxWidth: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", textDecoration: (dir.path==params.get("path") ? "underline" : "none")}}>{dir.basename}</span>
                </Link>
              }
            </li>
          }
        })
      }
      </ul>
  )
}

FileRightBar.propTypes = {
  filesTraning: PropTypes.any,
  onGetFilesTraning: PropTypes.func,
};

DirAndSubdir.propTypes = {
  dirs: PropTypes.array
};

const mapStateToProps = ({ filesTraning }) => ({
  filesTraning: filesTraning.filesTraning,
});

const mapDispatchToProps = dispatch => ({
  onGetFilesTraning: setFilesLoaded => dispatch(getFilesTraning(setFilesLoaded)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FileRightBar));

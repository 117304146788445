import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react"
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import "toastr/build/toastr.min.css";
import classnames from "classnames";
import { size } from "lodash";
import  localforage from "localforage";
/**
 * Componente per la lista delle condizioni di vendita. Viene creata la lista delle varie sessioni delle condizoni con i radio button corrispondenti
 * @param {props} props Parametri da passare al componente
 * @param {array} filterQuery Query per il firltraggio delle condizioni di vendita
 * @param {array} condizioniPreset Condizioni preselezionate in fase di caricamento del componente
 * @param {function} setCondizioni Funzione per il cambiamento di stato delle condizioni di vendita
 * @param {function} setAllConditionsSelected Funzione per impostare lo stato booleano per indicare che tutte le condizioni sono state selezionate
 * @param {boolean} changedLocalStorage Booleano che indica se il localStorage è stato modificato o meno
 * @param {function} setChangedLocalStorage Funziona per modificare lo stato di changedLocalStorage
 * @returns Componente
 */
const CondizioniDiVenditaList = props => {
  Date.prototype.toDateInputValue = (function() {
    var local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0,10);
  });
  /**
   * Lista delle condizioni di vendita
   * @type {array}
   */
  const [conditionsList, setConditionsList] = React.useState([]);
  /**
   * Id della tab attiva
   * @type {number}
   */
  const [verticalActiveTab, setVerticalActiveTab] = React.useState(0);
  /**
   * Condizioni di vendita scelte dall'utente
   * @type {object}
   */
  const [conditionChoise, setConditionChoise] = React.useState(props.condizioniPreset);
  /**
   * Spedizione scelta dall'utente
   * @type {object}
   */
  const [spedizioneChoise, setSpedizioneChoise] = React.useState(JSON.parse(localStorage.getItem("ht-order")).spedizione ? JSON.parse(localStorage.getItem("ht-order")).spedizione : {});
  /**
   * Consegna entro scelta dall'utente
   * @type {object}
   */
  let consegnaTemp = {};
  let condTemp = conditionChoise.filter(item => item.idCondizione === 1 && item.valore.id === "consegna-entro").length>0 ? conditionChoise.filter(item => item.idCondizione === 1 && item.valore.id === "consegna-entro")[0] : null;
  consegnaTemp.date = condTemp ? condTemp.valore.data : new Date().toDateInputValue();
  consegnaTemp.tassativa = condTemp ? condTemp.valore.tassativa : false;
  consegnaTemp.nuovoMese = condTemp ? condTemp.valore.nuovoMese : false;
  const [consegnaEntro, setConsegnaEntro] = React.useState(consegnaTemp);
  /**
   * Indica se l'indirizzo di spedizione è unico o ce ne sono più di uno
   * @type {boolean}
   */
  const [isSingleSpedizione, setIsSingleSpedizione] = React.useState(false);

  /**
   * Funzione per la modifica della tab selezionata
   * @param {number} tab Indica l'id della tab da cambiare
   */
  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setVerticalActiveTab(tab);
    }
  };

  useEffect(() => {
    if(JSON.parse(localStorage.getItem("ht-order")).cliente.anagrafica_spedizione.length===1 && !isSingleSpedizione){
      setIsSingleSpedizione(true)
      setSpedizioneChoise(JSON.parse(localStorage.getItem("ht-order")).cliente.anagrafica_spedizione[0]);
    } else {
      let ord = JSON.parse(localStorage.getItem("ht-order"));
      ord.spedizione = spedizioneChoise;
      localStorage.setItem("ht-order", JSON.stringify(ord));
    }
  }, [spedizioneChoise, isSingleSpedizione])

  useEffect(() => {
    localforage.getItem("conditions").then(function (conditions) {
      let filterQuery = "";
      if(JSON.parse(localStorage.getItem("ht-order")) && JSON.parse(localStorage.getItem("ht-order")).type === "Macchinario") {
        filterQuery = "MA-";
      } else {
        filterQuery = "MC-";
      }
      if(JSON.parse(localStorage.getItem("ht-order")) && JSON.parse(localStorage.getItem("ht-order")).proposta === 0) {
        filterQuery = filterQuery+"OF";
      } else {
        filterQuery = filterQuery+"PO";
      }
      let cond = [];
      if(filterQuery !== "") {
        conditions.forEach(conditionGroup => {
          if(conditionGroup.is_payment_method === 0){
            let condizioniTemp = conditionGroup.condizioni.filter(condition => {
              return condition.ref.includes(filterQuery);
            });
            conditionGroup.condizioni = condizioniTemp;
            if(size(conditionGroup.condizioni) > 0) {
              cond.push(conditionGroup);
            }
          }
        });
      }
      cond.sort(function(a, b) {
        return compareStrings(a.categoria, b.categoria);
      })
      setVerticalActiveTab(cond.length > 0 ? cond[0].id : null);
      props.setAllConditionsSelected(conditionChoise.length === cond.length);
      setConditionsList(cond);
    });
  }, [])

  /**
   * Questa funzione compara due stringhe per l'ordinamento
   * @param {string} a prima stringa confrontata
   * @param {string} b seconda stringa confrontata
   * @returns {number} -1, 0, 1 se il primo paramentro è minore del secondo, uguale o maggiore
   */
  function compareStrings(a, b) {
    // Assuming you want case-insensitive comparison
    a = a.toLowerCase();
    b = b.toLowerCase();
  
    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }

  /**
   * Funzione per il cambiamento delle condizioni di vendita
   * @param {*} name Nome della condizione
   * @param {*} keyNome Id della condizione di vendita
   * @param {*} value Varore selezionato
   */
  const changeCondition = (name, keyNome, value) => {
    let conditionChoiseTemp = JSON.parse(JSON.stringify(conditionChoise));
    conditionChoiseTemp = conditionChoiseTemp.filter(item => item.idCondizione !== keyNome);
    conditionChoiseTemp.push(
      {
        "condizione": name,
        "idCondizione": keyNome,
        "valore": value
      }
    )
    let orderTemp = JSON.parse(localStorage.getItem("ht-order"));
    orderTemp.condizioni = conditionChoiseTemp;
    localStorage.setItem("ht-order", JSON.stringify(orderTemp));
    props.setChangedLocalStorage(!props.changedLocalStorage);
    console.log(conditionChoiseTemp, conditionsList);
    props.setAllConditionsSelected(conditionChoiseTemp.length === conditionsList.length);
    props.setCondizioni(conditionChoiseTemp);
    let idConditionList = conditionsList.map(condition => condition.id);
    if(idConditionList.indexOf(verticalActiveTab)+1 < idConditionList.length && value.id !== "consegna-entro"){
      toggleVertical(idConditionList[idConditionList.indexOf(verticalActiveTab)+1]);
    } else if(localStorage.getItem("ht-order") && JSON.parse(localStorage.getItem("ht-order")). proposta === 1 && value.id !== "consegna-entro"){
      toggleVertical("spedizione");
    }
    setConditionChoise(conditionChoiseTemp);
  }

  return (
    <>
      <Row>
        <Col md="3">
          <Nav pills className="flex-column ht_elipse_nav">
            {
              conditionsList.map(condition => 
                <NavItem key={"nav"+condition.id}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === condition.id,
                    })}
                    onClick={() => {
                      toggleVertical(condition.id);
                    }}
                  >
                    {conditionChoise.filter(item => item.idCondizione === condition.id).length>0 ? <i className="bx bx-check" style={{color: "#34c38f", borderRadius: "50%",background: "white", padding:"2px"}}></i> : <i className="bx bx-edit-alt" style={{color: "#f1b44c", borderRadius: "50%",background: "white", padding:"2px"}}></i>}{" "}
                    {condition.categoria}
                  </NavLink>
                </NavItem>
              )
            }
            {localStorage.getItem("ht-order") && JSON.parse(localStorage.getItem("ht-order")). proposta === 1 && 
              <NavItem key={"navSpedizione"}>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "mb-2": true,
                    active: verticalActiveTab === "spedizione",
                  })}
                  onClick={() => {
                    toggleVertical("spedizione");
                  }}
                >
                  {typeof spedizioneChoise.Indirizzo !== "undefined" ? <i className="bx bx-check" style={{color: "#34c38f", borderRadius: "50%",background: "white", padding:"2px"}}></i> : <i className="bx bx-edit-alt" style={{color: "#f1b44c", borderRadius: "50%",background: "white", padding:"2px"}}></i>}{" "}
                  Indirizzo di spedizione
                </NavLink>
              </NavItem>
            }
          </Nav>
        </Col>
        <Col md="9" className="ht_line_left">
          <TabContent
            activeTab={verticalActiveTab}
            className="text-muted mt-4 mt-md-0"
          >
            {
              conditionsList.map(condition => 
                <TabPane key={"tab"+condition.id} tabId={condition.id}>
                  <h5 className="mb-4">{condition.categoria}</h5>
                  <ul className="ht_list_tab">
                    {
                      condition.condizioni.map(singleCondition => 
                        <li key={singleCondition.id} onClick={() => changeCondition(condition.categoria, condition.id, singleCondition)}>
                          <input type="radio" id={singleCondition.id} checked={conditionChoise.filter(item => item.idCondizione === condition.id && item.valore.id === singleCondition.id).length>0} value={singleCondition.id} name={condition.categoria.split(' ').join('_').replace(/[^a-zA-Z0-9_]/g, "").toLowerCase()} onChange={(e) => changeCondition(condition.categoria, condition.id, singleCondition)}/>
                          <label dangerouslySetInnerHTML={{ __html: singleCondition.descrizione }} ></label>
                        </li>
                      )
                    }
                    {
                      condition.categoria === "Consegna" && JSON.parse(localStorage.getItem("ht-order")) && JSON.parse(localStorage.getItem("ht-order")).type !== "Macchinario" &&
                      <li onClick={() => {
                        if(consegnaEntro.date){
                          changeCondition(condition.categoria, condition.id, {descrizione: "<p>Consegna entro il "+new Intl.DateTimeFormat('it-IT', { 
                            month: '2-digit', 
                            day: '2-digit',
                            year: 'numeric', 
                          }).format(new Date(consegnaEntro.date.replace(/-/g, "/")))+(consegnaEntro.tassativa ? " - Tassativa" : "")+(consegnaEntro.nuovoMese ? " - Nuovo mese" : "")+"</p>", id: "consegna-entro", nome: "Consegna entro", data: consegnaEntro.date, tassativa: consegnaEntro.tassativa, nuovoMese: consegnaEntro.nuovoMese})
                        }
                      }}>
                        <input type="radio" id="ht-1" checked={conditionChoise.filter(item => item.idCondizione === 1 && item.valore.id === "consegna-entro").length>0} value={"ht-1"} name="ht-1" onChange={(e) => changeCondition(condition.categoria, condition.id, {descrizione: "<p>Consegna entro il "+new Intl.DateTimeFormat('it-IT', { 
                            month: '2-digit', 
                            day: '2-digit',
                            year: 'numeric', 
                          }).format(new Date(consegnaEntro.date.replace(/-/g, "/")))+(consegnaEntro.tassativa ? " - Tassativa" : "")+(consegnaEntro.nuovoMese ? " - Nuovo mese" : "")+"</p>", id: "consegna-entro", nome: "Consegna entro", data: consegnaEntro.date, tassativa: consegnaEntro.tassativa, nuovoMese: consegnaEntro.nuovoMese })}/>
                        <label >Consegna entro il <input
                            className="form-control"
                            type="date"
                            value={consegnaEntro.date}
                            onChange = {(e) => {
                              changeCondition(condition.categoria, condition.id, {descrizione: "<p>Consegna entro il "+ new Intl.DateTimeFormat('it-IT', { 
                                month: '2-digit', 
                                day: '2-digit',
                                year: 'numeric', 
                              }).format(new Date(e.target.value.replace(/-/g, "/")))+(consegnaEntro.tassativa ? " - Tassativa" : "")+(consegnaEntro.nuovoMese ? " - Nuovo mese" : "")+"</p>", id: "consegna-entro", nome: "Consegna entro", data: e.target.value, tassativa: consegnaEntro.tassativa, nuovoMese: consegnaEntro.nuovoMese });
                              let tempCons = consegnaEntro;
                              tempCons.date = e.target.value;
                              setConsegnaEntro(tempCons);
                            } }
                            id="ht-consegna-entro"
                          />
                          <br/>
                          <label>
                            <input type="checkbox" checked={consegnaEntro.tassativa}
                              onClick={(e) => {
                                let tempCons = consegnaEntro;
                                tempCons.tassativa = !tempCons.tassativa;
                                setConsegnaEntro(tempCons);
                                changeCondition(condition.categoria, condition.id, {descrizione: "<p>Consegna entro il "+new Intl.DateTimeFormat('it-IT', { 
                                  month: '2-digit', 
                                  day: '2-digit',
                                  year: 'numeric', 
                                }).format(new Date(consegnaEntro.date.replace(/-/g, "/")))+(tempCons.tassativa ? " - Tassativa" : "")+(consegnaEntro.nuovoMese ? " - Nuovo mese" : "")+"</p>", id: "consegna-entro", nome: "Consegna entro", data: consegnaEntro.date, tassativa: tempCons.tassativa, nuovoMese: consegnaEntro.nuovoMese })
                              }}
                            /> Tassativa
                          </label>
                          <span style={{"width": "30px", "display": "inline-block"}}/>
                          <label >
                            <input type="checkbox" value={consegnaEntro.nuovoMese}
                              onClick={(e) => {
                                let tempCons = consegnaEntro;
                                tempCons.nuovoMese = !tempCons.nuovoMese;
                                setConsegnaEntro(tempCons);
                                changeCondition(condition.categoria, condition.id, {descrizione: "<p>Consegna entro il "+new Intl.DateTimeFormat('it-IT', { 
                                  month: '2-digit', 
                                  day: '2-digit',
                                  year: 'numeric', 
                                }).format(new Date(consegnaEntro.date.replace(/-/g, "/")))+(consegnaEntro.tassativa ? " - Tassativa" : "")+(tempCons.nuovoMese ? " - Nuovo mese" : "")+"</p>", id: "consegna-entro", nome: "Consegna entro", data: consegnaEntro.date, tassativa: consegnaEntro.tassativa, nuovoMese: tempCons.nuovoMese })
                              }}
                            /> Nuovo mese
                          </label>
                        </label>
                      </li>
                    }
                  </ul>
                </TabPane>
              )
            }
            {localStorage.getItem("ht-order") && JSON.parse(localStorage.getItem("ht-order")). proposta === 1 && 
            <TabPane key={"tabSpedizione"} tabId="spedizione">
              <h5 className="mb-4">Indirizzo di spedizione</h5>
              <ul className="ht_list_tab">
                {
                  JSON.parse(localStorage.getItem("ht-order")).cliente.anagrafica_spedizione.map((spedizione, key) => 
                    <li key={key} onClick={() => setSpedizioneChoise(spedizione)}>
                      <input type="radio" id={key} checked={JSON.parse(localStorage.getItem("ht-order")).cliente.anagrafica_spedizione.length===1 || JSON.stringify(spedizioneChoise)===JSON.stringify(spedizione)} value={key} name="ht-spedizione" onChange={(e) => setSpedizioneChoise(spedizione)}/>
                      <label dangerouslySetInnerHTML={{ __html: (spedizione.Descrizione ? ("<strong>"+spedizione.Descrizione+"</strong><br/>") : "")+spedizione.Indirizzo+"<br/>("+spedizione.Cap+") "+spedizione.Localita+" ("+spedizione.Provincia+")" }} ></label>
                    </li>
                  )
                }
              </ul>
            </TabPane>}
          </TabContent>
        </Col>
      </Row>
    </>
  )
}

export default CondizioniDiVenditaList

CondizioniDiVenditaList.propTypes = {
  filterQuery: PropTypes.array,
  condizioniPreset: PropTypes.array,
  setCondizioni: PropTypes.func,
  setAllConditionsSelected: PropTypes.func,
  changedLocalStorage: PropTypes.bool,
  setChangedLocalStorage: PropTypes.func,
};
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { withRouter, useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

//redux
import { useDispatch } from "react-redux";

import { withTranslation } from "react-i18next";

import RouteChoice from "components/Heytech/RouteChoice";
import ClientList from "components/Heytech/ClientList";
import Swal from "sweetalert2";
import  * as url from "helpers/url_helper";
import { post } from "helpers/api_helper";

const CreaOrdine = props => {
  const history = useHistory();

  const [selectedRoute, setSelectedRoute] = React.useState(localStorage.getItem("ht-order") && JSON.parse(localStorage.getItem("ht-order")).duplicate == 1 ? JSON.parse(localStorage.getItem("ht-order")).type : "");
  const [selectedClient, setSelectedClient] = React.useState(localStorage.getItem("ht-temp-customer") ? parseInt(localStorage.getItem("ht-temp-customer")) : null);
  const [clientData, setClientData] = React.useState(null);

  useEffect(() => {
    if(!localStorage.getItem("ht-order") || JSON.parse(localStorage.getItem("ht-order")).duplicate !== 1){
      localStorage.removeItem("ht-order");
      localStorage.removeItem("tags");
    }
    localStorage.removeItem("ht-orderTemp");
    localStorage.removeItem("ht-dettaglio-archivio");
  }, [])

  useEffect(() => {
    if(localStorage.getItem("ht-temp-customer")){
      localStorage.removeItem("ht-temp-customer");
    }
  }, [selectedClient])

  const clickNext = () => {
    if(clientData.anagrafica_qualifica !== "Cliente"){
      Swal.fire({
        title: "Invio modulo dati anagrafici",
        html: "Hai selezionato un cliente potenziale, l'ordine sarà evaso al ricevimento del modulo dati anagrafici compilato.<br/>Vuoi inviare il modulo alla seguente e-mail?",
        input: 'email',
        inputValue: clientData.anagrafica_email ? clientData.anagrafica_email : "",
        inputAttributes: {
          autocapitalize: 'off',
          required: true,
          placeholder: "Inserire l'e-mail"
        },
        showCancelButton: true,
        cancelButtonText: 'Continua e non inviare il modulo',
        confirmButtonText: 'Invia il modulo',
        confirmButtonColor: '#003a5d',
        showLoaderOnConfirm: true,
        preConfirm: (email) => {
          let body = {id:clientData.anagrafica_id,email:email};
          return post(url.SEND_MODULO_ANAGRAFICA, body).then(response => {
            if (!response.email_success) {
              throw new Error(response.email_err)
            }
            return response
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Errore: ${error}`
            )
          })          
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Modulo inviato con successo',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#003A5D',
            confirmButtonText: 'Ok',
            allowOutsideClick: false
          }).then((result) => {
            if (result.value) {
              realNext();
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          realNext();
        }
      })
    } else {
      realNext();
    }
  }

  const realNext = () => {
    if(!localStorage.getItem("ht-order") || JSON.parse(localStorage.getItem("ht-order")).duplicate !== 1){
      clientData.codice = clientData.anagrafica_codice;
      var order = {
        "cliente": clientData,
        "type": selectedRoute,
        "agente": {"userName": JSON.parse(localStorage.getItem("authUser")).name},
        "proposta": 1,
        "order": []
      };
      localStorage.setItem("ht-order", JSON.stringify(order));
      localStorage.removeItem("ht-orderTemp");
      if(selectedRoute === "Macchinario") {
        history.push("/nuovo-ordine/macchinario/scelta-macchina");
      } else {
        history.push("/nuovo-ordine/materialediconsumo/scelta");
      }
    } else {
      clientData.codice = clientData.anagrafica_codice;
      var order = JSON.parse(localStorage.getItem("ht-order"));
      order.cliente = clientData;
      delete order.duplicate;
      localStorage.setItem("ht-order", JSON.stringify(order));
      if(!order.condizioni){
        history.push((order.proposta === 1 ? "/nuovo-ordine" : "/nuova-offerta")+(order.type === "Macchinario" ? "/macchinario" : "/materialediconsumo")+"/scelta-condizionidivendita");
      } else if(order.condizioni && !order.pagamenti){
        history.push((order.proposta === 1 ? "/nuovo-ordine" : "/nuova-offerta")+(order.type === "Macchinario" ? "/macchinario" : "/materialediconsumo")+"/scelta-condizionidivendita");
      } else if(order.condizioni && order.pagamenti){
        history.push((order.proposta === 1 ? "/nuovo-ordine" : "/nuova-offerta")+(order.type === "Macchinario" ? "/macchinario" : "/materialediconsumo")+"/riepilogo");
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Crea Ordine | Gruppo Bisaro Cloud</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Attività" breadcrumbItem="Crea Ordine" />
          <Row>
            {/* <Col md="12">
              <Card>
                <CardBody>
                  <ProgressBar numberBable={6} progressValue={progressValue}/>
                  <div className="d-flex justify-content-end pt-4">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={selectedRoute==="" || selectedClient=== null}
                    >
                      Avanti{" "}
                      <i className="bx bx-cookie font-size-16 align-middle"></i>
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
            <RouteChoice setSelectedRoute={setSelectedRoute} selectedRoute={selectedRoute} isOrder={true}/>
            <Col md="12">
              <Card>
                <CardBody>
                  <ClientList
                    title={<h5 className="mb-0">Seleziona un cliente</h5>}
                    selectedClient={selectedClient}
                    setSelectedClient={setSelectedClient}
                    setClientData={setClientData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {selectedRoute!=="" && selectedClient!== null && <button
            type="button"
            className="btn btn-primary floating-btn-bottom-right"
            onClick={clickNext}
          >
            Avanti{" "}
            <i className="bx bx-cookie align-middle"></i>
          </button>}
        </Container>
      </div>
    </React.Fragment>
  )
}

CreaOrdine.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
}

export default withRouter(withTranslation()(CreaOrdine));
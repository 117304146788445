import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import contactsSaga from "./contacts/saga";
import productsSaga from "./products/saga";
import archivioSaga from "./archivio/saga";
import tagsSaga from "./tags/saga";
import pdfSaga from "./pdf/saga";
import pdfOrderSaga from "./pdfOrder/saga";
import conditionsSaga from "./conditions/saga";
import warrantySaga from "./warranty/saga";
import verifyToketSaga from "./verifyToket/saga";
import setDraftSaga from "./saveDraft/saga";
import setOrderSaga from "./saveOrder/saga";
import editOrderSaga from "./editOrder/saga";
import deleteOrderSaga from "./deleteOrder/saga";
import filesTraningSaga from "./filesTraning/saga";
import fileTraningDownloadSaga from "./fileTraningDownload/saga";
import setTagOrderSaga from "./saveTagsInOrder/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(contactsSaga),
    fork(productsSaga),
    fork(pdfSaga),
    fork(conditionsSaga),
    fork(warrantySaga),
    fork(verifyToketSaga),
    fork(setDraftSaga),
    fork(setOrderSaga),
    fork(setTagOrderSaga),
    fork(archivioSaga),
    fork(tagsSaga),
    fork(editOrderSaga),
    fork(deleteOrderSaga),
    fork(pdfOrderSaga),
    fork(filesTraningSaga),
    fork(fileTraningDownloadSaga),
  ])
}

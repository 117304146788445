import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//redux
import { withTranslation } from "react-i18next";

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import ClientList from "components/Heytech/ClientList";

const ContactsList = props => {
  const [selectedClient, setSelectedClient] = React.useState(null);
  const [clientData, setClientData] = React.useState(null);

  localStorage.removeItem("ht-order");
  localStorage.removeItem("tags");
  localStorage.removeItem("ht-orderTemp");

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Lista clienti | Gruppo Bisaro Cloud</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Attività" breadcrumbItem="Lista clienti" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ClientList 
                    selectedClient={selectedClient}
                    setSelectedClient={setSelectedClient}
                    setClientData={setClientData}
                    withCreateBtn
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ContactsList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ContactsList));
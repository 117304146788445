import PropTypes from "prop-types";
import React from "react"
import {
  Col,
  Card,
  CardBody
} from "reactstrap";
import macchinarioLogo from "assets/images/macchinarioLogo.png";
import materialediconsumoLogo from "assets/images/materialediconsumoLogo.png";

const RouteChoice = props => {

  return (
    <>
      <Col md="6">
        <Card>
          <CardBody>
            <button
              type="button"
              className={props.selectedRoute === "Macchinario" ? "btn btn-primary  btn-label btnChoiseType active" : "btn btn-light  btn-label btnChoiseType"}
              onClick={() => {if(!localStorage.getItem("ht-order") || JSON.parse(localStorage.getItem("ht-order")).duplicate !== 1) props.setSelectedRoute("Macchinario")}}
            >
              {navigator.onLine && <img src={macchinarioLogo} className="logoBtn label-icon"/>} Crea {props.isOrder ? "un ordine" : "un'offerta"} <strong>Macchina</strong>
            </button>
          </CardBody>
        </Card>
      </Col>
      <Col md="6">
        <Card>
          <CardBody>
            <button
              type="button"
              className={props.selectedRoute === "Materiale di consumo" ? "btn btn-primary  btn-label btnChoiseType active" : "btn btn-light  btn-label btnChoiseType"}
              onClick={() => {if(!localStorage.getItem("ht-order") || JSON.parse(localStorage.getItem("ht-order")).duplicate !== 1) props.setSelectedRoute("Materiale di consumo")}}
            >
              {navigator.onLine && <img src={materialediconsumoLogo} className="logoBtn label-icon"/>} Crea {props.isOrder ? "un ordine" : "un'offerta"} <strong>Materiale di consumo</strong>
            </button>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default RouteChoice

RouteChoice.propTypes = {
  setSelectedRoute: PropTypes.func,
  selectedRoute: PropTypes.string,
  isOrder: PropTypes.bool,
};
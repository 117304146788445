import {
  GET_CONDITIONS,
  GET_CONDITIONS_FAIL,
  GET_CONDITIONS_SUCCESS,
} from "./actionTypes"

export const getConditions = setConditionsLoaded => ({
  type: GET_CONDITIONS,
  payload: setConditionsLoaded
})

export const getConditionsSuccess = conditions => ({
  type: GET_CONDITIONS_SUCCESS,
  payload: conditions,
})

export const getConditionsFail = error => ({
  type: GET_CONDITIONS_FAIL,
  payload: error,
})
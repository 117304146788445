import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { SET_ORDER } from "./actionTypes"

import {
  setOrderSuccess,
  setOrderFail
} from "./actions"

//Include Both Helper File with needed methods
import { setOrder } from "../../helpers/backend_helper"

function* fetchSaveOrder({ payload: {setOrderLoaded, data} }) {
  try {
    const response = yield call(setOrder, data);
    yield put(setOrderSuccess(response));
    setOrderLoaded(response);
  } catch (error) {
    yield put(setOrderFail(error));
    setOrderLoaded(0);
  }
}

function* setOrderSaga() {
  yield takeEvery(SET_ORDER, fetchSaveOrder)
}

export default setOrderSaga;

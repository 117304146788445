import PropTypes from "prop-types";
import React, { useEffect } from "react"
//redux
import { useDispatch } from "react-redux";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {Spinner} from 'reactstrap';

import {
  setDraft
} from "store/saveDraft/actions";
import Swal from 'sweetalert2';

import uuid from 'react-uuid';


const SaveBozzaBtn = props => {
  const dispatch = useDispatch();
  const [draftLoaded, setDraftLoaded] = React.useState(false);
  const [draftData, setDraftData] = React.useState(false);
  const [draftLocalId, setDraftLocalId] = React.useState(false);

  useEffect(() => {
    if(draftData && draftData.id){
      if(draftLocalId && draftLocalId > 0){
        let ordersLocal = JSON.parse(localStorage.getItem("ht-save-offline"));
        ordersLocal = ordersLocal.filter((o) => o.offlineId !== draftLocalId);
        if(ordersLocal.length > 0 ){
          localStorage.setItem("ht-save-offline", JSON.stringify(ordersLocal));
        } else {
          localStorage.removeItem("ht-save-offline");
        }
      }
      let order = JSON.parse(localStorage.getItem("ht-order"));
      order.was_draft = draftData.id;
      localStorage.setItem("ht-order", JSON.stringify(order));
      toastr.options = {
        positionClass: "toast-bottom-full-width",
        closeButton: true,
        preventDuplicates: true,
      };
      toastr.success("La bozza è stata salvata con successo", "Bozza salvata con successo");
      setDraftLoaded(false);
    } else if (draftData === 0){
      Swal.fire({
        title: 'Ooops! Qualcosa è andato storto.',
        html: 'Verificare che tutti i dati siano corretti!',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#003A5D',
        confirmButtonText: 'Ok',
        allowOutsideClick: false
      }).then((result) => {
        setDraftLoaded(false);
      });
    }
  }, [draftData])

  return (
    <div className="btn btn-light"
      onClick={() => {
        let order = JSON.parse(localStorage.getItem('ht-order'));
        order.cliente.codice = order.cliente.anagrafica_codice;
        let callData = {
          "data": order
        }
        if( typeof order.was_draft !== "undefined" && order.was_draft > 0 ){
          callData.was_draft = order.was_draft;
        }
        if(localStorage.getItem("tags")){
          callData.tags = JSON.parse(localStorage.getItem("tags"));
        }
        if(navigator.onLine){
          if(typeof order.offlineId !== "undefined" && order.offlineId > 0){
            setDraftLocalId(order.offlineId);
            delete order["offlineId"];
            callData.data = order;
            localStorage.setItem("ht-order", JSON.stringify(order));
          }
          dispatch(setDraft(setDraftData, callData));
          setDraftLoaded(true);
        } else {
          let save_offline = localStorage.getItem('ht-save-offline') ? JSON.parse(localStorage.getItem('ht-save-offline')) : [];
          if(typeof order.offlineId !== "undefined" && order.offlineId > 0){
            save_offline = save_offline.filter((item) => {
              return item.offlineId !== order.offlineId;
            });
          }
          callData.offlineId = Date.now();
          callData.offLineStatus = "Bozza";
          order.offlineId = callData.offlineId;
          localStorage.setItem('ht-order', JSON.stringify(order));
          save_offline.push(callData);
          
          localStorage.setItem('ht-save-offline', JSON.stringify(save_offline));
          toastr.options = {
            positionClass: "toast-bottom-full-width",
            closeButton: true,
            preventDuplicates: true,
          };
          toastr.warning("La bozza è stata salvata con successo. Ricordarsi di aggiornare i dati quando si è on-line.", "Bozza salvata con successo in locale.");
        }
      }}
      disabled = {draftLoaded === true || props.disabled}
    >{!(draftLoaded === true) ?  "Salva bozza" : <Spinner
        color="primary"
        style={{"width": "15px", "height": "15px"}}
      >
        Loading...
      </Spinner>}
    </div>
  )
}

export default SaveBozzaBtn

SaveBozzaBtn.propTypes = {
  disabled: PropTypes.bool
};
import {
  SET_DRAFT,
  SET_DRAFT_FAIL,
  SET_DRAFT_SUCCESS,
} from "./actionTypes"

export const setDraft = (setDraftLoaded, data) => ({
  type: SET_DRAFT,
  payload: {setDraftLoaded, data},
})

export const setDraftSuccess = result => {
  return ({
    type: SET_DRAFT_SUCCESS,
    payload: result,
  })
}

export const setDraftFail = error => ({
  type: SET_DRAFT_FAIL,
  payload: error,
})
import axios from "axios"

//apply base url for axios
const API_URL = "https://adm.bisaro.it/api"

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  let token = localStorage.getItem("authUser") && typeof JSON.parse(localStorage.getItem("authUser")).api_token !== "undefined"  ? JSON.parse(localStorage.getItem("authUser")).api_token : "";
  if(token !== ""){
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  let token = localStorage.getItem("authUser") && typeof JSON.parse(localStorage.getItem("authUser")).api_token !== "undefined"  ? JSON.parse(localStorage.getItem("authUser")).api_token : "";
  if(token !== ""){
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  let token = localStorage.getItem("authUser") && typeof JSON.parse(localStorage.getItem("authUser")).api_token !== "undefined"  ? JSON.parse(localStorage.getItem("authUser")).api_token : "";
  if(token !== ""){
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  let token = localStorage.getItem("authUser") && typeof JSON.parse(localStorage.getItem("authUser")).api_token !== "undefined"  ? JSON.parse(localStorage.getItem("authUser")).api_token : "";
  if(token !== ""){
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

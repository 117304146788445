import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess } from "./actions"

//Include Both Helper File with needed methods
//import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postLogin,
} from "../../../helpers/backend_helper"

import localForage from 'localforage';

//const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, setLoggedIn, setLoading } }) {
  try {
    const response = yield call(postLogin, {
      email: user.email,
      password: user.password,
    })
    localStorage.setItem("authUser", JSON.stringify(response));
    yield put(loginSuccess(response))
    setLoggedIn(true);
  } catch (error) {
    yield put(apiError(error));
    setLoggedIn("userError");
    setLoading(false);
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localForage.removeItem("conditions");
    localForage.removeItem("clienti_diretti");
    localForage.removeItem("clienti_sotto_agenti");
    localStorage.clear();
    window.location.href = "/login";
  } catch (error) {
    yield put(apiError(error))
  }
}

/* function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type,
      )
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
} */

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  /* yield takeLatest(SOCIAL_LOGIN, socialLogin) */
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga

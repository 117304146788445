import {
  SET_TAGORDER,
  SET_TAGORDER_FAIL,
  SET_TAGORDER_SUCCESS,
} from "./actionTypes"

export const setTagOrder = (setTagOrderLoaded, data) => ({
  type: SET_TAGORDER,
  payload: {setTagOrderLoaded, data},
})

export const setTagOrderSuccess = result => {
  return ({
    type: SET_TAGORDER_SUCCESS,
    payload: result,
  })
}

export const setTagOrderFail = error => ({
  type: SET_TAGORDER_FAIL,
  payload: error,
})
import {
  EDIT_ORDER,
  EDIT_ORDER_FAIL,
  EDIT_ORDER_SUCCESS,
} from "./actionTypes"

export const editOrder = (editOrderLoaded, data) => ({
  type: EDIT_ORDER,
  payload: {editOrderLoaded, data},
})

export const editOrderSuccess = result => {
  return ({
    type: EDIT_ORDER_SUCCESS,
    payload: result,
  })
}

export const editOrderFail = error => ({
  type: EDIT_ORDER_FAIL,
  payload: error,
})
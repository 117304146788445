import {
  DELETE_ORDER,
  DELETE_ORDER_FAIL,
  DELETE_ORDER_SUCCESS,
} from "./actionTypes"

export const deleteOrder = (deleteOrderLoaded, data) => ({
  type: DELETE_ORDER,
  payload: {deleteOrderLoaded, data},
})

export const deleteOrderSuccess = result => {
  return ({
    type: DELETE_ORDER_SUCCESS,
    payload: result,
  })
}

export const deleteOrderFail = error => ({
  type: DELETE_ORDER_FAIL,
  payload: error,
})
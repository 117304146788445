import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import MetaTags from 'react-meta-tags';
import { Link, withRouter } from "react-router-dom";
import { Col, Container, Form, Row, Input, Label, FormFeedback, Spinner } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import CarouselPage from "./CarouselPage";
import {loginUser} from "../../store/actions";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  getUsers
} from "store/contacts/actions";
import {
  getProducts
} from "store/products/actions";

import {
  getConditions
} from "store/conditions/actions";

import {
  getWarranty
} from "store/warranty/actions";

const Login = props => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [customersLoaded, setCustomersLoaded] = useState(false);
  const [productsLoaded, setProductsLoaded] = useState(false);
  const [conditionsLoaded, setConditionsLoaded] = useState(false);
  const [warrantyLoaded, setWarrantyLoaded] = useState(false);
  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Inserire il proprio nome utente"),
      password: Yup.string().required("Inserire la password"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      dispatch(loginUser(values, setLoggedIn, setLoading));
    }
  });

  useEffect(() => {
    if (customersLoaded && productsLoaded && conditionsLoaded && warrantyLoaded) {
      setTimeout(function () {
        localStorage.setItem("lastUpdate", Date.now());
        props.history.push("/");
      }, 1000);
    }
  }, [customersLoaded, productsLoaded, conditionsLoaded, warrantyLoaded]);

  useEffect(() => {
    if (loggedIn && loggedIn !== "userError") {
      dispatch(getUsers(setCustomersLoaded));
      dispatch(getProducts(setProductsLoaded));
      dispatch(getConditions(setConditionsLoaded));
      dispatch(getWarranty(setWarrantyLoaded));
    } else if(loggedIn === "userError") {
      toastr.options = {
        positionClass: "toast-top-right",
        closeButton: true,
        preventDuplicates: false,
      };
      toastr.error("Nome utente o password errati");
      setLoggedIn(false);
    }
  }, [loggedIn]);

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Gruppo Bisaro Cloud</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            
            <Col xl={4}>
              <div className="auth-full-page-content p-md-5 p-4 mw-30">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="30"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="18"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Benvenuto in GB Cloud!</h5>
                        <p className="text-muted">
                          Accedi per continuare.
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >

                          <div className="mb-3">
                            <Label className="form-label">Nome utente</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Nome utente"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type="password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>

                          {/* <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Ricordami
                            </label>
                          </div> */}
                          
                          <p className="text-center">{loading ? "Scaricamento dati in corso..." : <span>&nbsp;</span>}</p>

                          <div className="mt-3 d-grid">
                            {!loading ? <button
                                className="btn btn-primary btn-block "
                                type="submit"
                              >
                                Accedi
                              </button>
                              : <div className="text-center"><Spinner
                                color="primary"
                              >
                                Loading...
                              </Spinner></div>
                            }
                          </div>

                        </Form>

                        {/* <Form action="dashboard">
                          <div className="mt-4 text-center">
                            <h5 className="font-size-14 mb-3">
                              Sign in with
                            </h5>

                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                >
                                  <i className="mdi mdi-facebook"></i>
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-info text-white border-info"
                                >
                                  <i className="mdi mdi-twitter"></i>
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                >
                                  <i className="mdi mdi-google"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Form>
                        <div className="mt-5 text-center">
                          <p>
                            Don&apos;t have an account ?
                            <Link
                              to="pages-register-2"
                              className="fw-medium text-primary"
                            >
                              Signup now
                            </Link>
                          </p>
                        </div>*/}
                      </div>
                    </div> 

                    <div className="mt-4 mt-md-5 text-center d-none d-xl-block">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Gruppo Bisaro. Progettato & Sviluppato da <a className="colorHeytech" target="_blank" rel="noreferrer" href="https://www.heytech.it">Heytech</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage/>
            <div className="mb-4 mb-md-5 mt-4 mt-md-5 text-center d-xl-none">
              <p className="mb-0">
                © {new Date().getFullYear()} Gruppo Bisaro. Progettato & Sviluppato da <a className="colorHeytech" target="_blank" rel="noreferrer" href="https://www.heytech.it">Heytech</a>
              </p>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_TAGS } from "./actionTypes"

import {
  getTagsSuccess,
  getTagsFail
} from "./actions"

//Include Both Helper File with needed methods
import { getTags } from "../../helpers/backend_helper"

function* fetchTags({ payload: setTagsData }) {
  try {
    const response = yield call(getTags)
    yield put(getTagsSuccess(response.tag_list));
    setTagsData(response.tag_list);
  } catch (error) {
    yield put(getTagsFail(error));
    if(error.response.status === 401){
      window.location.replace("/login");
    }
  }
}

function* tagsSaga() {
  yield takeEvery(GET_TAGS, fetchTags)
}

export default tagsSaga;

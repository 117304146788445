import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CONDITIONS } from "./actionTypes"

import {
  getConditionsSuccess,
  getConditionsFail
} from "./actions"

import localForage from 'localforage';

//Include Both Helper File with needed methods
import { getConditions } from "../../helpers/backend_helper"

function* fetchConditions({ payload: setConditionsLoaded }) {
  try {
    const response = yield call(getConditions)
    yield put(getConditionsSuccess(response.data));
    
    localForage.removeItem("conditions");
    localForage.setItem("conditions", response.data);
    setConditionsLoaded(true);
  } catch (error) {
    yield put(getConditionsFail(error));
    setConditionsLoaded(false);
  }
}

function* conditionsSaga() {
  yield takeEvery(GET_CONDITIONS, fetchConditions)
}

export default conditionsSaga;

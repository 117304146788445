import {
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  tags: [],
  userProfile: {},
  error: {},
}

const tags = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload,
      }

    case GET_TAGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default tags

import {
  GET_FILESTRANING,
  GET_FILESTRANING_FAIL,
  GET_FILESTRANING_SUCCESS,
} from "./actionTypes"

export const getFilesTraning = (setFilesLoaded) => ({
  type: GET_FILESTRANING,
  payload: {setFilesLoaded},
})

export const getFilesTraningSuccess = files => ({
  type: GET_FILESTRANING_SUCCESS,
  payload: files,
})

export const getFilesTraningFail = error => ({
  type: GET_FILESTRANING_FAIL,
  payload: error,
})
import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_WARRANTY } from "./actionTypes"

import {
  getWarrantySuccess,
  getWarrantyFail
} from "./actions"

import localForage from 'localforage';

//Include Both Helper File with needed methods
import { getWarranty } from "../../helpers/backend_helper"

function* fetchWarranty({ payload: setWarrantyLoaded }) {
  try {
    const response = yield call(getWarranty)
    yield put(getWarrantySuccess(response.data));
    
    localStorage.removeItem("warranty");
    localStorage.setItem("warranty", JSON.stringify(response.data));
    setWarrantyLoaded(true);
  } catch (error) {
    yield put(getWarrantyFail(error));
    setWarrantyLoaded(false);
  }
}

function* warrantySaga() {
  yield takeEvery(GET_WARRANTY, fetchWarranty)
}

export default warrantySaga;

import {
  GET_WARRANTY,
  GET_WARRANTY_FAIL,
  GET_WARRANTY_SUCCESS,
} from "./actionTypes"

export const getWarranty = setWarrantyLoaded => ({
  type: GET_WARRANTY,
  payload: setWarrantyLoaded
})

export const getWarrantySuccess = warranty => ({
  type: GET_WARRANTY_SUCCESS,
  payload: warranty,
})

export const getWarrantyFail = error => ({
  type: GET_WARRANTY_FAIL,
  payload: error,
})
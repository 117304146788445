import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";

import {Spinner} from 'reactstrap';

import {
  setOrder
} from "store/saveOrder/actions";

import {
  editOrder
} from "store/editOrder/actions";

import {
  setDraft
} from "store/saveDraft/actions";
import { useSelector, useDispatch } from "react-redux";

const SyncOfflineOrderBtn = props => {
  const dispatch = useDispatch();
  const [LoadingSyncLocal, setLoadingSyncLocal] = useState(false);
  const [orderData, setOrderData] = useState(false);

  const onClickSyncOffline = () => {
    let offlineData = localStorage.getItem("ht-save-offline") ? JSON.parse(localStorage.getItem("ht-save-offline")) : [];
    if(offlineData.length > 0) {
      let orderOffline = offlineData[0];
      if(orderOffline.offLineStatus == "Edit"){
        delete orderOffline['offLineStatus'];
        delete orderOffline['offlineId'];
        delete orderOffline.data['offlineId'];
        dispatch(editOrder(setOrderData, orderOffline));
      } else if(orderOffline.offLineStatus == "Order") {
        delete orderOffline['offLineStatus'];
        delete orderOffline['offlineId'];
        delete orderOffline.data['offlineId'];
        dispatch(setOrder(setOrderData, orderOffline));
      } else {
        delete orderOffline['offLineStatus'];
        delete orderOffline['offlineId'];
        delete orderOffline.data['offlineId'];
        dispatch(setDraft(setOrderData, orderOffline));
      }
    }
    setLoadingSyncLocal(offlineData.length > 0);
  }

  useEffect(() => {
    if(LoadingSyncLocal){
      let offlineData = localStorage.getItem("ht-save-offline") ? JSON.parse(localStorage.getItem("ht-save-offline")) : undefined;
      if(typeof offlineData !== "undefined" && offlineData.length > 0) {
        offlineData.shift();
        if(offlineData.length > 0){
          localStorage.setItem("ht-save-offline", JSON.stringify(offlineData));
          let orderOffline = offlineData[0];
          if(orderOffline.offLineStatus == "Edit"){
            delete orderOffline['offLineStatus'];
            delete orderOffline['offlineId'];
            delete orderOffline.data['offlineId'];
            dispatch(editOrder(setOrderData, orderOffline));
          } else if(orderOffline.offLineStatus == "Order") {
            delete orderOffline['offLineStatus'];
            delete orderOffline['offlineId'];
            delete orderOffline.data['offlineId'];
            dispatch(setOrder(setOrderData, orderOffline));
          } else {
            delete orderOffline['offLineStatus'];
            delete orderOffline['offlineId'];
            delete orderOffline.data['offlineId'];
            dispatch(setDraft(setOrderData, orderOffline));
          }
        } else {
          localStorage.removeItem("ht-save-offline");
          setLoadingSyncLocal(false);
          location.reload(true);
        }
      } else if(typeof offlineData !== "undefined"){
        localStorage.removeItem("ht-save-offline");
        setLoadingSyncLocal(false);
        location.reload(true);
      }
      setLoadingSyncLocal(typeof offlineData !== "undefined" && offlineData.length > 0);
    }
  }, [orderData])

  return (
    navigator.onLine && localStorage.getItem("ht-save-offline") && JSON.parse(localStorage.getItem("ht-save-offline")).length > 0 && <>
      {!LoadingSyncLocal ? <button
          type="button"
          onClick={() => onClickSyncOffline()}
          className="btn header-item noti-icon "
        >
          {props.element}
        </button>
      : <button
          type="button"
          className="btn header-item noti-icon "
        >
          <Spinner
            color="primary"
          >
            Loading...
          </Spinner>
        </button>
      }
    </>
  )
}

export default SyncOfflineOrderBtn

SyncOfflineOrderBtn.propTypes = {
  element: PropTypes.any
};
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react"
import {
  Row,
  Col,
  Collapse,
  Badge,
} from "reactstrap";
import classnames from "classnames";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { size } from "lodash";
import CustomSearch from "components/Heytech/CustomSearch";

import ShowPrice from "components/Heytech/ShowPrice";

import localforage from "localforage";
import { fi } from "date-fns/locale";
import Swal from "sweetalert2";

const ProductList = props => {

  const [openCollapse, setOpenCollapse] = React.useState(false);
  const [searchField, setSearchField] = React.useState("");
  const [productsList, setProductsList] = React.useState(false);
  const [productsListFiltered, setProductsListFiltered] = React.useState([]);
  const [productsGroupsList, setProductsGroupsList] = React.useState([]);

  var pQty = props.prodQty;

  useEffect(() => {
    if(props.alwaysOpen){
      let tempProductFiltered = [];
      productsGroupsList.map(item => {
        tempProductFiltered = tempProductFiltered.concat(getProductsFiltered(item.id, searchField, false, true));
      });
      setProductsListFiltered(tempProductFiltered);
    }
  }, [productsGroupsList, productsList, searchField])


  useEffect(() => {
    if(productsList === false){
      localforage.getItem("prodotti").then(function (products) {
        let productsTemp = products;
        if(props.filterQuery){
          productsTemp = products.filter(product => {
            let isInQuery = true;
            props.filterQuery.map((query) => {
              if(!query.value.includes(product[query.key])) isInQuery = false;
            })
            return isInQuery;
          });
        }
        productsTemp.sort(function(a, b) {
          return compareStrings(a.componente_raggruppamenti, b.componente_raggruppamenti);
        })
        var groups = [];
        productsTemp.map(item => {
          if (groups.filter((i) => i.id === item.componente_raggruppamenti_id).length === 0) {
            groups.push({"nome": item.componente_raggruppamenti, "id": item.componente_raggruppamenti_id});
          }
        })
        setProductsGroupsList(groups);
        setProductsList(productsTemp);
      });
    }
  }, [productsList])

  function compareStrings(a, b) {
    // Assuming you want case-insensitive comparison
    a = a.toLowerCase();
    b = b.toLowerCase();
  
    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }

  const getProductsFiltered = (raggruppamenti_id, searchQuery, returnNumber=false, alwaysOpen=false) => {
    var splitSearchQuery = searchQuery.split(" ");
    var tempProdFilter = productsList=== false ? [] : productsList.filter(item => {
      if(searchQuery === "") {
        return item.componente_raggruppamenti_id === raggruppamenti_id;
      } else if(splitSearchQuery.filter(iter => item.componente_nome.toLowerCase().indexOf(iter.toLowerCase()) !== -1).length == splitSearchQuery.length || item.componente_codice.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) {
        return item.componente_raggruppamenti_id === raggruppamenti_id;
      } else false
    })
    if(returnNumber) {
      return tempProdFilter.length;
    } else if(alwaysOpen){
      return tempProdFilter;
    } else {
      setProductsListFiltered(tempProdFilter);
    }
  }

  const sizePerPage = 20;
  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: size(productsListFiltered), // replace later with size(users),
    custom: false,
    hideSizePerPage: true,
    showTotal: true,
  };
  const defaultSorted = [
    {
      dataField: "componente_nome", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  /* const selectRow = {
    mode: "radio",
  }; */

  const productListColumns = [
    {
      text: "Quantità",
      hidden: !props.withQty,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, product) => (
        <div>
          <input
            className="form-control"
            type="number"
            placeholder="Quantità"
            style={{width: "90px"}}
            defaultValue={props.productData.filter(prod => product.componente_codice === prod.componente_codice).length > 0 && props.productData.filter(prod => product.componente_codice === prod.componente_codice)[0].quantity > 0 ? props.productData.filter(prod => product.componente_codice === prod.componente_codice)[0].quantity : 1}
            onClick={e => e.stopPropagation()}
            onChange={e => {
              if(pQty.filter(item => item.code === product.componente_codice).length > 0) {
                let tempPQty = [];
                pQty.forEach(element => {
                  if(product.componente_codice == element.code) {
                    tempPQty = [...tempPQty, {"code": product.componente_codice, "qty": parseInt(e.target.value)}];
                  } else {
                    tempPQty = [...tempPQty, element];
                  }
                });
                pQty = tempPQty;
                props.setProdQty(pQty);
              } else {
                pQty = [...pQty, {"code": product.componente_codice, "qty": parseInt(e.target.value)}];
                props.setProdQty(pQty);
              }
            }}
          />
        </div>
      ),
    },
    {
      text: "Descrizione",
      dataField: "componente_nome",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, product) => (
        <div className={typeof props.selectedProduct !== "undefined" ? "cliccable" : ""}>
          <h5 className="font-size-14 mb-1 text-dark">
            {product.componente_nome}
            {product.componente_speciale && <><br/><Badge pill className="badge-primary ms-1">
              Elemento nota
            </Badge></>}
          </h5>
          { !product.componente_speciale && <p className="text-muted mb-0">{product.componente_codice} {!product.componente_speciale && " - "}{!product.componente_speciale && <ShowPrice price={product.componente_prezzo} qty={props.productData && props.productData.find(prod => product.componente_codice === prod.componente_codice) ? props.productData.find(prod => product.componente_codice === prod.componente_codice).quantity : 1}/>}{product.componente_classe_provv !== "" ? " - Prov: "+product.componente_classe_provv : ""}{product.componente_note !== "" ? <><br/>{product.componente_note}</> : ""}</p>}
        </div>
      ),
    },
    {
      text: "Scheda tecnica",
      dataField: "componente_pdf",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, product) => (
        product.componente_pdf.length > 0 ? <button
          type="button"
          className="btn btn-outline-primary "
          onClick={() => {
            props.setPdfOpenedData({"id_pdf": product.componente_pdf[0].id, "nome": product.componente_nome});
            props.tog_fullscreen(product.componente_pdf[0].id);
          }}
        >
          <i className="bx bx-show-alt font-size-16 align-middle me-2"></i>{" "}
          Visualizza
        </button> : ""
      ),
    },
  ];

  var node = useRef();

  const keyField = "componente_codice";
  
  const selectRow = {
    mode: props.multiSelect ? "checkbox" : "radio",
    selected: props.selectedProduct,
    clickToSelect: true,
    onSelect: (props.multiSelect ? (row, isSelect, rowIndex, e) => {
      if(isSelect) {
        if(row.componente_speciale){
          Swal.fire({
            title: row.componente_nome + ' (' + row.componente_codice + ')',
            html:
              'Descrizione: <input id="descrizione" type="text" class="swal2-input" style="font-size: 14px; width: 100%; margin: 0px;">',
            showCancelButton: true,
            confirmButtonText: 'Aggiungi',
            confirmButtonColor: '#003A5D',
            showLoaderOnConfirm: true,
            didOpen: function () {
              document.querySelector(".swal2-confirm").setAttribute('disabled', 'disabled');
              document.querySelector('#descrizione').addEventListener('keyup', function(e) {
                if(e.target.value === '') {
                  document.querySelector(".swal2-confirm").setAttribute('disabled', 'disabled');
                } else {
                  document.querySelector(".swal2-confirm").removeAttribute('disabled');
                }
              })
            },
            preConfirm: function () {
              return new Promise(function (resolve) {
                resolve({
                  descrizione: document.querySelector('#descrizione').value
                })
              })
            }
          }).then(function (result) {
            if(result.isConfirmed){
              let special = row;
              special.nome_speciale = result.value.descrizione;
              special.componente_prezzo = [{
                prezzo: 0,
                qta: 0
              }];
              props.setProductData([...props.productData, special]);
              props.setSelectedProduct([...props.selectedProduct, special.componente_codice]);
            }
          })
        } else {
          props.setProductData([...props.productData, row]);
          props.setSelectedProduct([...props.selectedProduct, row.componente_codice]);
        }
      } else {
        props.setProductData(props.productData.filter((item) => row.componente_codice !== item.componente_codice));
        props.setSelectedProduct(props.selectedProduct.filter((item) => row.componente_codice !== item));
      }
    } : 
    (row, isSelect, rowIndex, e) => {
      if(row.componente_speciale){
        Swal.fire({
          title: row.componente_nome + ' (' + row.componente_codice + ')',
          html:
            'Descrizione: <input id="descrizione" type="text" class="swal2-input">',
          showCancelButton: true,
          confirmButtonText: 'Aggiungi',
          confirmButtonColor: '#003A5D',
          showLoaderOnConfirm: true,
          didOpen: function () {
            document.querySelector(".swal2-confirm").setAttribute('disabled', 'disabled');
            document.querySelector('#descrizione').addEventListener('keyup', function(e) {
              if(e.target.value === '') {
                document.querySelector(".swal2-confirm").setAttribute('disabled', 'disabled');
              } else {
                document.querySelector(".swal2-confirm").removeAttribute('disabled');
              }
            })
          },
          preConfirm: function () {
            return new Promise(function (resolve) {
              resolve({
                descrizione: document.querySelector('#descrizione').value
              })
            })
          }
        }).then(function (result) {
          let special = row;
          special.nome_speciale = result.value.descrizione;
          special.componente_prezzo = [{
            prezzo: 0,
            qta: 0
          }];
          props.setSelectedProduct([special.componente_codice]);
          props.setProductData([special]);
        })
      } else {
        props.setSelectedProduct([row.componente_codice]);
        props.setProductData([row]);
      }
    }),
    hideSelectAll: true,
  };

  return (
    <>
      <div className="product_list_heder d-flex justify-content-between align-middle mb-3">
        {props.title}
        <div className="search-box d-inline-block">
          <div className="position-relative">
            <CustomSearch value={searchField} placeholder="Ricerca" onChange = {(e) => {
              setSearchField(e.currentTarget.value);
              getProductsFiltered(openCollapse, e.currentTarget.value);
            }}/>
          </div>
        </div>
      </div>
      <div
        className="accordion accordion-flush"
        id="accordionFlushExample"
      >
        {!props.oneList ? (productsGroupsList.reduce((partialSum, item) => partialSum + getProductsFiltered(item.id, searchField, true), 0) == 0 ? <h2 style={{textAlign: "center"}}>Non ci sono elementi</h2> : productsGroupsList.map((item, index) => {
          var count = getProductsFiltered(item.id, searchField, true);
          
          return count > 0 && <div className="accordion-item" key={item.id}>
            <h2 className="accordion-header" id="headingFlushOne">
              <button
                className={classnames(
                  "accordion-button",
                  "fw-medium",
                  props.alwaysOpen ? "alwaysOpen" : "",
                  { collapsed: props.alwaysOpen || openCollapse !== item.id }
                )}
                type="button"
                onClick={() => {
                  if(!props.alwaysOpen){
                    getProductsFiltered(item.id, searchField);
                    setOpenCollapse(openCollapse === item.id ? false : item.id)
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                {item.nome}
                <span className="badge bg-primary ms-1">{count}</span>
              </button>
            </h2>

            <Collapse
              isOpen={props.alwaysOpen || openCollapse === item.id}
              className="accordion-collapse"
            >
              <div className="accordion-body">
                <div className="text-muted">
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField={keyField}
                    columns={productListColumns}
                    data={productsListFiltered.filter(itemP => itemP.componente_raggruppamenti_id == item.id)}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField={keyField}
                          data={productsListFiltered.filter(itemP => itemP.componente_raggruppamenti_id == item.id)}
                          columns={productListColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    {typeof props.selectedProduct !== "undefined" ?
                                      <BootstrapTable
                                        keyField={keyField}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap table-hover"
                                        }
                                        bordered={false}
                                        striped={false}
                                        responsive
                                        ref={node}
                                        selectRow={selectRow}
                                        className="ht_mobile_vertical"
                                      />
                                    : <BootstrapTable
                                        keyField={keyField}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap table-hover"
                                        }
                                        bordered={false}
                                        striped={false}
                                        responsive
                                        ref={node}
                                        className="ht_mobile_vertical"
                                      />
                                    }
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      );
                    }}
                  </PaginationProvider>
                </div>
              </div>
            </Collapse>
          </div>
        })) : 
        (productsListFiltered.length > 0 ? <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField={keyField}
          columns={productListColumns}
          data={productsListFiltered}
        >
          {({ paginationProps, paginationTableProps }) => {
            return (
              <ToolkitProvider
                keyField={keyField}
                data={productsListFiltered}
                columns={productListColumns}
                bootstrap4
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          {typeof props.selectedProduct !== "undefined" ?
                            <BootstrapTable
                              keyField={keyField}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-hover"
                              }
                              bordered={false}
                              striped={false}
                              responsive
                              ref={node}
                              selectRow={selectRow}
                              className="ht_mobile_vertical"
                            />
                          : <BootstrapTable
                              keyField={keyField}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-hover"
                              }
                              bordered={false}
                              striped={false}
                              responsive
                              ref={node}
                              className="ht_mobile_vertical"
                            />
                          }
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            );
          }}
        </PaginationProvider> : <h2 style={{textAlign: "center"}}>Non ci sono elementi</h2>)}
      </div>
    </>
  )
}

export default ProductList

ProductList.propTypes = {
  title: PropTypes.any,
  location: PropTypes.object,
  t: PropTypes.any,
  setSelectedProduct: PropTypes.func,
  selectedProduct: PropTypes.array,
  setProductData: PropTypes.func,
  productData: PropTypes.array,
  setUrlPdf: PropTypes.func,
  urlPdf: PropTypes.object,
  setLoadedPDF: PropTypes.func,
  loadedPDF: PropTypes.bool,
  tog_fullscreen: PropTypes.func,
  setPdfOpenedData: PropTypes.func,
  filterQuery: PropTypes.array,
  multiSelect: PropTypes.bool,
  withQty: PropTypes.bool,
  prodQty: PropTypes.object,
  alwaysOpen: PropTypes.bool,
  setProdQty: PropTypes.func,
  oneList: PropTypes.bool
};
import PropTypes from "prop-types";
import React, { memo, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { withRouter, useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Spinner
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

//redux
import { useDispatch } from "react-redux";

import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

import {
  getPdf
} from "store/pdf/actions";

import { withTranslation } from "react-i18next";

import ProductList from "components/Heytech/ProductList";
import ProgressBar from "components/Heytech/ProgressBar";
import ShortInfo from "components/Heytech/ShortInfo";
import SaveBozzaBtn from "components/Heytech/SaveBozzaBtn";
import SaveBozzaAuto from "components/Heytech/SaveBozzaAuto";

const SceltaMacchina = props => {
  const history = useHistory();

  const [htOrder, setHtOrder] = React.useState(localStorage.getItem("ht-order") ? JSON.parse(localStorage.getItem("ht-order")) : undefined);

  const [selectedProduct, setSelectedProduct] = React.useState(localStorage.getItem("ht-orderTemp") ? [JSON.parse(localStorage.getItem("ht-orderTemp")).macchinario.code] : []);
  const [productData, setProductData] = React.useState([]);
  const [progressValue, setProgressValue] = React.useState(1);

  const dispatch = useDispatch();

  const [modal_fullscreen, setModal_fullscreen] = React.useState(false);
  const [pdfOpenedData, setPdfOpenedData] = React.useState({});
  const [loadedPDF, setLoadedPDF] = React.useState(false);

  const [numPages, setNumPages] = React.useState(null);
  const [urlPdf, setUrlPdf] = React.useState({});
  const [urlPdfBlob, setUrlPdfBlob] = React.useState(undefined);

  useEffect(() => {
    if (typeof htOrder === "undefined" || typeof htOrder.cliente === "undefined" || htOrder.type === "") {
      history.push("/nuova-offerta");
    }
  }, [htOrder])

  useEffect(() => {
    if(selectedProduct.length > 0 && productData.length > 0){
      let macchinario = {
        "optional": productData[0].componente_optional,
        "category": productData[0].componente_raggruppamenti,
        "code": productData[0].componente_codice,
        "materiale_di_consumo": productData[0].componente_materiale_di_consumo,
        "nome": productData[0].componente_nome,
        "accessori": productData[0].componente_accessori,
        "accessori_obbligatori": productData[0].componente_accessori_obbligatori,
        "prezzo": productData[0].componente_prezzo,
        "ricambi": productData[0].componente_ricambi,
        "unita_di_misura": productData[0].componente_unita_di_misura
      }
      if(!localStorage.getItem("ht-orderTemp")){
        let orderTemp = {
          "macchinario":macchinario,
          "accessori":[],
          "accessoriObbligatori":[],
          "optional":[],
          "materialiDiConsumo":[]
        };
        localStorage.setItem("ht-orderTemp", JSON.stringify(orderTemp));
      } else {
        let orderTemp = JSON.parse(localStorage.getItem("ht-orderTemp"));
        if(macchinario.code !== orderTemp.macchinario.code){
          orderTemp = {
            "macchinario":macchinario,
            "accessori":[],
            "accessoriObbligatori":[],
            "optional":[],
            "materialiDiConsumo":[]
          };
          localStorage.setItem("ht-orderTemp", JSON.stringify(orderTemp));
        }
      }
    }
  }, [selectedProduct])

  useEffect(() => {
    if(loadedPDF && typeof urlPdf !== "undefined" && typeof urlPdf.pdf !== "undefined") {
      const blob = base64toBlob(urlPdf.pdf);
      setUrlPdfBlob(URL.createObjectURL(blob));
    }
  }, [urlPdf, loadedPDF]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const tog_fullscreen = (pdfId = "") => {
    if(pdfId !== ""){
      dispatch(getPdf(pdfId, setLoadedPDF, setUrlPdf));
      setModal_fullscreen(!modal_fullscreen);
      removeBodyCss();
    } else if(typeof pdfOpenedData.id_pdf !== "undefined"){
      dispatch(getPdf(pdfOpenedData.id_pdf, setLoadedPDF, setUrlPdf));
      setModal_fullscreen(!modal_fullscreen);
      removeBodyCss();
    }
  }
  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  }

  const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data;

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Scelta macchinario | Gruppo Bisaro Cloud</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Attività" parentsBreadcrumb={[{"title":"Crea "+(htOrder. proposta === 0 ? "Offerta" : "Ordine"), "link":(htOrder. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine")}]} breadcrumbItem="Scelta macchinario" />
          <Row>
            <ShortInfo />
            <Col md="12">
              <Card>
                <CardBody>
                  <ProgressBar numberBable={6} progressValue={progressValue}/>
                  <div className="d-flex justify-content-between pt-4 ht_btn_bar_top">
                    {JSON.parse(localStorage.getItem("ht-order")).order.length === 0 && <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        history.push((htOrder. proposta === 0 ? "/nuova-offerta" : "/nuovo-ordine"));
                      }}
                    >
                      <i className="bx bx-left-arrow-alt font-size-16 align-middle"></i>{" "}
                      Cliente
                    </button>}
                    
                    <SaveBozzaBtn disabled={JSON.parse(localStorage.getItem("ht-order")).order.length == 0 ? true : false}/>
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={selectedProduct.length === 0}
                      onClick={() => {history.push((htOrder. proposta === 0 ? "/nuova-offerta/macchinario/scelta-accessori" : "/nuovo-ordine/macchinario/scelta-accessori"));}}
                    >
                      Accessori{" "}
                      <i className="bx bx-right-arrow-alt font-size-16 align-middle"></i>
                    </button>
                    {JSON.parse(localStorage.getItem("ht-order")).order.length > 0 && <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        history.push((htOrder. proposta === 0 ? "/nuova-offerta/macchinario/scelta-condizionidivendita" : "/nuovo-ordine/macchinario/scelta-condizionidivendita"));
                      }}
                    >
                      Condizioni di vendita{" "}
                      <i className="bx bx-right-arrow-alt font-size-16 align-middle"></i>
                    </button>}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <CardBody>
                  <ProductList
                    title={<h5 className="mb-0">Seleziona un macchinario</h5>}
                    filterQuery={[{"key":"componente_tipo", "value": ["P-MAC"]}]}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    productData={productData}
                    setProductData={setProductData}
                    urlPdf={urlPdf} setUrlPdf={setUrlPdf}
                    setLoadedPDF={setLoadedPDF}
                    loadedPDF={loadedPDF}
                    tog_fullscreen={tog_fullscreen}
                    setPdfOpenedData={setPdfOpenedData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            isOpen={modal_fullscreen}
            toggle={() => {
              tog_fullscreen();
            }}
            className="modal-fullscreen"
          >
            <div className="modal-header">
              <h5
                className="modal-title mt-0"
                id="exampleModalFullscreenLabel"
              >
                {pdfOpenedData.nome} - Scheda tecnica
              </h5>
              <button
                onClick={() => {
                  setModal_fullscreen(false);
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                {!loadedPDF && <Spinner className="ms-2" color="primary" />}
                {loadedPDF && typeof urlPdfBlob !== "undefined" && <HTFileViewer memo_extension = {"pdf"} memo_urlFile={urlPdfBlob} />}
              </div>
            </div>
          </Modal>
        </Container>
      </div>
      <SaveBozzaAuto />
    </React.Fragment>
  )
}

const HTFileViewer = memo(function Greeting({ memo_extension, memo_urlFile }) {
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  return <Worker workerUrl="pdf.worker.js">
    <div style={{
      flex: "1",
      overflow: "hidden",
      height: "100%"
    }}>
      <div
        className="rpv-core__viewer"
        style={{
          display: 'flex',
          height: '100%',
          position: 'relative',
        }}
      >
        <div
          style={{
            alignItems: 'center',
            backgroundColor: '#eeeeee',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            borderRadius: '2px',
            bottom: '16px',
            display: 'flex',
            left: '50%',
            padding: '4px',
            position: 'absolute',
            transform: 'translate(-50%, 0)',
            zIndex: 1,
          }}
        >
          <Toolbar>
            {(props: ToolbarSlot) => {
              const {
                CurrentPageInput,
                GoToNextPage,
                GoToPreviousPage,
                NumberOfPages,
                ZoomIn,
                ZoomOut,
              } = props;
              return (
                <>
                  <div style={{ padding: '0px 2px' }}>
                    <ZoomOut />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <ZoomIn />
                  </div>
                  <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                    <GoToPreviousPage />
                  </div>
                  <div style={{ padding: '0px 2px', width: '4rem' }}>
                    <CurrentPageInput />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    / <NumberOfPages />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <GoToNextPage />
                  </div>
                </>
              );
            }}
          </Toolbar>
        </div>
        <div
          style={{
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <Viewer fileUrl={memo_urlFile} plugins={[toolbarPluginInstance]} />
        </div>
      </div>
    </div>
  </Worker>
});

HTFileViewer.propTypes = {
  memo_urlFile: PropTypes.any,
  memo_extension: PropTypes.any,
};

SceltaMacchina.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
}

export default withRouter(withTranslation()(SceltaMacchina));
import PropTypes from "prop-types";
import React, { useEffect } from "react"
import {
  Card,
  CardBody,
  Col
} from "reactstrap";
import macchinarioLogo from "assets/images/macchinarioLogo.png";
import materialediconsumoLogo from "assets/images/materialediconsumoLogo.png";
import Swal from 'sweetalert2';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ShortInfo = props => {
  const [order, setOrder] = React.useState(localStorage.getItem("ht-order") ? JSON.parse(localStorage.getItem("ht-order")) : undefined);
  const [orderTemp, setOrderTemp] = React.useState(localStorage.getItem("ht-orderTemp") ? JSON.parse(localStorage.getItem("ht-orderTemp")) : undefined);

  useEffect(() => {
    setOrder(localStorage.getItem("ht-order") ? JSON.parse(localStorage.getItem("ht-order")) : undefined);
    setOrderTemp(localStorage.getItem("ht-orderTemp") ? JSON.parse(localStorage.getItem("ht-orderTemp")) : undefined);
  }, [props.changedLocalStorage])

  const deleteMaterialeDiConsumo = (id, name) => {
    Swal.fire({
      title: 'Eliminazione materiale di consumo',
      text: 'Sicuro di voler eliminare ' + name + '?',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annulla',
      confirmButtonText: 'Sì, elimina!',
      confirmButtonColor: '#f46a6a',
    }).then((result) => {
      if (result.isConfirmed) {
        if (order.type == "Macchinario") {
          let orderTempNew = JSON.parse(JSON.stringify(localStorage.getItem("ht-orderTemp")));
          orderTempNew.materialiDiConsumo = orderTempNew.materialiDiConsumo.filter(item => item.id !== id);
          localStorage.setItem("ht-orderTemp", JSON.stringify(orderTempNew));
          setOrderTemp(orderTempNew);
        } else {
          let orderTempNew = JSON.parse(localStorage.getItem("ht-order"));
          orderTempNew.order = orderTempNew.order.filter(item => item.id !== id);
          localStorage.setItem("ht-order", JSON.stringify(orderTempNew));
          setOrder(orderTempNew);
        }
      }
    })
  }

  function compareStrings(a, b) {
    // Assuming you want case-insensitive comparison
    a = a.toLowerCase();
    b = b.toLowerCase();

    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    if (droppedItem.source.droppableId === "list-container-accessori") {
      var updatedList = [...orderTemp.accessori];
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      let sortN = 0;
      updatedList.map((item) => {
        sortN = sortN + 1;
        item.sort_number = sortN;
        return item;
      })
      let tempOrder = orderTemp;
      tempOrder.accessori = updatedList;
      localStorage.setItem("ht-orderTemp", JSON.stringify(tempOrder));
      setOrderTemp(tempOrder);
    } else if (droppedItem.source.droppableId === "list-container-optional") {
      var updatedList = [...orderTemp.optional];
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      let sortN = 0;
      updatedList.map((item) => {
        sortN = sortN + 1;
        item.sort_number = sortN;
        return item;
      })
      let tempOrder = orderTemp;
      tempOrder.optional = updatedList;
      localStorage.setItem("ht-orderTemp", JSON.stringify(tempOrder));
      setOrderTemp(tempOrder);
    } else {
      var updatedList = [...orderTemp.materialiDiConsumo];
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      let sortN = 0;
      updatedList.map((item) => {
        sortN = sortN + 1;
        item.sort_number = sortN;
        return item;
      })
      let tempOrder = orderTemp;
      tempOrder.materialiDiConsumo = updatedList;
      localStorage.setItem("ht-orderTemp", JSON.stringify(tempOrder));
      setOrderTemp(tempOrder);
    }
  };

  const getScontoType = (item) => {
    if (item.sconto_type == -1) {
      return "";
    } else if (item.sconto_type == 0) {
      return " - Omaggio";
    } else if (item.sconto_type == 2) {
      return " - Promo";
    } else if (item.sconto_type == 1) {
      if (item.sconto > 0) {
        return " - Sconto: " + item.sconto + "%";
      }
    }
  }

  return (
    typeof order !== "undefined" ? <>
      <Col md="5">
        <Card>
          <div className="px-4 py-3 border-bottom">
            <ul className="list-inline mb-0">
              <li className="list-inline-item me-3" id="dueDate">
                {navigator.onLine && <img className="short_list_icon_type" src={order.type === "Macchinario" ? macchinarioLogo : materialediconsumoLogo} alt={(order.proposta === 0 ? "Offerta" : "Proposta d'ordine") + " " + order.type} />}{" "}
                <strong>{order.proposta === 0 ? "Offerta" : "Proposta d'ordine"}{" "}{order.type}</strong>
              </li>
            </ul>
          </div>
          <CardBody>
            <div className="d-flex">
              <div className="flex-grow-1 overflow-hidden">
                <h5 className="text-truncate font-size-15">
                  Cliente
                </h5>
                <p className="mb-0 font-size-12">
                  {order.cliente && typeof order.cliente.anagrafica_descrizione !== "undefined" && order.cliente.anagrafica_descrizione}
                </p>
                <p className="text-muted mb-0 font-size-10">{order.cliente && typeof order.cliente.anagrafica_codice !== "undefined" && ("Codice: " + order.cliente.anagrafica_codice)}</p>
                <p className="text-muted mb-0 font-size-10">{order.cliente && typeof order.cliente.anagrafica_indirizzo !== "undefined" && order.cliente.anagrafica_indirizzo.length > 0 && (order.cliente.anagrafica_indirizzo[0].Indirizzo + " - " + order.cliente.anagrafica_indirizzo[0].Cap + ", " + order.cliente.anagrafica_indirizzo[0].Localita + " (" + order.cliente.anagrafica_indirizzo[0].Provincia + ")")}</p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      {typeof orderTemp !== "undefined" ? <Col md="7">
        <Card>
          <div className="px-4 py-3 border-bottom">
            <strong>Riepilogo parziale</strong>
          </div>
          <CardBody>
            <strong>{orderTemp.macchinario.nome}</strong> <small>({orderTemp.macchinario.code})</small><br />
            {(orderTemp.accessoriObbligatori.length > 0 || orderTemp.accessori.length > 0) && <>Accessori:
              {orderTemp.accessoriObbligatori.length > 0 && <ul>
                {orderTemp.accessoriObbligatori.map((item, index) => <li key={item.code}><small>{item.nome} ({item.code})</small></li>)}
              </ul>}
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container-accessori">
                  {(provided) => (
                    <ul
                      className="list-container-accessori"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {orderTemp.accessori.map((item, index) => <Draggable key={item.code} draggableId={item.code} index={index}>
                        {(provided) => (
                          <li key={item.code}
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <small>{item.is_special ? "" : "[" + item.quantity + "]"} {item.is_special ? item.nome_speciale : item.nome} ({item.code})</small>
                          </li>
                        )}
                      </Draggable>)}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </>}
            {orderTemp.optional.length > 0 && <>Optional:
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container-optional">
                  {(provided) => (
                    <ul
                      className="list-container-optional"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {orderTemp.optional.map((item, index) => <Draggable key={item.code} draggableId={item.code} index={index}>
                        {(provided) => (
                          <li key={item.code}
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <small>{item.is_special ? "" : "[" + item.quantity + "]"} {item.is_special ? item.nome_speciale : item.nome} ({item.code})</small>
                          </li>
                        )}
                      </Draggable>)}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </>}
            {orderTemp.materialiDiConsumo.length > 0 && <>Materiale di consumo:<br />
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container-MDC">
                  {(provided) => (
                    <ul
                      className="list-container-MDC"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {orderTemp.materialiDiConsumo.map((item, index) => <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                          <li key={item.id}
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <small>{item.is_special ? "" : "[" + item.quantity + "]"} {item.is_special ? item.nome_speciale : item.nome} ({item.code}{getScontoType(item)})</small>{" "}<div className="btn btn-sm btn-danger" onClick={() => deleteMaterialeDiConsumo(item.id, item.nome + " (" + item.code + getScontoType(item) + ")")}><i className="mdi mdi-trash-can align-middle me-2"></i>Elimina</div>
                          </li>
                        )}
                      </Draggable>)}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </>}
          </CardBody>
        </Card>
      </Col> : ""}
      {order.type !== "Macchinario" && order.order.length > 0 ? <Col md="7">
        <Card>
          <div className="px-4 py-3 border-bottom">
            <strong>Riepilogo</strong>
          </div>
          <CardBody>
            <DragDropContext onDragEnd={handleDrop}>
              <Droppable droppableId="list-container">
                {(provided) => (
                  <ul
                    className="list-container"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {order.order.map((item, index) => <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided) => (
                        <li key={item.id} className="mb-2"
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                        >
                          <small>{!item.is_special && "[" + item.quantity + "]"} {item.is_special ? item.nome_speciale : item.nome} ({item.code}{getScontoType(item)})</small>{" "}<div className="btn btn-sm btn-danger" onClick={() => deleteMaterialeDiConsumo(item.id, item.nome + " (" + item.code + getScontoType(item) + ")")}><i className="mdi mdi-trash-can align-middle me-2"></i>Elimina</div>
                        </li>
                      )}
                    </Draggable>)}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </CardBody>
        </Card>
      </Col> : ""}
      {typeof order.condizioni !== "undefined" && typeof orderTemp === "undefined" ? <Col md={order.type !== "Macchinario" ? "12" : "7"}>
        <Card>
          <div className="px-4 py-3 border-bottom">
            <strong>Condizioni & pagamenti</strong>
          </div>
          <CardBody>
            <strong className="d-block mb-2">Condizioni di vendita</strong>
            {order.condizioni.sort(function (a, b) { return compareStrings(a.condizione, b.condizione); }).map((item) => <small className="d-block px-3" key={item.idCondizione}>{item.condizione}:<strong dangerouslySetInnerHTML={{ __html: item.valore.descrizione }}></strong></small>)}
            {order.proposta === 1 && typeof order.spedizione.Indirizzo !== "undefined" && <small className="d-block px-3">Indirizzo di spedizione:<p><strong dangerouslySetInnerHTML={{ __html: (order.spedizione.Descrizione ? order.spedizione.Descrizione + "<br/>" : "") + order.spedizione.Indirizzo + "<br/>(" + order.spedizione.Cap + ") " + order.spedizione.Localita + " (" + order.spedizione.Provincia + ")" }}></strong></p></small>}
            {typeof order.pagamenti !== "undefined" && <><strong className="d-block mb-2">Metodo di pagamento</strong>
              {order.pagamenti.map((item) => <small className="d-block px-3" key={item.idCondizione}><strong className="ht_no_margin_p" dangerouslySetInnerHTML={{ __html: item.valore.descrizione }}></strong></small>)}</>}
          </CardBody>
        </Card>
      </Col> : ""}
    </> : ""
  )
}

export default ShortInfo

ShortInfo.propTypes = {
  changedLocalStorage: PropTypes.bool,
};